import React from 'react';
import clsx from 'clsx';

import { Theme, makeStyles } from '@material-ui/core';


import { Switch, Route, useRouteMatch } from "react-router-dom";
import Sidebar from '../Sidebar';
import ResourceIndex from './ResourceIndex';
import ResourceDetails from './ResourceDetails';

import { ProductSchema } from '../models/Product';
import { UserSchema, ProfileSchema } from '../models/User';
import { LabSchema } from '../models/Lab';
import { PwnOrderSchema } from '../models/PwnOrder';
import { ProductVariantSchema } from '../models/ProductVariant';
import { OrderSchema } from '../models/Order';
import { LineItemsSchema } from '../models/LineItem';
import { DiscountSchema } from '../models/Discount';
import { parentedSchema } from '../services/ResourceService';
import SnackProvider from '../context/SnackProvider';
import { useUserContext, UserRole } from '../context/UserContext';
import AuthGateway from './AuthGateway';
import DevNotes from './DevNotes';
import Profile from './Profile';
import { RefundSchema } from '../models/Refund';
import {TopAppBar, BottomAppBar} from './Bars';

const drawerWidth = 240;


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: theme.spacing(4)
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

const Hub = () => {
  const classes = useStyles();
  let { path } = useRouteMatch();
  const [open, setOpen] = React.useState(false);
  const { userRole } = useUserContext();

  if (userRole <= UserRole.CUSTOMER) { return <AuthGateway /> }
  return (
    < >
      <SnackProvider>
        <div className={classes.root}>
          <Switch>
            <Route path={path}>
              <TopAppBar open={open} setOpen={setOpen} />
              {/* <Typography variant="h6" className={classes.title}>LTO Commerce Hub</Typography>  */}
              {/* <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}

              >
                <Toolbar>
                  {!open && (
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer} hidden={open}>
                      <MenuIcon />
                    </IconButton>
                  )}
                  <Box paddingTop={2} paddingBottom={2}>
                    <img src="/images/header-logo.png" style={{ height: '76px' }} />
                  </Box>
                  <Typography variant="h6" className={classes.title}>LTO Commerce Hub</Typography>
                  <AuthenticationButtons />
                </Toolbar>
              </AppBar> */}

              <Sidebar open={open} setOpen={setOpen} />
              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: open,
                })}
              >
                <div className={classes.drawerHeader} />

                <Switch>
                  <Route path='*/orders/new'><div>Cannot create new orders outside of shopping cart</div></Route>

                  <Route path={`*/labs/:parentResourceId/variants`}><ResourceIndex schema={parentedSchema(ProductVariantSchema, LabSchema)} key="l-v" /></Route>
                  <Route path={`*/products/:parentResourceId/variants`}><ResourceIndex schema={parentedSchema(ProductVariantSchema, ProductSchema)} key="p-v" /></Route>
                  <Route path={`*/orders/:parentResourceId/line_items`}><ResourceIndex schema={parentedSchema(LineItemsSchema, OrderSchema)} key="o-li" /></Route>
                  <Route path={`*/orders/:parentResourceId/refunds`}><ResourceIndex schema={parentedSchema(RefundSchema, OrderSchema)} key="o-r" /></Route>
                  <Route path={`*/users/:parentResourceId/orders`}><ResourceIndex schema={parentedSchema(OrderSchema, UserSchema)} key="u-o" /></Route>
                  <Route path={`*/discounts/:parentResourceId/users`}><ResourceIndex schema={parentedSchema(UserSchema, DiscountSchema)} key="d-u" /></Route>
                  <Route path={`*/pwn_orders/:parentResourceId/users`}><ResourceIndex schema={parentedSchema(UserSchema, DiscountSchema)} key="po-u" /></Route>

                  <Route path='*/orders'><ResourceIndex schema={OrderSchema} key="o" /></Route>
                  <Route path='*/products'><ResourceIndex schema={ProductSchema} key="p" /></Route>
                  <Route path='*/variants'><ResourceIndex schema={ProductVariantSchema} key="v" /></Route>
                  <Route path='*/pwn_orders'><ResourceIndex schema={PwnOrderSchema} key="po" /></Route>
                  <Route path='*/labs'><ResourceIndex schema={LabSchema} key="l" /></Route>
                  <Route path='*/users'><ResourceIndex schema={UserSchema} key="u" /></Route>
                  <Route path='*/discounts'><ResourceIndex schema={DiscountSchema} key="d" /></Route>
                </Switch>


                <Route path={`${path}/callback`}>
                  <Route exact path={`${path}/callback/:resourceId`}>
                    <ResourceDetails schema={ProfileSchema} />
                  </Route>
                </Route>

                <Route path={`${path}/profile`}>
                  <Route exact path={`${path}/profile/:resourceId`}>
                    <ResourceDetails schema={ProfileSchema} />
                  </Route>
                  <Profile />
                </Route>

                <Route path={`${path}/integrations`}>
                  <DevNotes />
                </Route>
              </main>
            </Route>
          </Switch>

        </div>
      </SnackProvider>

      <BottomAppBar />
    </>
  )
}

export default Hub;
