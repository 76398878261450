import ResourceService, { ResourceSchema } from '../services/ResourceService';
import ColumnFactory from '../services/ColumnFactory';

import ProductOptions from '../components/ProductOptions';
import ProductVariantDetails from '../components/ProductVariantDetails';
import { FilterOption } from '../services/ResourceService';
// import { GridRenderCellParams } from '@material-ui/data-grid'
import { HubActionProps, NewRedirectAction, HubAction } from '../components/CommonResourceHandlers';
import { UserRole, useUserContext } from '../context/UserContext';

const ProductHubActionsResolver = (props: HubActionProps): HubAction[] | null => {
  const { userRole } = useUserContext();

  const { schema, parentResourceId } = props;
  var actions = [];
  if (schema.parent && parentResourceId) {
    //Section if there a parent to product
  } else {
    if (userRole >= UserRole.ADMIN) {
      actions.push(NewRedirectAction());
    }
  }

  return actions;
}


type Product = {
  id: string;
  name: string;
  description?: string;
  variants?: [string];
  embedCode?: string;
  fastingRequired: boolean;
};

const scriptForProduct = (productId: string) => {
  return `<script type="text/javascript" src="${ResourceService.baseUrl()}/scripts/products/${productId}/add_to_cart.js"></script><span id="60ff868c7aec0903e5085bc8"></span>`;
}

const DateDescriptor = (filterOption: FilterOption) => {

  const dateString = filterOption.value ? filterOption.value.toLocaleDateString() : '...';
  return `${filterOption.description}: ${dateString}`;
}

const Links = [{ path: 'variants', cta: 'Variants' }]

const ProductSchema = {
  apiResponseResourceCollectionKey: 'products',
  apiResponseResourceItemKey: 'product',
  apiRequestPath: '/products',
  labels: { name: 'Product', pluralName: 'Products', describer: (resource) => resource.name },
  links: Links,
  columns: [
    ColumnFactory.id,
    {
      field: 'name',
      headerName: 'Product Name',
      flex: 1.5,
      editable: true,
    },
    {
      field: 'slug',
      headerName: 'Slug',
      flex: 1,
      editable: false,
    },
    ColumnFactory.createdAt,
    ColumnFactory.updatedAt,
    ColumnFactory.check({ field: 'active', headerName: 'Active' }),
    ColumnFactory.check({ field: 'fastingRequired', headerName: 'Fasting Required' }),
    ColumnFactory.links(Links),
    ColumnFactory.actions()
  ],
  optionHandler: ProductOptions,
  subPaths: [{ path: 'variants', Editor: ProductVariantDetails }],
  filterOptions: [
    { name: 'active', value: true, description: "Active" },
    { name: 'inactive', value: false, description: "Inactive" },
    { name: 'createdAfter', valueType: 'date', description: "Created After", descriptor: DateDescriptor },
    { name: 'createdBefore', valueType: 'date', description: "Created Before", descriptor: DateDescriptor }
  ],
  actionsResolver: ProductHubActionsResolver

} as ResourceSchema;


const ProductService = {
  //   index: async () => {
  //     return (await index(urlWithApiPath('/products'))).products;
  //   },
  //   read: async (id: string) => {
  //     return (await read(urlWithApiPath(`/products/${id}`)))
  //   },
  //   createOrUpdate: async (resource: any) => {
  //     const apiPath = resource.id ? `/products/${resource.id}` : '/products';
  //     const method = resource.id ? 'PUT' : 'POST';
  //     return await createOrUpdate(urlWithApiPath(apiPath), resource, method)
  //   },
  //   destroy: async (id: string) => {
  //     return (await destroy(urlWithApiPath(`/products/${id}`)));
  //   },
  scriptForProduct
};

export { ProductSchema, ProductService };


export default Product;
