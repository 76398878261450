// import { ResourceSchema } from '../services/ResourceService';
import { useMutableResourceContext } from '../context/MutableResourceProvider';

interface Props {
  setShowActions?: any;
}

interface ResourceOptionProps {
  setShowActions?: any;
  handleChange: (event: any) => void;
  handleCheckChange: (event: any) => void;
  handleDateChange: (date: Date, dateKey: string) => void;
}

function ResourceOptions(props: Props) {

  const { schema, setResource } = useMutableResourceContext();

  const handleChange = (event: any) => {

    setResource((freshResource: any) => {

      var r = { ...freshResource } as any;
      var paths = event.target.name.split('.');
      var obj = r;
      while (paths.length > 1) {
        var key = paths.shift();
        if (key.endsWith('[]')) {
          key = key.replace('[]', '');
          obj[key] = obj[key] || [{}];
          obj = obj[key][0];
          //Note: we could handle existing collections here too by looking for
          // brackets with integers in them and setting the current object to
          // array index of said integer
        } else {
          obj[key] = obj[key] || {};
          obj = obj[key];
        }
      }
      obj[paths.shift()] = event.target.value;
      return r;
    })
  }

  const handleCheckChange = (event: any) => {
    setResource((freshResource: any) => {

      var r = { ...freshResource } as any;
      r[event.target.name] = event.target.checked;
      return r;
    })
  }

  const handleDateChange = (date: any, dateKey: string) => {
    setResource((freshResource: any) => {
      var r = { ...freshResource } as any;
      r[dateKey] = date.toISOString();
      return r;
    })
  }

  return (
    <schema.optionHandler {...props} handleChange={handleChange} handleCheckChange={handleCheckChange} handleDateChange={handleDateChange} />
  )
}



export type { ResourceOptionProps };
export default ResourceOptions;
