
import { Dispatch } from 'react';
import { Typography, AppBar, Toolbar, Theme, makeStyles, Grid, Box, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import AuthenticationButtons from './AuthenticationButtons';


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white', //background of LTO icon
    color: theme.palette.secondary.contrastText,
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: '4px',
    borderBottomStyle: 'solid'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));


interface TopAppBarProps {
  open?: boolean;
  setOpen?: Dispatch<boolean>;
  children?: any;
}

const Logo = () => {
  const theme = useTheme();

  const isMobile = window.innerWidth < theme.breakpoints.values.sm;
  const imgHeight = isMobile ? "40px" : "76px";
  const padding = isMobile ? { top: 1, bottom: 1 } : { top: 3, bottom: 2 };

  return (
    <Box paddingTop={padding.top} paddingBottom={padding.bottom}>
      <a href='https://testing.com'>
        <img src="/images/Testing-Logo.png" height={imgHeight} alt='' />
      </a>
    </Box>
  )
}

const TopAppBar = ({ open = false, setOpen, children }: TopAppBarProps) => {
  const classes = useStyles();

  const toggleDrawer = () => {
    if (setOpen) { setOpen(!open) }
  }
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      elevation={0}
    >
      <Toolbar>
        {setOpen && !open && (
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer} hidden={open}>
            <MenuIcon />
          </IconButton>
        )}
        <Logo />
        <div className={classes.title} />
        {children}
        {/* <Typography variant="h6" className={classes.title}>LTO Commerce Hub</Typography> */}
        <AuthenticationButtons showLogin={false} />
      </Toolbar>
    </AppBar>
  )
}


const BottomAppBar = () => {
  return (
    <AppBar position="fixed" color="primary" style={{ top: 'auto', bottom: 0 }}>
      <Toolbar style={{ minHeight: '50px' }}>
        <Grid container justifyContent="center">
          <Typography variant="body1" style={{ fontWeight: 550 }}>© 2021 Testing.com. All rights reserved.</Typography>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export {
  TopAppBar, BottomAppBar
};
