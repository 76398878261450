import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

interface Props {
  handleChange: (e: any) => void;
  currentValue: string;
  fieldName: string
}

const PhoneNumber= ({handleChange, currentValue, fieldName}: Props) => {

  const beforeMaskedStateChange = ({previousState, currentState, nextState}:any) => {

    // This logic was added to fix an issue with autofill that would truncate
    // the value. Apparently this is a known issue with the InputMask library
    // https://github.com/sanniassin/react-input-mask
    if (currentState) {
      let { value: nextValue, enteredString } = nextState;
      let { value: currentValue } = currentState;
      if (enteredString === '' && currentValue !== nextValue) {
        let { value } = previousState;
        return {
          ...nextState,
          value
        }
      }
    }

    let { value } = nextState;
    // This corrects an issue when the user is backspacing
    if (value.endsWith('-')) {
      value = value.slice(0, -1);
    }
    return {
      ...nextState,
      value
    };

  }
  return (
    <InputMask
      mask="999-999-9999"
      maskPlaceholder={null}
      value={currentValue || ""}
      onChange={handleChange}
      beforeMaskedStateChange={beforeMaskedStateChange}
    >
      <TextField required name={fieldName} label="Phone No." inputProps={{pattern: "[0-9\\-]{12}"}} fullWidth />
    </InputMask>
  )
}
export default PhoneNumber
