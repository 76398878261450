import { Grid, Typography, Box } from '@material-ui/core';
import formatCurrency from '../utils'
import Order from '../models/Order';

interface OrderSummaryArgs {
  alignItemCount?: 'right' | 'left'
  order: Order;
}

/* eslint-disable-next-line import/no-anonymous-default-export*/
export default (props: OrderSummaryArgs) => {
  if (!props) return null
  const {
    alignItemCount = 'right',
    order = {} as Order
  } = props

  const getDiscountValue = (args: any) => {
    if (args) {
      return args.valueType === 'flat'
        ? `(${formatCurrency(args.amount)})`
        : `${args.percentOff} % (${formatCurrency(order.subTotal - order.totalAmount)})`;
    } else {
      return '';
    }
  }

  return (
    <Box p={2}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="flex-end"
          alignContent="center"
          spacing={2}
        >
          <Grid item xs={6} style={{ textAlign: alignItemCount, paddingRight: '12px' }}>
            <Typography display="block">
              Subtotal
            </Typography>
          </Grid>
          <Grid item xs={6} >
            <Typography display="block" style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {formatCurrency(order.subTotal)}
            </Typography>
          </Grid>
          <div style={{ borderBottom: '1px solid #d8d8d8', height: '1px', width: '100%', margin: '10px auto 0' }} />
          {order.fees !== undefined && order.fees > 0 && (
            <>
              <Grid item xs={8} md={10} style={{ textAlign: alignItemCount, paddingRight: '12px', paddingTop: '12px' }}>
                <Typography display="block">
                  Fees
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography display="block" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {formatCurrency(order.fees)}
                </Typography>
              </Grid>
              <div style={{ borderBottom: '1px solid #d8d8d8', height: '1px', width: '100%', margin: '10px auto 0' }} />
            </>
          )}
          {order.discount && (
            <>
              <Grid item xs={6} style={{ textAlign: alignItemCount, paddingRight: '12px', paddingTop: '12px' }}>
                <Typography display="block">
                  Discounts
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography display="block" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {getDiscountValue(order.discount)}
                </Typography>
              </Grid>
              <div style={{ borderBottom: '1px solid #d8d8d8', height: '1px', width: '100%', margin: '10px auto 0' }} />
            </>
          )}
          <Grid item xs={6} style={{ textAlign: alignItemCount, paddingRight: '12px', marginBottom: '4px' }}>
            <Typography variant="h5" display="block">Total</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography display="block" style={{ textAlign: 'right', fontWeight: 700, fontSize: '30px' }}>
              {formatCurrency(order.totalAmount)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
