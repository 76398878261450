import { useState } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload';
import ResultUpload from './ResultUpload';
import DownloadPwnDocument from './DownloadPwnDocument';
import { formatDateString } from '../utils';
import { useMutableResourceContext } from '../context/MutableResourceProvider';

const PwnOrderOptions = () => {
  const { resource, loadResource } = useMutableResourceContext();
  const [showingUpload, showUpload] = useState(false);

  const handleDidUpload = (updatedResource?: any) => {
    if (updatedResource) { loadResource(true); }
    showUpload(false)
  }

  const resultDescription = () => {
    if (resource.testResult) {
      return formatDateString(resource.testResult.updatedAt);
    } else {
      return "No Test Results File";
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <Typography variant='body1' align='right'><strong>ID:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant='body1'> {resource.id}</Typography>
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <Typography variant='body1' align='right'><strong>Status:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant='body1'> {resource.aasmState}</Typography>
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <Typography variant='body1' align='right'><strong>Requistion ID:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant='body1'> {resource.externalOrderId}</Typography>
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <Typography variant='body1' align='right'><strong>Requisition Form:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          {resource.requisitionForm && (
            <DownloadPwnDocument pwnId={resource.id} externalOrderId={resource.externalOrderId} docName={'requisition_form'} />
          )}
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <Typography variant='body1' align='right'><strong>Test Results:</strong></Typography>
        </Grid>

        <Grid item container xs={8} spacing={2} >
          <Grid item>
            <Typography variant='body1'>{resultDescription()}</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              endIcon={<UploadIcon />}
              onClick={() => showUpload(true)}>Upload Test Results</Button>
            {showingUpload && <ResultUpload pwnOrder={resource} onDone={handleDidUpload} />}
          </Grid>
          <Grid item>
            {resource.testResult && (
              <DownloadPwnDocument pwnId={resource.id} externalOrderId={resource.externalOrderId} docName={'test_result'} />
            )}
          </Grid>
        </Grid>


      </Grid>

    </Grid>
  )
};

export default PwnOrderOptions;
