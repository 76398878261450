import { useState, useEffect } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import MoreIcon from '@material-ui/icons/MoreVert';
import { HubAction } from './CommonResourceHandlers';

const ResourceActionMenu = (props: any) => {

  const { schema } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentAction, setCurrentAction] = useState<HubAction>();


  const schemaActions = (schema.actionsResolver && schema.actionsResolver(props)) || [];
  const bulkActions = (schema.bulkActionsResolver && schema.bulkActionsResolver(props)) || [];

  const actions = [...schemaActions, ...bulkActions];

  useEffect(() => {
    if (currentAction) {
      handleCloseActionMenu();
    }
  }, [currentAction])

  if (!actions || actions.length <= 0) {
    return null;
  }

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  }

  const handleDone = () => {
    setCurrentAction(undefined)
    props.onDone();
  }

  return (
    <>
      <Fab color="primary" aria-label="add" size="medium" onClick={handleOpenActionMenu}>
        {actions.length > 1 ? <MoreIcon /> : <AddIcon />}
      </Fab>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseActionMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        {actions.map((action: HubAction) => <MenuItem onClick={() => setCurrentAction(action)}>{action.callToAction(props)}</MenuItem>)}

      </Menu>

      {currentAction && <currentAction.component {...props} onDone={handleDone} />}

    </>
  )
}
export default ResourceActionMenu
