import { useEffect, useState } from 'react';
import qs from 'qs';
import { Switch, Route, useLocation, useRouteMatch, useParams, Redirect } from "react-router-dom";

import Cart from './Cart';
import ResourceService from '../services/ResourceService';
import OrderIntent, { OrderIntentSchema } from '../models/OrderIntent';
import { LinearProgress } from '@material-ui/core';

import {TopAppBar, BottomAppBar} from './Bars';


type OrderIntentParams = {
  id: string
}

const CartManager = () => {
  const { id } = useParams<OrderIntentParams>();

  const [orderIntent, setOrderIntent] = useState<OrderIntent>();

  const loadOrderIntent = async (orderIntentId: string) => {
    const result = await ResourceService.read(orderIntentId, OrderIntentSchema);
    setOrderIntent(result.orderIntent);
  }

  const saveOrderIntent = async (_orderIntent: OrderIntent) => {
    const result = await ResourceService.createOrUpdate(_orderIntent, OrderIntentSchema);
    setOrderIntent(result.orderIntent);
    return result.orderIntent;
  }

  useEffect(() => {
    loadOrderIntent(id);
  }, [id])

  if (!orderIntent) { return null; }

  return <Cart orderIntent={orderIntent} saveOrderIntent={saveOrderIntent} />
}

//Build a cart from url params
const CartBuilder = () => {
  // const history = useHistory();
  const location = useLocation();

  const search = location.search ? location.search.replace('?', '') : ''
  const query = qs.parse(search);

  let { url } = useRouteMatch();

  const [orderIntent, setOrderIntent] = useState<OrderIntent>();

  const createOrderIntentFromVariants = async (variantIds: string[]) => {
    const result = await ResourceService.createOrUpdate({ variantIds }, OrderIntentSchema);
    setOrderIntent(result.orderIntent);
  }

  useEffect(() => {
    if (typeof query.items === 'string') {
      const variantIds = query.items.split(',');
      createOrderIntentFromVariants(variantIds);
    }
  }, []);// eslint-disable-line

  if (!orderIntent) { return <LinearProgress /> }
  return <Redirect to={`${url}/${orderIntent.id}`} />
}


const Shop = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>

      <Route path={path}>
        <TopAppBar />

        <Route exact path={`${path}/cart/:id`}>
          <CartManager />
        </Route>
        <Route exact path={`${path}/cart`}>
          <CartBuilder />
        </Route>

        <BottomAppBar />
      </Route>
    </Switch>
  )
}

export default Shop;
