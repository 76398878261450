import StripePayment from './Stripe/StripePayment'
import PaypalPayment from './Paypal/PaypalPayment'
import PaymentProps from './PaymentProps'

export default function Payment(props: PaymentProps) {
  return (
    <>
    {props.paymentOption === 'Stripe' && (
      <StripePayment {...props} />
    )}
    {props.paymentOption === 'PayPal' && (
      <PaypalPayment {...props} />
    )}
    </>
  );
}
