
import EditIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { Button } from '@material-ui/core';

const PushDetailButton = (props: any) => {

  const { to, children, color } = props;
  const history = useHistory();
  let { url } = useRouteMatch();

  return (
    <Button
      variant="text"
      color={color || "primary"}
      size="small"
      // style={{ margin: 4, }}
      endIcon={<EditIcon />}
      onClick={() => {
        const newPath = `${url}/${to}`;
        history.push(newPath);
      }}
    >
      {children}
    </Button>
  )
}


const PushDetailButtonWithCount = ({ resource, link }: any) => {
  const items = resource[link.key || link.path];
  const ctaWithCount = items ? `${link.cta} (${items.length})` : link.cta;
  return <PushDetailButton to={link.path}>{ctaWithCount}</PushDetailButton>;
}

export default PushDetailButton;
export { PushDetailButtonWithCount };
