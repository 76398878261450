import { ResourceSchema } from '../services/ResourceService';
import ColumnFactory from '../services/ColumnFactory';

import UserOptions from '../components/UserOptions';
import { HubActionProps, NewRedirectAction, HubAction, AddManyToManyAction, RemoveManyToManyAction } from '../components/CommonResourceHandlers';
import Address from './Address';

const UserHubActionsResolver = (props: HubActionProps): HubAction[] | null => {
  const { schema, parentResourceId } = props;
  var actions = [];
  if (schema.parent && parentResourceId) {
    if (schema.parent.apiResponseResourceItemKey === 'discount') {
      //We can add this user to a discount
      actions.push(AddManyToManyAction());
    }

  } else {
    actions.push(NewRedirectAction());
  }

  return actions;
}

const UserHubBulkActionsResolver = (props: HubActionProps): HubAction[] | null => {
  const { schema, parentResourceId, resource } = props; //todo: resource should be maybe an array of resources or resourceIds

  if (schema.parent && parentResourceId && schema.parent.apiResponseResourceItemKey === 'discount' && resource) {
    return [RemoveManyToManyAction()];
  }

  return null;
}

type User = {
  id: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  emailAddress: string;
  dob: Date;
  gender: string;
  active: boolean;
  testingIn: string;
  billingAddress: Address;
  //   addressOne: string,
  //   addressTwo: string,
  //   city: string,
  //   state: string,
  //   zip: string
  // };
  phoneNumber: string;
  sendBirthdayCard: boolean;
  twelveMonthReminder: boolean;
  futureUpdates: boolean;
  smsUpdates: boolean;
  auth0Roles: Array<string>;
  isValidated: boolean;
};

export type PhoneNumber = {
  number: string;
}

export type CreateUser = {
  dob?: string;
  firstName?: string;
  gender?: string;
  lastName?: string;
  middleInitial?: string;
  phoneNumberAttributes?: PhoneNumber
  billingAddressAttributes?: Address
  auth0Roles?: any;
}

const Links = [{ path: 'orders', cta: 'Orders' }]

// may need this if we have nasty nested stuff-jj
// const UserComparisonHandler = (res1: User, res2: User) => {
//   const baseEqual = ResourceComparitor(res1, res2);
//   const nestedEqual = JSON.stringify(res1.billingAddress) === JSON.stringify(res2.billingAddress)

//   return nestedEqual && baseEqual;
// }

const UserBackendAdaptor = (frontendResource: User) => {
  var r = {
    ...frontendResource,
    billingAddressAttributes: frontendResource.billingAddress,
    phoneNumberAttributes: { number: frontendResource.phoneNumber }
  } as any;

  delete r.isValidated;
  delete r.orders;
  return r;
}

const UserSchema = {
  apiResponseResourceCollectionKey: 'users',
  apiResponseResourceItemKey: 'user',
  apiRequestPath: '/users',
  labels: { name: 'User', pluralName: 'Users', describer: (user: any) => `${user.firstName} ${user.lastName} (${user.emailAddress})` },
  links: Links,
  columns: [
    ColumnFactory.id,
    { field: 'firstName', headerName: 'FIRST NAME', flex: 1, },
    { field: 'lastName', headerName: 'LAST NAME', flex: 1 },
    { field: 'emailAddress', headerName: 'EMAIL', flex: 2 },
    { field: 'auth0Roles', headerName: 'ROLES', flex: 1 },
    ColumnFactory.check({ field: 'active', headerName: 'ACTIVE' }),
    ColumnFactory.links(Links),
    ColumnFactory.actions()
  ],
  columnsForMobile: [],
  optionHandler: UserOptions,
  actionsResolver: UserHubActionsResolver,
  bulkActionsResolver: UserHubBulkActionsResolver,
  // comparisonHandler: UserComparisonHandler, //may need this if we have nasty nested stuff
  adaptor: { toBackend: UserBackendAdaptor }

} as ResourceSchema;

const ProfileSchema = {
  apiResponseResourceCollectionKey: 'profiles',
  apiResponseResourceItemKey: 'user',
  apiRequestPath: '/profile',
  labels: { name: 'Profile', pluralName: 'Profile', describer: UserSchema.labels.describer },
  columns: [],
  columnsForMobile: [],
  optionHandler: UserOptions,
  idResolver: () => { return ''; },
  // comparisonHandler: UserComparisonHandler, //may need this if we have nasty nested stuff
  adaptor: { toBackend: UserBackendAdaptor }


} as ResourceSchema;

export { UserSchema, ProfileSchema };

export default User;
