import { useCallback, useState } from 'react'
import {
  Button,
  Grid,
  TextField,
  Typography,
  Chip,
} from '@material-ui/core';


import DeleteIcon from '@material-ui/icons/Delete';
import { useUserContext } from '../context/UserContext'
import { useResourceContext } from '../context/ResourceProvider'
import formatCurrency from '../utils'
import { parentedSchema, CreateUpdateMethods } from '../services/ResourceService'
import AppliedDiscountSchema from '../models/Discounts'
import { OrderSchema } from '../models/Order'
import { useMutableResourceContext } from '../context/MutableResourceProvider';
import EditState, { EditProps } from './EditState';

const Discount = ({ editing, setEditing }: EditProps) => {

  const { isAuthenticated } = useUserContext()
  const { memberAction } = useResourceContext()
  const { resource, setResource, ogResource, loadResource, resourceIsDirty } = useMutableResourceContext();
  const order = resource;
  const ogOrder = ogResource;
  const loadOrder = loadResource;

  const hasSavedPayment = () => Boolean(ogOrder.paymentTransaction);

  const [errorMessage, setErrorMessage] = useState<string>('')
  const appliedDiscount = ogOrder.discount;

  const setCode = (c: string) => {
    var o = { ...order, volitileDiscountCode: c } as any;
    setResource(o);
    setEditing(true);
  }

  const appyDiscount = useCallback(
    async (id: string, orderId: string) => {

      const data = await memberAction(
        'apply',
        { id },
        parentedSchema(AppliedDiscountSchema, OrderSchema),
        { method: CreateUpdateMethods.POST, parentResourceId: orderId }
      )

      return data;
    }, []); // eslint-disable-line

  const removeDiscount = useCallback(
    async (id: string, orderId: string) => {
      const data = await memberAction(
        'remove',
        { id },
        parentedSchema(AppliedDiscountSchema, OrderSchema),
        { method: CreateUpdateMethods.POST, parentResourceId: orderId }
      )

      return data;
    }, []); // eslint-disable-line

  const onSuccess = () => {
    loadOrder(true);
  }

  if (!isAuthenticated) return null
  if (!order) return null

  const handleCodeChange = (event: any) => {
    const { target } = event
    const { value } = target

    setErrorMessage('')
    setCode(value)
  }

  const confirmApply = async () => {
    if (order.volitileDiscountCode) {
      try {
        await appyDiscount(order.volitileDiscountCode, order.id)
        return onSuccess()
      } catch (err: any) {
        let msg = err.message || 'Trouble with discount code.'

        if (err.status && err.status === 404) {
          msg = 'Discount code was not found.'
        }
        setErrorMessage(msg)
      }
    }
  }


  const handleDelete = async () => {
    try {
      await removeDiscount(appliedDiscount.code, order.id)
      setCode('');
      return onSuccess()
    } catch (err: any) {
      let msg = err.message || 'Trouble removing discount.'

      if (err.status && err.status === 404) {
        msg = 'Discount code was not found.'
      }

      setErrorMessage(msg)
    }
  }

  const hasSavedAddress = () => Boolean(ogOrder.billingAddress);
  const canShow = () => hasSavedAddress() && !hasSavedPayment()//&& didConfirmReadyForPayment
  const canDelete = () => !hasSavedPayment()
  if (!canShow()) { return null; }

  return (
    <EditState editing={editing} setEditing={setEditing}>
      <Grid container direction='row' justifyContent="space-between" style={{paddingTop: '24px'}}>
        {appliedDiscount && (
          <>
            <Grid item>
              <Typography variant="h6" display="block" gutterBottom>
                Current Discount:
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                label={`${appliedDiscount.code}: ${appliedDiscount.valueType === 'flat' ? formatCurrency(appliedDiscount.amount) : appliedDiscount.percentOff + '%'}`}
                onDelete={canDelete() ? handleDelete : undefined}
                deleteIcon={<DeleteIcon />}
                variant="outlined"
                color="primary"
              />
            </Grid>
          </>
        )}
        {!appliedDiscount && (
          <>
            <Grid item xs={12} style={{ marginBottom: '1em', display: 'flex' }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Discount Code"
                name="code"
                onChange={handleCodeChange}
                type="text"
                style={{marginRight: '10px', background: '#fff'}}
                value={order.volitileDiscountCode || ''}
                helperText={errorMessage}
                disabled={!editing && resourceIsDirty()}
              />
              <Button variant="contained" color="primary" style={{whiteSpace: 'nowrap', padding: '0 20px', fontWeight: 'bold'}} onClick={confirmApply}>Apply</Button>
            </Grid>
          </>
        )}
      </Grid>
    </EditState>
  )
}

export default Discount;
