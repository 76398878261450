import { Button } from '@material-ui/core';

interface Props {
  disabled?: boolean,
  onClick: () => Promise<void>
  text: string
}

const PaymentButton = ({ disabled = false, onClick, text = '...' }: Props) => {

  return (
    <Button
      fullWidth variant="contained" color="primary"
      disabled={disabled}
      id="payment-button"
      onClick={onClick}
      type="button"
    >
      {text}
    </Button>
  )
}

export default PaymentButton
