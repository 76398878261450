
import React from 'react';
import { FilterOption } from '../services/ResourceService';

type Props = {
  children: any;
};


type SearchContextTypes = {
  query: string | undefined;
  setQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
  filters: FilterOption[] | undefined;
  setFilters: React.Dispatch<React.SetStateAction<FilterOption[] | undefined>>;
}

// const defaultSearchContext = {
//   query: undefined,
//   setQuery: () => { }
// };

export const SearchContext = React.createContext<SearchContextTypes>({} as SearchContextTypes);
export const useSearchContext = () => React.useContext(SearchContext);

const SearchProvider: React.FC<Props> = ({ children }: Props) => {

  const [query, setQuery] = React.useState<string | undefined>();
  const [filters, setFilters] = React.useState<FilterOption[] | undefined>([]);

  return <SearchContext.Provider value={{ query, setQuery, filters, setFilters }}>{children} </SearchContext.Provider>
}

export default SearchProvider;
