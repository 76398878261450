import { useState } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  Button
} from '@material-ui/core';
import { useUserContext, UserRole } from '../context/UserContext'
import { useMutableResourceContext } from '../context/MutableResourceProvider';

import RelationshipSelectorDialog from './RelationshipSelectorDialog';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import User, { UserSchema } from '../models/User'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      '& .MuiTypography-body2': {
        margin: 2
      }
    },
    [theme.breakpoints.up('md')]: {
      '& .MuiTypography-body2': {
        margin: 12
      }
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
    color: theme.palette.primary.light,
  }
}));

interface CareOrderUserSelectorProps {
  setEditing: any;
}

const CareOrderUserSelector = ({ setEditing }: CareOrderUserSelectorProps) => {
  const [showingUserSelector, showUserSelector] = useState(false);
  const { userRole } = useUserContext();

  const { resource, setResource, ogResource } = useMutableResourceContext();
  const order = resource;
  const ogOrder = ogResource;
  const setOrder = setResource;

  const isSubmitted = () => {
    return order.pwnOrders && order.pwnOrders.length > 0;
  }

  const hasSavedPayment = () => Boolean(ogOrder.paymentTransaction);


  // User is now set from the order
  // const user = order.user;
  const setUser = async (user: User) => {
    const o = { ...order };
    o.userId = user.id;
    o.user = user;
    o.billingAddress = { ...user.billingAddress };
    o.shippingAddress = { ...user.billingAddress };
    o.phoneNumber = { number: user.phoneNumber };
    setOrder(o); //don't save the order just yet
    setEditing(true);
  }

  if (userRole <= UserRole.CUSTOMER || hasSavedPayment()) { return null; }

  return (
    <>
      <Button variant="outlined" onClick={() => showUserSelector(true)} endIcon={<ArrowDropDownIcon />}>Customer: {UserSchema.labels.describer(order.user)}</Button>
      <RelationshipSelectorDialog
        open={showingUserSelector} setOpen={showUserSelector}
        schema={UserSchema}
        onDidRelateResource={setUser}
        cta="Assign to customer"
        disabled={isSubmitted()} />
    </>
  )

}

const CareOrderUser = () => {
  const classes = useStyles()
  const { handleLogout, user, userRole } = useUserContext();

  const doLogout = () => {
    handleLogout({ returnTo: window.location.origin })
  }

  if (userRole !== UserRole.CUSTOMER) { return null }

  return (
    <Grid item xs={12} className={classes.root}>
      <Typography variant="body2" display="block" gutterBottom >
        You are logged in as {user?.firstName}&nbsp;{user?.lastName}.
        Not you? (<span className={classes.link} onClick={doLogout}>Sign Out</span>)
      </Typography>
    </Grid>
  )
}

export default CareOrderUser
export { CareOrderUserSelector }
