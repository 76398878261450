import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RelationSelector from './RelationSelector';
import { Button } from '@material-ui/core';

const RelationshipSelectorDialog = ({ schema, open, setOpen, onDidRelateResource, cta = "Relate" }: any) => {

  const [relatedResource, setRelatedResource] = useState<any>();

  const onCancel = () => {
    setOpen(false);
  }

  const onConfirm = () => {
    onDidRelateResource(relatedResource);
    setOpen(false);
  }


  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title">Relationship</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Pick an existing {schema.labels.name}
        </DialogContentText>
        <RelationSelector schema={schema} setRelatedResource={setRelatedResource} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {cta}
        </Button>
      </DialogActions>

    </Dialog>
  )
}

export default RelationshipSelectorDialog
