import React from 'react';
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Grid, InputAdornment } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useResourceContext } from '../context/ResourceProvider';
import ResourceService, { ResourceSchema } from '../services/ResourceService';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
  relatedResource?: any;
  setRelatedResource: (resource: any) => void;
  schema: ResourceSchema;
  disabled?: boolean;
}

const RelationSelector = ({ relatedResource, setRelatedResource, schema, disabled = false }: Props) => {

  const { index } = useResourceContext();
  // const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [search, setSearch] = React.useState("");

  const hubLinkPath = relatedResource?.id && ResourceService.hubPathForSchema(schema, relatedResource.id);

  async function loadOptions(query: string) {
    const results = await index(schema, { query });
    setOptions(results);
  }

  React.useEffect(() => {
    loadOptions(search)
  }, [search]); // eslint-disable-line

  const defaultGetOptionLabel = (option: any) => option.name;

  return (
    <Grid container direction="row" alignItems="stretch" spacing={2}>
      <Grid item xs={10}>
        <Autocomplete
          id="combo-box-demo"
          options={options}
          getOptionLabel={schema.labels.describer || defaultGetOptionLabel}
          style={{ width: 300, paddingTop: 20 }}
          renderInput={(params: any) => <TextField {...params} label={`Choose ${schema.labels.name}`} variant="outlined" onChange={ev => {
            // dont fire API if the user delete or not entered anything
            if (ev.target.value !== "" || ev.target.value !== null) {
              setSearch(ev.target.value);
            }
          }}
            InputProps={{ ...params.InputProps, startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment> }}
          />}
          value={relatedResource}
          onChange={(_: any, newValue: any) => { setRelatedResource(newValue) }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={1}>
        {hubLinkPath && <Link target="_blank" to={hubLinkPath}><OpenInNewIcon color='secondary' style={{ height: "100%", padding: 8 }} /></Link>}
      </Grid>
    </Grid>
  );
};

export default RelationSelector
