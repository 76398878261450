import { useEffect, useState } from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';
import { ResourceSchema, CreateUpdateMethods } from '../services/ResourceService';

import RelationshipSelectorDialog from './RelationshipSelectorDialog';
import { useResourceContext } from '../context/ResourceProvider';
import { useSnackContext } from '../context/SnackProvider';
import ConfirmDialog from './ConfirmDialog';
import { ResourceDetailsDialog } from './ResourceDetails';

export type HubActionProps = {
  schema: ResourceSchema;
  parentResourceId?: string;
  resource?: any;
  onDone: any;
}

export const ResourceRedirectHandler = (props: HubActionProps) => {
  let { url } = useRouteMatch();

  useEffect(() => {
    props.onDone();
  }, []) // eslint-disable-line

  return <Redirect to={`${url}/${props.resource?.id}`} />
}


export const NewRedirectHandler = (props: HubActionProps) => {
  let { url } = useRouteMatch();
  useEffect(() => {
    props.onDone();
  }, []) // eslint-disable-line

  return <Redirect to={`${url}/new`} />
}

const NewRedirectAction = () => {
  return {
    callToAction: (p: HubActionProps) => { return `New ${p.schema.labels.name}` },
    component: NewRedirectHandler
  } as HubAction;
};


export const AddManyToManyHandler = (props: HubActionProps) => {
  const { schema, parentResourceId } = props;
  const [open, setOpen] = useState(true);
  const { schemaFetch } = useResourceContext();
  const { setSnack } = useSnackContext();

  const setRelatedResource = async (resource: any) => {

    await schemaFetch(resource.id, schema, { method: CreateUpdateMethods.POST, parentResourceId })

    setSnack({ message: `Successfully Added ${schema.labels.describer(resource)}` });
    props.onDone();

  }

  return (
    <RelationshipSelectorDialog schema={schema} open={open} setOpen={setOpen} cta="Add" onDidRelateResource={setRelatedResource} />
  )

}

const AddManyToManyAction = () => {
  return {
    callToAction: (p: HubActionProps) => { return `Add existing ${p.schema.labels.name} to ${p.schema.parent?.labels.name}` },
    component: AddManyToManyHandler
  } as HubAction;
};



export const RemoveManyToManyHandler = (props: HubActionProps) => {
  const { schema, parentResourceId, resource } = props;
  const [open, setOpen] = useState(true);
  const { destroy } = useResourceContext();
  const { setSnack } = useSnackContext();

  const confirmRemove = async () => {
    await destroy(resource.id, schema, { parentResourceId })
    setSnack({ message: `Successfully Removed ${schema.labels.describer(resource)}` });
    props.onDone();

  }

  if (!resource) { <div>Loaading resource</div> }

  return (
    <ConfirmDialog open={open} setOpen={setOpen} message={`Do you really want to remove selected ${schema.labels.describer(resource)} from ${schema.parent?.labels.name}?`} onConfirm={confirmRemove} />
  )

}

const RemoveManyToManyAction = () => {
  return {
    callToAction: (p: HubActionProps) => { return `Remove selected ${p.schema.labels.name} from ${p.schema.parent?.labels.name}` },
    component: RemoveManyToManyHandler
  } as HubAction;
}



export const CreateOneToManyHandler = (props: HubActionProps) => {
  const { schema, parentResourceId } = props;

  return <ResourceDetailsDialog
    schema={schema}
    parentResourceId={parentResourceId}
    resourceId='new'
    onDidSaveResource={props.onDone}
    onDidClose={props.onDone}
  />
}


const CreateOneToManyAction = () => {
  return {
    callToAction: (p: HubActionProps) => { return `Create new ${p.schema.labels.name} in ${p.schema.parent?.labels.name}` },
    component: CreateOneToManyHandler
  } as HubAction;
};


export const AddOneToManyHandler = (props: HubActionProps) => {
  const { schema, parentResourceId } = props;
  const [open, setOpen] = useState(true);
  const [selectedResource, setSelectedResource] = useState<any>();

  const setRelatedResource = async (resource: any) => {
    setSelectedResource(resource);
  }
  if (selectedResource) {
    return <ResourceDetailsDialog
      schema={schema}
      parentResourceId={parentResourceId}
      resourceId={selectedResource.id}
      onDidSaveResource={props.onDone}
      onDidClose={props.onDone}
    />
  } else {
    return <RelationshipSelectorDialog schema={schema} open={open} setOpen={setOpen} cta="Add" onDidRelateResource={setRelatedResource} />
  }
}

const AddOneToManyAction = () => {
  return {
    callToAction: (p: HubActionProps) => { return `Add existing ${p.schema.labels.name} to ${p.schema.parent?.labels.name}` },
    component: AddOneToManyHandler
  } as HubAction;
};


export const RemoveOneToManyHandler = (props: HubActionProps) => {
  const { schema, resource } = props;
  // const [open, setOpen] = useState(true);

  return <ResourceDetailsDialog
    schema={schema}
    parentResourceId={null}
    resourceId={resource.id}
    onDidSaveResource={props.onDone}
    onDidClose={props.onDone}
  />


}

const RemoveOneToManyAction = () => {
  return {
    callToAction: (p: HubActionProps) => { return `Remove selected ${p.schema.labels.name} from ${p.schema.parent?.labels.name}` },
    component: RemoveOneToManyHandler
  } as HubAction;
};




// /RemoveOneToManyAction


export { AddManyToManyAction, NewRedirectAction, RemoveManyToManyAction, AddOneToManyAction, RemoveOneToManyAction, CreateOneToManyAction };


export interface HubAction {
  callToAction: (props: HubActionProps) => string;
  component: any;
}



export { };
