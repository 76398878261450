import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import Auth0ProviderWithHistory from "./context/Auth0ProviderWithHistory";

import ResourceProvider from './context/ResourceProvider';
import UserProvider from './context/UserProvider'
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#b20909'
    },
    secondary: {
      main: '#F4F4F4'
    },
  },
  typography: { fontFamily: ['Arial', 'Helvetica'].join(',') }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <ResourceProvider >
            <UserProvider>
              <App />
            </UserProvider>
          </ResourceProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
