
import { Grid, Typography } from '@material-ui/core';

interface Props {
  label: string;
  children: any;
}

const HeaderRow = ({ label, children }: Props) => {
  return (
    <Grid key={`header-${label}`} item container spacing={1}>
      <Grid item xs={4}>
        <Typography variant='body1' align='right'><strong>{label}:</strong></Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant='body1'> {children}</Typography>
      </Grid>
    </Grid>
  )
};

export default HeaderRow;
