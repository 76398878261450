import { useState, useContext } from 'react';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { useResourceContext } from '../context/ResourceProvider';
import { SnackContext } from "../context/SnackProvider";
import { DropzoneArea } from 'material-ui-dropzone'
import { PwnOrderSchema } from '../models/PwnOrder';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const ResultUpload = ({ pwnOrder, onDone }: any) => {
  const [processing, setProcessing] = useState(false);
  const [files, setFiles] = useState<any[]>();

  const { memberUpload } = useResourceContext();
  const { setSnack } = useContext(SnackContext);

  const canSave = files && files.length > 0 && !processing;

  const handleSave = async () => {
    if (!files) { return }

    //POST http://localhost:3000/api/v1/pwn_orders/61575a617aec092700a525fa/upload
    //args: test_results_file

    setProcessing(true);
    const formData = new FormData();
    const file = files[0];
    if (file) {
      formData.append('test_result_file', files[0]);
    }

    try {
      const result = await memberUpload('upload', formData, pwnOrder, PwnOrderSchema);
      setSnack({ message: 'Results Uploaded' });
      onDone(result);
    } catch (e) {
      console.error(e);
      if (e.errors.error) {
        setSnack({ message: e.errors.error });
      } else if (e.errors.Error) {
        setSnack({ message: e.errors.Error });
      } else if (e.errors.refund) {
        setSnack({ message: e.errors.refund.join(',') });
      }
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Dialog open={true} onClose={() => onDone()} aria-labelledby="form-dialog-title" fullWidth={true}>
      <DialogTitle id="form-dialog-title">Upload Test Results</DialogTitle>
      <DialogContent>

        <DropzoneArea
          onChange={setFiles}
          showPreviews={false}
          maxFileSize={5000000} />

        {canSave && <Alert severity="warning">Uploading this test results file will overwrite any existing results file</Alert>}

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onDone()}>Cancel</Button>

        <Button variant="contained" color="primary" onClick={() => handleSave()} disabled={!canSave}>Save</Button>
      </DialogActions>

    </Dialog>

  )
}
export default ResultUpload
