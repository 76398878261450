import { ResourceSchema } from '../services/ResourceService';

const AppliedDiscountSchema = {
  apiResponseResourceCollectionKey: 'discounts',
  apiResponseResourceItemKey: 'discount',
  apiRequestPath: '/discounts',
  labels: { name: 'Discount', pluralName: 'Discounts', describer: (resource) => resource.id },
  columns: [],
  columnsForMobile: [],
  optionHandler: {}
} as ResourceSchema;


export default AppliedDiscountSchema
