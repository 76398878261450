import { splitByLab } from './split-by-lab'
import getNestedValue from './getNestedValue'

const formatCurrency = (amt: any) => {
  if (!amt) return

  const value = new Intl.NumberFormat(
    'en-US',
    { style: 'currency', currency: 'USD' }
  ).format(amt)

  return value
}

const formatDateString = (dateString: string) => {
  const d = new Date(dateString);
  return `${d.toLocaleDateString()} at ${d.toLocaleTimeString()}`
}

export { getNestedValue, splitByLab, formatDateString }
export default formatCurrency
