
import React, { useState, useEffect } from 'react';
import { useUserContext, UserRole } from './UserContext';
import { useResourceContext } from './ResourceProvider';
import { ResourceSchema, ResourceComparitor } from '../services/ResourceService';
import { LinearProgress, Box, Typography } from '@material-ui/core';

type Props = {
  resourceIdentifier: string;
  parentResourceIdentifier?: string | null;
  schema: ResourceSchema;
  children: any;
  onDidSaveResource?: (r: any) => void;
};

//TODO: this should have a generic type within it
type MutableResourceContextTypes = {
  schema: ResourceSchema;
  resource: any;
  ogResource: any;
  setResource: React.Dispatch<React.SetStateAction<any>>;
  setOGResource: React.Dispatch<React.SetStateAction<any>>;
  loadResource: (force: boolean) => Promise<any>;
  saveResource: (r?: any) => Promise<any>;
  destroyResource: () => Promise<any>;
  resourceIsDirty: () => boolean;
  resourceLoading: boolean;
  resourceSaving: boolean;
  parentResource?: any;
  validationErrors?: any;
  revertResourceToOG: () => void;
}


export const MutableResourceContext = React.createContext<MutableResourceContextTypes>({} as MutableResourceContextTypes);
export const useMutableResourceContext = () => React.useContext(MutableResourceContext);

const MutableResourceProvider: React.FC<Props> = ({ schema, resourceIdentifier, parentResourceIdentifier, children, onDidSaveResource }: Props) => {
  const { read, createOrUpdate, destroy } = useResourceContext();

  const [resource, setResource] = useState(resourceIdentifier === 'new' ? {} as any : undefined)
  const [ogResource, setOGResource] = useState(resourceIdentifier === 'new' ? {} as any : undefined)

  const [parentResource, setParentResource] = useState();
  const [resourceLoading, setResourceLoading] = useState(true);
  const [resourceSaving, setResourceSaving] = useState(false);

  const [validationErrors, setValidationErrors] = useState();

  const { userRole } = useUserContext();

  const loadResource = async (force = false) => {

    if (userRole >= UserRole.CUSTOMER && (resourceIdentifier || force)) {

      setResourceLoading(true);

      try {
        const r = resourceIdentifier === 'new' ? {} : await read(resourceIdentifier, schema);
        if (parentResourceIdentifier && schema.parent) {
          const pr = await read(parentResourceIdentifier, schema.parent)
          setParentResource(pr);
        }

        setOGResource(r);
        setResource({ ...r });
        //TODO: Maybe need to reload parent if parent changed???
        setResourceLoading(false);

      } catch (e) {
        setResourceLoading(false);
        console.error(e);
        throw e;
      }
    }
  }

  const handleErrors = (e: any) => {
    if (e.errors) {
      setValidationErrors(e);
    } else if (e.message) {
      setValidationErrors({ Error: e.message } as any);
    } else {
      setValidationErrors({ errors: e } as any);
    }

    //Make sure we throw this error to cancel any downstream success conditions
    throw e;
  }

  //Save the given resource or the one we've been dealing with
  const saveResource = async (r?: any) => {
    const resourceToSave = r || resource;

    // const backendResource = schema.adaptor?.toBackend ? schema.adaptor?.toBackend(resourceToSave) : resourceToSave;

    setResourceSaving(true);
    setValidationErrors(undefined);

    try {
      const updatedResource = await createOrUpdate(
        resourceToSave,
        schema
      );

      setResource(updatedResource);
      setOGResource({ ...updatedResource });
      if (onDidSaveResource) { onDidSaveResource(updatedResource) };

      return updatedResource;

    } catch (e) {
      console.error("CAUGHT ERROR", { e });
      handleErrors(e);
    } finally {
      setResourceSaving(false);
    }
  }

  const resourceIsDirty = () => {
    return !ResourceComparitor(resource, ogResource);
  }

  const destroyResource = async () => {
    try {
      await destroy(resource.id, schema);
      if (onDidSaveResource) { onDidSaveResource(resource) }; //TODO: should we have separate callback for destroy?

    } catch (e) {
      console.error("Could not destroy resource", { resource }, e);
      handleErrors(e);
    } finally {
      setResourceLoading(false);
    }
  }

  const revertResourceToOG = () => {
    setResource({ ...ogResource });
  }


  useEffect(() => {
    loadResource();
  }, [userRole, resourceIdentifier]) // eslint-disable-line

  if (resourceLoading) { return <LinearProgress /> }

  // We should have a valid resource by this point, if we don't, show some errors
  else if (!resource || !ogResource) {
    // 404 empty state
    return (
      <Box p={16}>
        <Typography variant="h6" gutterBottom>{schema.labels.name} not found</Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          {resourceIdentifier || '---'}
        </Typography>
      </Box>
    )
  }

  return <MutableResourceContext.Provider value={{ schema, parentResource, resource, setResource, ogResource, setOGResource, saveResource, loadResource, destroyResource, revertResourceToOG, resourceLoading, resourceSaving, resourceIsDirty, validationErrors }}>
    {children}
  </MutableResourceContext.Provider>
}

export default MutableResourceProvider;
