import { ResourceSchema } from '../services/ResourceService';
import ColumnFactory from '../services/ColumnFactory';

import ProductVariant from './ProductVariant'
import LineItemOptions from '../components/LineItemOptions';
import formatCurrency, { getNestedValue } from '../utils';

export type LineItem = {
  price: number
  variantId: string
  id?: string
  state?: string
  variant?: ProductVariant
}

const LineItemsSchema = {
  apiResponseResourceCollectionKey: 'lineItems',
  apiResponseResourceItemKey: 'lineItem',
  apiRequestPath: '/line_items',
  labels: { name: 'Line Item', pluralName: 'Line Items', describer: (resource) => resource.orderNumber },
  columns: [
    { field: 'pwnOrder.externalOrderId', headerName: 'REQ. ID', flex: 1, valueGetter: getNestedValue },
    { field: 'variant.lab.name', headerName: 'Lab', flex: 1, valueGetter: getNestedValue },
    { field: 'variant.name', headerName: 'Test', flex: 1, valueGetter: getNestedValue },
    { field: 'price', headerName: 'Total', flex: 1, valueFormatter: (param: any) => param.value && formatCurrency(param.value) },
    { field: 'aasmState', headerName: 'Status', flex: 1 },
    ColumnFactory.actions()
  ],
  columnsForMobile: [],
  optionHandler: LineItemOptions,
  hideSearchAction: true
} as ResourceSchema;

export { LineItemsSchema };
export default LineItem;
