const getNestedValue = (params:any) => {
  let path = params.field.split('.');
  let v:any = params.row[path.shift()];
  while(v && path.length > 0) {
    v = v[path.shift()];
  }
  return v;
}

export default getNestedValue
