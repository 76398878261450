import { useEffect, useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";

import { LinearProgress, Paper, Typography, Grid, Button, Box } from '@material-ui/core';

import UserContext, { LogoutArgs, UserRole } from './UserContext'
import UserProfileModal from '../components/UserProfileModal'
import User, { CreateUser, ProfileSchema } from '../models/User'
import ResourceService from '../services/ResourceService';
import { useResourceContext } from './ResourceProvider';

const UserProvider = ({ children }: any) => {
  const { createOrUpdate } = useResourceContext();

  const {
    isLoading: authLoading,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user: auth0User
  } = useAuth0()

  const [user, setUser] = useState<User | null>()
  const [enrollmentUser, setEnrollmentUser] = useState<CreateUser>();
  const [userRole, setUserRole] = useState<UserRole>(UserRole.UNKNOWN);
  const [enrollmentErrors, setEnrollmentErrors] = useState<any>();
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const [permissions, setPermissions] = useState<string[]>([])

  const getPermissionsFromToken = (token: string): void => {
    const decoded: any = jwt_decode(token);

    if (typeof decoded === 'object') {
      setPermissions(decoded.permissions);
    }
  };

  const getUser = async () => {
    let token = '';
    try {
      token = await getAccessTokenSilently()
    } catch (e) {
      console.error("Error retrieving token from Auth0", e);
      logout({ returnTo: window.location.origin });
    }

    try {
      const results = await ResourceService.read("", ProfileSchema, { token });

      getPermissionsFromToken(token)

      setUser(results)

      return

    } catch (e: any) {
      if (e.status === 404) {
        const _user: CreateUser = {
          dob: undefined,
          firstName: auth0User?.given_name,
          gender: undefined,
          lastName: auth0User?.family_name,
          middleInitial: undefined, //TODO: check user creation
        }
        setEnrollmentUser(_user);

        return
      }
      if (e.status === 406) {
        console.error("user needs to verify email address");
        setVerifyEmail(true);
      }

    }
  }


  useEffect(() => {
    console.info({ isAuthenticated, user, authLoading });

    if (authLoading) { return };

    if (isAuthenticated && !user) {
      getUser();
    }
  }, [getAccessTokenSilently, isAuthenticated, user, setUser, auth0User, authLoading]) // eslint-disable-line

  useEffect(() => {
    var newRole = UserRole.UNKNOWN;

    if (authLoading) {
      newRole = UserRole.UNKNOWN;
    } else if (!isAuthenticated) {
      newRole = UserRole.GUEST
    } else if (user === null) {
      newRole = UserRole.CUSTOMER;
    } else {
      if (hasRole(user, 'admin')) {
        newRole = UserRole.ADMIN;
      } else if (hasRole(user, 'csr')) {
        newRole = UserRole.CSR;
      } else if (hasRole(user, 'customer')) {
        newRole = UserRole.CUSTOMER;
      }
    }
    console.info("Setting new user role", { newRole, user, authLoading, isAuthenticated });
    setUserRole(newRole);
  }, [user, isAuthenticated, authLoading]);

  const handleLogin = (appState: any) => {
    loginWithRedirect({ appState })
  }

  const handleLogout = (args: LogoutArgs | undefined = { returnTo: window.location.origin }) => {
    setUser(null)
    setUserRole(UserRole.UNKNOWN);
    logout(args)
  }

  const handleCancelEnrollment = (args: LogoutArgs) => {
    setUser(null)
    setEnrollmentUser(undefined);
    logout(args)
  }

  const handleSaveUser = async (args: Partial<CreateUser>) => {
    try {
      setLoading(true);
      setEnrollmentErrors(undefined);
      const user: any = await createOrUpdate(args, ProfileSchema);

      if (user) {
        setUser(user)
        setEnrollmentUser(undefined);
      }
      setLoading(false);

    } catch (err) {
      setLoading(false);
      setEnrollmentErrors(err);
    }
  }

  const hasPermission = (permission: string): boolean => { return permissions.indexOf(permission) > -1 };
  const hasRole = (user: CreateUser | User | undefined | null, role: string): boolean | undefined => {
    return user && user.auth0Roles ? user.auth0Roles.indexOf(role) > -1 : undefined;
  };

  if (enrollmentUser) {
    return (
      <UserProfileModal
        key="user-profile-modal"
        onCancel={() => handleCancelEnrollment({ returnTo: window.location.origin })}
        onSave={handleSaveUser}
        user={enrollmentUser}
        validationErrors={enrollmentErrors}
        loading={loading}
      />)
  }
  if (verifyEmail) {
    return (
      <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={10} md={3}>
          <Paper>
            <Box p={3}>
              <Typography>You must verify your email address before completing enrollment. Check your email for a link to activate.</Typography>
              <hr />
              <Grid container direction="row" justifyContent="center">
                <Button onClick={() => handleLogout()}>SIGN IN AS A DIFFERENT USER</Button>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    )
  }
  if (userRole === UserRole.UNKNOWN) { return <LinearProgress /> }


  return (
    <UserContext.Provider value={{
      handleLogin,
      handleLogout,
      isAuthenticated,
      // isEnrolled,
      isLoading: authLoading,
      user,
      reloadUser: getUser,
      permissions,
      hasPermission,
      hasRole,
      userRole
    }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
