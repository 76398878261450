import { TextField, InputAdornment } from '@material-ui/core';

interface Props {
  onChange: (e: any) => void
  value: Number
  name: string
  label: string
  error?: boolean
  fullWidth?: boolean
  disabled?: boolean
}

const MoneyField = ({ onChange, value, name, label, error, fullWidth = false, disabled = false }: Props) => {
  return (
    <TextField
      label={label}
      name={`${name}`}
      value={value}
      onChange={onChange}
      error={error}
      type="number"
      inputProps={{ pattern: "^\\d+(.|\\,)\\d{2}$" }}
      InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
      fullWidth={fullWidth}
      disabled={disabled}
    />
  )
}

export default MoneyField;
