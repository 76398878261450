import { Redirect, useLocation } from "react-router-dom";
import { useUserContext, UserRole } from '../context/UserContext';
import AuthGateway from "./AuthGateway";

const Root = () => {
    const { userRole } = useUserContext();
    let query = new URLSearchParams(useLocation().search);

    if (query.get('error')) {
        return <AuthGateway message={query.get('error_description') || query.get('error') || ''} />
    }

    // Options for landing here
    // Admin or CSR user redirect to /hub
    // Customer user redirect to /care

    const canAccessHub = userRole >= UserRole.CSR; //todo: use a user with role function
    const canAccessCare = userRole >= UserRole.CUSTOMER;

    if (canAccessHub) {
        return <Redirect to="/hub/orders" />
    } else if (canAccessCare) {
        return <Redirect to="/care/orders" />
    }

    //otherwise show login
    return <AuthGateway />
}
export default Root
