import { ResourceSchema } from '../services/ResourceService';
import ColumnFactory from '../services/ColumnFactory';

import LabOptions from '../components/LabOptions';
import { HubActionProps, NewRedirectAction, HubAction } from '../components/CommonResourceHandlers';
import { UserRole, useUserContext } from '../context/UserContext';


const LabHubActionsResolver = (props: HubActionProps): HubAction[] | null => {
  const { userRole } = useUserContext();

  const { schema, parentResourceId } = props;

  if (userRole >= UserRole.ADMIN) {

    var actions = [];
    if (schema.parent && parentResourceId) {
      //Section if there a parent to product
      //We can add this user to a discount

    } else {
      actions.push(NewRedirectAction());
    }

    return actions;
  }

  return null;
}

type Lab = {
  id: string;
  name: string;
  color: string;
  sendOut: boolean;
};

const Links = [{ path: 'variants', cta: 'Variants' }]

const LabSchema = {
  apiResponseResourceCollectionKey: 'labs',
  apiResponseResourceItemKey: 'lab',
  apiRequestPath: '/labs',
  labels: { name: 'Lab', pluralName: 'Labs', describer: (resource) => resource.name },
  links: Links,
  columns: [
    ColumnFactory.id,
    { field: 'name', headerName: 'NAME', width: 150 },
    { field: 'color', headerName: 'COLOR', width: 150 },
    { field: 'sendOut', headerName: 'SEND TO PWN', width: 150 },
    ColumnFactory.links(Links),
    ColumnFactory.actions()
  ],
  columnsForMobile: [],
  optionHandler: LabOptions,
  actionsResolver: LabHubActionsResolver

} as ResourceSchema;

const MockLabs = [
  { id: '1', name: 'Lab Core', slug: 'lab-core', color: '#f18d00' },
  { id: '2', name: 'Quest', slug: 'quest', color: '#358cba' }
]

const LabService = {
  index: async () => {
    return MockLabs;
  },
  read: async (id: string) => {
    return MockLabs.find((candidate: any) => candidate.id === id);
  }
};

export { LabService, MockLabs, LabSchema };

export default Lab;
