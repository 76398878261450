import React from 'react';
import { DataGrid, GridSortModel, GridSelectionModel } from '@material-ui/data-grid'
import { Box, makeStyles, Typography, Grid, Theme, useTheme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { Route, useRouteMatch, useParams } from 'react-router-dom';
import { ResourceSchema } from '../services/ResourceService';
import { useResourceContext } from '../context/ResourceProvider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchField from './SearchField';
import FilterField from './FilterField';
import ResourceDetails from './ResourceDetails';
import { FilterOption } from '../services/ResourceService';
import { SearchContext } from '../context/SearchProvider';
import ResourceActionMenu from './ResourceActionMenu';
import { SnackContext } from "../context/SnackProvider";

const useStyles = makeStyles((theme: Theme) => {
  return {
    box: {
      [theme.breakpoints.down('md')]: {
        padding: '45px 0px',
        width: '100%'
      },
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 64px)',
        padding: 32
      }
    }
  }
})


interface Props {
  schema: ResourceSchema;
  scopedParentResourceId?: string;
  hideExtras?: boolean;
}
const DEFAULT_SORT: GridSortModel = [{ field: 'createdAt', sort: 'desc' }];

const ResourceIndex = ({ schema, scopedParentResourceId, hideExtras }: Props) => {
  const styles = useStyles()
  const theme = useTheme()

  const [entries, setEntries] = React.useState<any>(null);
  const [query, setQuery] = React.useState<string | undefined>();
  const [filters, setFilters] = React.useState<FilterOption[] | undefined>([]);
  const params = useParams<any>();
  const parentResourceId = scopedParentResourceId || params.parentResourceId;
  const [resource, setResource] = React.useState<any | undefined>();

  const [page, setPage] = React.useState(0);
  const [sortModel, setSortModel] = React.useState<GridSortModel>(DEFAULT_SORT);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);

  const history = useHistory();
  let { url, path } = useRouteMatch();
  const { setSnack } = React.useContext(SnackContext);

  const location = useLocation();

  const { paginatedIndex } = useResourceContext();

  function handleNavParent() {

    var components = url.split('/');
    components.pop();

    const newPath = components.join('/');
    history.push(newPath);
  }

  function handleSortChange(params: any) {
    if (params.sortModel !== sortModel) {
      setSortModel((params.sortModel && params.sortModel.length > 0) ? params.sortModel : DEFAULT_SORT);
    }
  }

  function handleDidSaveResource(resource: any) {
    // let src = isHub ? ResourceService.hubPathForSchema(schema, updatedResource.id, parentResourceId) : ResourceService.carePathForSchema(schema, updatedResource.id, parentResourceId)
    let src = url + '/' + resource.id;
    setSnack({ message: `Saved ${schema.labels.name} ${resource.slug || ''}`, source: src });
  }

  async function loadIfNeeded(force = false) {
    if (!entries || force) {
      const sort = sortModel.map((col) => `${col.field} ${col.sort}`).join(',');
      const paginatedEntries = await paginatedIndex(schema, { query, filters, parentResourceId, page: page + 1, sort });
      setEntries(paginatedEntries);

    }
  }

  React.useEffect(() => {
    setSelectionModel([]);
  }, [entries]);

  React.useEffect(() => {
    setPage(0);
  }, [query, filters]);

  React.useEffect(() => {
    loadIfNeeded(true);
  }, [path, page, url, location, query, filters, sortModel]); // eslint-disable-line

  React.useEffect(() => {
    if (entries && schema.apiResponseResourceCollectionKey && selectionModel) {
      const items = entries[schema.apiResponseResourceCollectionKey];
      if (items) {
        const r = items.find((resource: any) => resource.id === selectionModel[0]);
        setResource(r);
      }
    }

  }, [selectionModel]); // eslint-disable-line

  if (!entries || !schema.columns || !schema.apiResponseResourceCollectionKey || !entries[schema.apiResponseResourceCollectionKey]) {
    return <LinearProgress />
  }

  const _columns = window.innerWidth >= theme.breakpoints.values.sm
    ? schema.columns
    : schema.columnsForMobile

  return (
    <SearchContext.Provider value={{ query, setQuery, filters, setFilters }}>
      <Box className={styles.box}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: '1em' }}
        >
          {!hideExtras && schema.parent && (
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={handleNavParent}
              style={{ marginRight: 10 }}
            >
              {schema.parent.labels.name}
            </Button>
          )}

          <Typography variant="h4" style={{ flex: 1 }}>
            {schema.labels.pluralName}
          </Typography>

          {!hideExtras && <ResourceActionMenu schema={schema} parentResourceId={parentResourceId} resource={resource} onDone={() => loadIfNeeded(true)} />}

        </Grid>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            {!Boolean(schema.hideSearchAction) && <SearchField />}
          </Grid>
          <Grid item>
            <FilterField schema={schema} />
          </Grid>
        </Grid>

        <hr />

        <DataGrid
          rows={entries[schema.apiResponseResourceCollectionKey]}
          columns={_columns}
          pagination
          paginationMode="server"
          onPageChange={(page) => setPage(page)}
          pageSize={entries.pagination.perPage}
          rowCount={entries.pagination.itemCount}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortChange}
          autoHeight
          disableColumnMenu
          disableColumnSelector
          disableColumnFilter
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </Box >

      <Route exact path={`${path}/:resourceId`}>
        <ResourceDetails schema={schema} onDidSave={handleDidSaveResource} showSaveAndClose />
      </Route>
    </SearchContext.Provider>
  );
};
export default ResourceIndex
