import { CardElement } from "@stripe/react-stripe-js";
import { Typography } from '@material-ui/core';

interface Props {
  onChange: (event: any) => Promise<void>,
  error?: string,
  hidePostalCode?: boolean
}

const StripeCardElement = ({ error = '', onChange, hidePostalCode = false }: Props) => {
  const cardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    },
    hidePostalCode
  }

  return (
    <>
      <CardElement
        id="card-element"
        options={cardElementOptions}
        onChange={onChange}
      />
      <Typography variant="caption">
        {error}
      </Typography>
    </>
  )
}

export default StripeCardElement
