import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({

  comment: {
    padding: '1.5em 1em',
    marginTop: theme.spacing(2),
    minHeight: '10vh',
    maxHeight: '15vh',
    overflow: 'auto',
    whiteSpace: 'pre-line'
  },

}));

interface Props {
  comments: Array<{id: string, comment: string, createdAt: Date}>,
  allowAdd: boolean,
  handleChange: any,
  lifo: boolean
}

const NotesView = ({comments, allowAdd, handleChange, lifo}: Props) => {
  const [showForm, setShowForm] = useState(false);

  const classes = useStyles();
  var notes = (comments || []).map(function(comment){
    return (<Paper elevation={3} className={classes.comment}>
      <Typography variant="subtitle2">Added: {comment.createdAt}</Typography>
      <Typography variant="body2">{comment.comment}</Typography>
    </Paper>);
  });

  if (lifo) {
    notes.reverse();
  }

  return (
    <>
      {allowAdd && showForm && (
        <TextField label="New Note" name="notesAttributes[].comment" onChange={handleChange} multiline={true} fullWidth />
      )}
      {allowAdd && !showForm && (
        <Button variant="contained" color="primary" onClick={()=> setShowForm(true)}>Add Note</Button>
      )}
      { notes }
    </>
  )
};

export default NotesView;
