import React from 'react';

import { useSearchContext } from '../context/SearchProvider';
import { ResourceSchema, FilterOption } from '../services/ResourceService';
import Chip from '@material-ui/core/Chip';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
// import { KeyboardDatePicker } from '@material-ui/pickers';
import { Popover, Button, Box } from '@material-ui/core';

interface Props {
  schema: ResourceSchema;
}

interface MegaChipProps {
  filterOption: FilterOption;
  filterOptions: FilterOption[];
  setFilterOptions: any;
}

const MegaChip = ({ filterOption, filterOptions, setFilterOptions }: MegaChipProps) => {

  const { filters, setFilters } = useSearchContext(); //active filters
  const [open, setOpen] = React.useState(false); //popover open state
  const chipRef = React.useRef(null); //get a reference to the current chip, needed for Popover anchor

  const filterIsActive = (filterOption: FilterOption) => {
    return filters?.find((activeFilter: FilterOption) => activeFilter.description === filterOption.description) !== undefined;
  }

  const handleDateChange = (date: any) => {
    if (!filters || !filterOptions) { return; }


    const newFilterOption = { ...filterOption, value: date };

    saveFilterOption(newFilterOption);
    setOpen(false);

  }

  const saveFilterOption = (newFilterOption: FilterOption) => {
    if (!filterOptions) { return; }
    const isFilterActive = filterIsActive(newFilterOption);

    var newFilterOptions = [...filterOptions];

    // Find the index of this filter option
    const index = newFilterOptions.findIndex((activeFilter: FilterOption) => {
      return activeFilter.description === newFilterOption.description
    });

    // If this filter option exists (it should because it comes from the schema)
    if (index > -1) {
      // Update the filter option to get the new date
      newFilterOptions[index] = newFilterOption;
      setFilterOptions(newFilterOptions);

      // Now that the options are up to date, finally update the filter sent with the API query
      if (isFilterActive) {
        updateFilter(newFilterOption);
      } else {
        addFilter(newFilterOption);
      }
    }
  };



  const toggle = (filterOption: FilterOption) => {
    if (filterIsActive(filterOption)) {
      removeFilter(filterOption);
    } else {
      addFilter(filterOption);
    }
  }

  const removeFilter = (filterOption: FilterOption) => {
    if (filters) {
      var newFilters = [...filters];
      const indexToRemove = filters.findIndex((activeFilter: FilterOption) => activeFilter.description === filterOption.description)
      if (indexToRemove > -1) {
        newFilters.splice(indexToRemove, 1);
        setFilters(newFilters);
      }
    }
  }

  const addFilter = (filterOption: FilterOption) => {
    if (filters) {
      var newFilters = [...filters];
      newFilters.push(filterOption);
      setFilters(newFilters);
    }
  }

  const updateFilter = (filterOption: FilterOption) => {
    if (filters) {
      var newFilters = [...filters];
      const activeFilterIndex = newFilters.findIndex((activeFilter: FilterOption) => activeFilter.description === filterOption.description)
      if (activeFilterIndex > -1) {
        newFilters[activeFilterIndex] = filterOption;
        setFilters(newFilters);
      }
    }
  }


  function handleClick() {
    if (filterOption.valueType === 'date') {
      setOpen(true);
    } else {
      toggle(filterOption);
    }
  }

  function handleToggle() {
    setOpen(false);
    toggle(filterOption);
  }

  // function handleDelete() {

  //   if (filterOption.valueType === 'date') {
  //     setOpen(!open);
  //   } else {
  //     handleClick();
  //   }
  // }

  function variant() {
    return filterIsActive(filterOption) ? "default" : "outlined"
  }


  return (
    <>
      <Chip
        label={filterOption.descriptor ? filterOption.descriptor(filterOption) : filterOption.description}
        clickable
        color="primary"
        onClick={handleClick}
        onDelete={filterOption.valueType === 'date' ? handleClick : undefined}
        deleteIcon={<ArrowDropDownIcon />}
        variant={variant()}
        ref={chipRef}
      />

      {open && (
        <Popover open={open} anchorEl={chipRef.current} onClose={() => setOpen(false)}>
          <DatePicker
            variant="static"
            value={filterOption.value}
            onChange={handleDateChange}
          />
          {filterOption.value && (
            <Box textAlign='center'>
              {<Button onClick={handleToggle}>{filterIsActive(filterOption) ? 'Remove' : 'Apply'} Filter</Button>}
            </Box>
          )}
        </Popover>
      )}
    </>
  )
}


const FilterField = ({ schema }: Props) => {
  const [filterOptions, setFilterOptions] = React.useState(schema.filterOptions);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <span style={{ flex: 1 }}>

        {filterOptions?.map((filterOption: FilterOption) => <MegaChip key={`mega-chip-${filterOption.name}`} filterOption={filterOption} filterOptions={filterOptions} setFilterOptions={setFilterOptions} />)}
      </span>
    </MuiPickersUtilsProvider>

  );

}

export default FilterField;
