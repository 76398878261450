import { useState } from 'react';
import { getAuth0Config } from '../services/Auth0Config';

import {
  TextField, RadioGroup, FormControlLabel, Switch, Select, MenuItem,
  InputLabel, FormControl, Grid, Radio, FormLabel, Button
} from '@material-ui/core';
import { ResourceOptionProps } from './ResourceOptions';
import { useUserContext } from '../context/UserContext';
import NotesView from './NotesView';
import TestingInSelect from './UserFields/TestingInSelect';
import PhoneNumber from './UserFields/PhoneNumber';
import Dob from './UserFields/Dob';
import { ProfileSchema } from '../models/User';
import { CreateUpdateMethods } from '../services/ResourceService';
import { useMutableResourceContext } from '../context/MutableResourceProvider';

const UserOptions = ({ handleChange, handleCheckChange }: ResourceOptionProps) => {
  const { schema, resource, setResource, } = useMutableResourceContext();
  const { hasPermission } = useUserContext();
  const isProfile = schema === ProfileSchema;
  const userIsNew = !Boolean(resource.id);

  const [isPasswordResetSuccessful, setIsPasswordResetSuccessful] = useState(false);

  const resetPassword = async () => {
    const auth0Config = getAuth0Config();

    const url = `https://${auth0Config.domain}/dbconnections/change_password`
    const params = {
      "client_id": auth0Config.clientId,
      "email": resource?.emailAddress,
      "connection": "Username-Password-Authentication",
    }

    const body = JSON.stringify(params);
    const headers = { "Content-Type": "application/json" }
    const method = CreateUpdateMethods.POST;

    const options = {
      method,
      body,
      headers
    } as any
    const result = await fetch(url, options);
    if (result.status === 200) {
      setIsPasswordResetSuccessful(true);
    }
  }

  // the api shape of a profile has address at billingAddress but the
  // POST form needs it at billingAddressAttributes. Since we update the resource
  // on every change, and we were updating a different field than what is used to
  // show it looked the user couldn't edit the field.
  // useEffect(() => {
  //   const apiAddress = resource.billingAddress || {};
  //   const formAddress = resource.billingAddressAttributes || {};
  //   setBillingAddress({ ...apiAddress, ...formAddress });
  //   resource.phoneNumberAttributes = { number: resource.phoneNumber };

  // }, [resource]);

  const handleAddressChange = (event: any) => { //TODO: maybe put address in ResourceOptions
    var r = { ...resource };
    var ba = { ...r.billingAddress };

    ba[event.target.name] = event.target.value;
    r.billingAddress = ba;
    setResource(r);
  }

  const handleDateChange = (d: string) => {
    var r = { ...resource };
    r.dob = d
    setResource(r);
  }


  return (
    <Grid container spacing={3}>
      <Grid container item spacing={1} xs={12} md={8} alignContent="flex-start">
        <Grid container item spacing={1} xs={12}>
          <Grid item xs={4}>
            <TextField label="First Name" name="firstName" value={resource.firstName} onChange={handleChange} fullWidth />
          </Grid>
          <Grid item xs={1}>
            <TextField label="MI" name="middleInitial" value={resource.middleInitial} onChange={handleChange} />
          </Grid>
          <Grid item xs={4}>
            <TextField label="Last Name" name="lastName" value={resource.lastName} onChange={handleChange} fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} item xs={12}>
          <Grid item xs={8}>
            <TextField label="Email" disabled={!userIsNew} name="emailAddress" value={resource.emailAddress} onChange={handleChange} fullWidth />
          </Grid>
        </Grid>


        <Grid container item spacing={1} xs={12}>
          <Grid item xs={3}>
            <Dob date={resource.dob} setDate={handleDateChange} />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="testing-in-label">Testing In</InputLabel>
              <TestingInSelect id="testing_in"
                labelId="testing-in-label"
                handleChange={handleChange}
                currentValue={resource.testingIn} />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <FormLabel component="legend">Gender at Birth</FormLabel>
              <RadioGroup aria-label="gender" name="gender" value={resource.gender} onChange={handleChange} row>
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="male" control={<Radio />} label="Male" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid item xs={4}>
            <PhoneNumber handleChange={handleChange}
              currentValue={resource.phoneNumber}
              fieldName="phoneNumber" />
          </Grid>
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid item xs={5}>
            <TextField label="Address 1" name="addressOne" value={resource.billingAddress?.addressOne} onChange={handleAddressChange} fullWidth />
          </Grid>
          <Grid item xs={5}>
            <TextField label="Address 2" name="addressTwo" value={resource.billingAddress?.addressTwo} onChange={handleAddressChange} fullWidth />
          </Grid>
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid item xs={4}>
            <TextField label="City" name="city" value={resource.billingAddress?.city} onChange={handleAddressChange} fullWidth />
          </Grid>
          <Grid item xs={1}>
            <TextField label="State" name="state" value={resource.billingAddress?.state} onChange={handleAddressChange} fullWidth />
          </Grid>
          <Grid item xs={2}>
            <TextField label="Zip" name="zip" value={resource.billingAddress?.zip} onChange={handleAddressChange} fullWidth />
          </Grid>
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid item xs={6}>
            <FormControlLabel
              labelPlacement="top"
              control={<Switch color="primary" name="sendBirthdayCard" checked={resource.sendBirthdayCard || false} onChange={handleCheckChange} />}
              label="Send Birthday Card"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              labelPlacement="top"
              control={<Switch color="primary" name="twelveMonthReminder" checked={resource.twelveMonthReminder || false} onChange={handleCheckChange} />}
              label="Twelve Month Reminder"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              labelPlacement="top"
              control={<Switch color="primary" name="futureUpdates" checked={resource.futureUpdates || false} onChange={handleCheckChange} />}
              label="Future Updates"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              labelPlacement="top"
              control={<Switch color="primary" name="smsUpdates" checked={resource.smsUpdates || false} onChange={handleCheckChange} />}
              label="SMS Updates"
            />
          </Grid>
        </Grid>

        {hasPermission('role:users') && !isProfile && (
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="auth0-roles-label">Roles</InputLabel>
                <Select
                  labelId="auth0-roles-label"
                  id="auth0_roles"
                  name="auth0Roles"
                  value={resource.auth0Roles || []}
                  onChange={handleChange}
                  multiple={true}
                >
                  <MenuItem value={'customer'}>Customer</MenuItem>
                  <MenuItem value={'csr'}>CSR</MenuItem>
                  <MenuItem value={'admin'}>Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {resource.id && (
              <Grid item xs={4}>
                <FormControlLabel
                  labelPlacement="top"
                  control={<Switch color="primary" name="active" checked={resource.active} onChange={handleCheckChange} />}
                  label="Active"
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {isProfile && (
        <Grid container item spacing={0} xs={4}>
          <Grid item xs={12}>
            {isPasswordResetSuccessful && (<div>Check your email for a link to set your new password</div>)}
            {!isPasswordResetSuccessful && (<Button variant="contained" color="primary" onClick={() => resetPassword()}>Change Password</Button>)}
          </Grid>
        </Grid>
      )}
      {!isProfile && (
        <Grid container item spacing={0} xs={4}>
          <Grid item xs={12}>
            <NotesView comments={resource.notes} allowAdd={true} handleChange={handleChange} lifo={true} />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
};
export default UserOptions
