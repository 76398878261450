import React from 'react';
import { Avatar, Button, Menu, MenuItem, IconButton } from '@material-ui/core';
import { useUserContext } from '../context/UserContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ResourceDetailsDialog } from './ResourceDetails';
import { ProfileSchema } from '../models/User';

const AuthenticationButtons = (props: any) => {
  const { appState, showLogin = true } = props
  const { reloadUser } = useUserContext()

  const {
    isAuthenticated,
    isLoading,
    handleLogin,
    handleLogout,
    user
  } = useUserContext();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [doEdit, setDoEdit] = React.useState(false);

  if (isLoading) {
    return <CircularProgress />
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doProfile = () => {
    setDoEdit(true);
    handleClose();
  }

  const doLogout = () => {
    handleLogout()
    handleClose();
  }

  const handleEditDone = () => {
    setDoEdit(false);
    reloadUser();
  }

  if (isAuthenticated) {
    return <div>
      <IconButton
        aria-label="account"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar>{user ? `${user?.firstName[0]}${user?.lastName[0]}` : 'U'}</Avatar>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={doLogout}>Logout</MenuItem>
        <MenuItem onClick={doProfile}>Profile</MenuItem>
      </Menu>
      {doEdit && (
        <ResourceDetailsDialog schema={ProfileSchema} resourceId={'/'} onDidClose={handleEditDone} onDidSaveResource={() => reloadUser()} />
      )}
    </div>;
  } else if (showLogin) {
    return <Button onClick={() => handleLogin(appState)}>Log In</Button>;
  } else {
    return null;
  }
};

export default AuthenticationButtons;
