

import { Card, CardHeader, CardContent, makeStyles, Theme } from '@material-ui/core';


export type Props = {
  title: string;
  children?: any;
  customHeaderStyle?: { background: string, color: string };
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: { background: '#666', color: '#fff' },
    headerHighlighted: { background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }
  }
});


const ShopCard = (props: Props) => {

  const { title, children, customHeaderStyle } = props;

  const classes = useStyles();

  return (
    <Card key={`focus-card`} >
      <CardHeader title={title} className={classes.header}
        style={customHeaderStyle}
      />
      <CardContent className="card-content">
        {children || null}
      </CardContent>
    </Card >
  )
}

export default ShopCard
