
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  useTheme
} from '@material-ui/core';
import { useMutableResourceContext } from '../context/MutableResourceProvider';


const OrderNextStepCard = () => {
  const theme = useTheme();

  const { resource } = useMutableResourceContext();
  const order = resource;

  const labs = order.lineItems.map((item: any) => item.variant.lab.name)
  const uniqueLabs = labs.filter((item: any, pos: number) => labs.indexOf(item) == pos)
  const labList = uniqueLabs.join(',');

  return (
    <Card style={{ flexBasis: '100%', margin: '30px 0' }}>
      <CardHeader title='Thank You For Your Order' style={{ background: "#a90b0b", color: '#FFF' }} />
      <CardContent className="card-content" style={{ background: theme.palette.secondary.main }}>
        <p style={{ fontWeight: 'bold' }}>Your purchase number {order.id} has been received and a receipt has been sent to your email address.</p>
        <p>You will receive a requisition number with any test preperation instructions in just a few minutes.</p>
        <p>The testing you selected is to be performed by:</p>
        <p style={{ fontSize: '1rem', fontWeight: 'bold', margin: 0 }}>{labList}</p>
        <p>Please find a convenient location and see available appointments by clicking the button below.</p>
        <Button href='https://testing.com/lab-locator' style={{ height: '60px', padding: '2em', marginBottom: '30px', marginRight: '11px', fontWeight: 'bold' }} color='primary' variant='contained'>FIND A LAB</Button>
        <Button href='http://testing.com/how-it-works' style={{ height: '60px', padding: '2em', marginBottom: '30px', fontWeight: 'bold' }} color='primary' variant='outlined'>HOW IT WORKS</Button>
        <p>If you have any questions or concerns, please call us Mon-Fri, 8am - 7pm EST at <a style={{ color: '#a90b0b' }} href='tel:8889139846'>(888) 913-9846</a>.</p>
        <p>Or email us at <a href='mailto:info@testing.com' style={{ color: '#a90b0b' }}>info@testing.com</a></p>
      </CardContent>
    </Card>
  )
}

export default OrderNextStepCard;
