import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { add, format, parse, parseISO, formatISO } from 'date-fns';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

export const DEFAULT_DATE_FORMAT = "MM/dd/yyyy"

interface Props {
  date?: string;
  setDate: any;
}

const Dob = ({ date, setDate }: Props) => {


  const mustBeBefore = add(new Date(), { years: -18 })
  const handleDateChange = (_: any, value: string | null | undefined) => {
    if (value) {
      try {
        setDate(formatISO(parse(value, DEFAULT_DATE_FORMAT, new Date())))
      } catch (e) {
        console.error("Parsing of date failed", value);
      }
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl required fullWidth>
        {/* <FormLabel component="legend">DOB</FormLabel> */}
        {/* <InputLabel id="legend">DOB</InputLabel> */}

        <KeyboardDatePicker
          required
          label="DOB"
          name='dob'
          value={date ? parseISO(date) : null}

          // value={date || null}
          format={DEFAULT_DATE_FORMAT}
          emptyLabel=""
          onChange={handleDateChange}
          autoOk
          maxDate={mustBeBefore}
        />

        <FormHelperText>DOB must be on or before {format(mustBeBefore, DEFAULT_DATE_FORMAT)}</FormHelperText>
      </FormControl>
    </MuiPickersUtilsProvider>

  )
}
export default Dob;
