import { useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import PaymentProps from '../PaymentProps';
import { PayPalButton } from "react-paypal-button-v2";
import { OrderSchema } from '../../../models/Order';
import { useResourceContext } from '../../../context/ResourceProvider';
import { parentedSchema } from '../../../services/ResourceService';
import PaymentTransaction, { PaymentTransactionSchema } from '../../../models/PaymentTransaction';


export default function PaypalPayment(props: PaymentProps) {
  const [processing, setProcessing] = useState(false);
  const { createOrUpdate } = useResourceContext();
  const { order, onSuccess, onError, error, onCancel = () => { } } = props;

  const handleError = (err: any) => {
    onError(err.toString());
    setProcessing(false);
  }

  const handleCancel = (_: any) => {
    onCancel();
  }

  const handleSuccess = async (details: any, data: any) => {
    setProcessing(true);

    const pt = { vendor: 'PayPal', confirmationNumber: data.orderID, rawResponse: details } as PaymentTransaction;
    const tx = await createOrUpdate(pt, parentedSchema(PaymentTransactionSchema, OrderSchema), { parentResourceId: order.id });

    setProcessing(false);
    onSuccess();

    return tx;
  };

  return (
    <>
      {processing && <CircularProgress />}
      {error && (
        <Typography>{error}</Typography>
      )}
      {!processing &&
        <PayPalButton
          amount={order.totalAmount}
          shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
          onError={handleError}
          onSuccess={handleSuccess}
          style={{ color: 'white', height: 55 }}
          onCancel={handleCancel}
          options={{
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
            disableFunding: "card,paylater",
          }}
        />
      }
    </>
  );

}
