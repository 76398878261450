import { useState, useEffect } from 'react';
import AddressForm, { TypeOfAddress } from './AddressForm';
import Address from '../models/Address';
import { Checkbox, FormHelperText } from '@material-ui/core';
import LineItem from '../models/LineItem';
import { Grid, Typography } from '@material-ui/core';
import { ResourceComparitor } from '../services/ResourceService';
import { CheckOutlined } from '@material-ui/icons';
import { useMutableResourceContext } from '../context/MutableResourceProvider';
import EditCard, { EditProps } from './EditCard';
import { UserRole, useUserContext } from '../context/UserContext';
import User from '../models/User';

const OrderAddresses = (props: EditProps) => {
  const { editing, setEditing } = props;
  const { resource, ogResource, setResource, validationErrors } = useMutableResourceContext();

  const order = resource;
  const ogOrder = ogResource;
  const setOrder = setResource;

  const [shippingMatchesBilling, setShippingMatchesBilling] = useState<boolean | null>(ResourceComparitor(order.billingAddress, order.shippingAddress))


  const hasSavedPayment = () => Boolean(ogOrder.paymentTransaction);
  const { userRole } = useUserContext();

  const hasShippable = () => {
    return order.lineItems && order.lineItems.filter((lineItem: LineItem) => lineItem.variant?.shippable).length > 0;
  }

  const canShowShippingAddress = hasShippable();
  const canEditBillingAddress = !hasSavedPayment();
  const canEditShippingAddress = !hasSavedPayment() || userRole >= UserRole.CSR;

  const hasSavedAddress = () => Boolean(ogOrder.billingAddress) && Boolean(ogOrder.shippingAddress);

  const handleBillingAddressChange = (newAddress: Address) => {
    var o = { ...order };
    o.billingAddress = newAddress;
    if (Boolean(shippingMatchesBilling)) {
      o.shippingAddress = newAddress;
    }
    setOrder(o);
  }

  const handleShippingAddressChange = (newAddress: Address) => {
    var o = { ...order };
    o.shippingAddress = newAddress;
    setOrder(o);
  }

  const cloneBillingToShipping = () => {
    setOrder({ ...order, shippingAddress: { ...order.billingAddress } });
  }

  //TODO: Maybe add this and haave this component load from both spots
  useEffect(() => {

    if (!order.billingAddress && order.user && order.user?.billingAddress) {
      // If we arrive here with no billing address, populate that and get ready to save
      var updatedOrder = { ...order };
      var addr = updatedOrder.user?.billingAddress
      if (addr) {
        updatedOrder.billingAddress = { ...addr };
        updatedOrder.shippingAddress = { ...addr }
      }
      setOrder(updatedOrder);
      // Also make sure user can change it if they want
      setEditing(true);
    }
  }, [order]) // eslint-disable-line

  useEffect(() => {
    // Update the shipping matches billing whenever the order saves/reloads
    // Not necessarily whenever the user updates the order because that would toggle the flag and hide shipping
    // if they typed out the same addresses
    setShippingMatchesBilling(ResourceComparitor(order.billingAddress, order.shippingAddress));
  }, [ogOrder]); // eslint-disable-line

  interface AddressViewProps {
    address: Address;
    user: User;
  }

  const AddressView = ({ address, user }: AddressViewProps) => (
    <>
      <Typography >
        {user.firstName} {user.lastName}
      </Typography>
      <Typography  >
        {address?.addressOne} {address.addressTwo}
      </Typography>
      <Typography key="address-city-state-zip" >
        {address?.city}&nbsp;{address?.state},&nbsp;{address?.zip}
      </Typography>
    </>
  )

  const OrderAddressesView = () => {
    return (
      <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="flex-start">
        {order.billingAddress && (
          <Grid item xs={6} container direction="column">
            {canShowShippingAddress && <Typography variant="h6">Billing</Typography>}

            <AddressView address={order.billingAddress} user={order.user} />
          </Grid>
        )}
        {canShowShippingAddress && (
          <>
            {shippingMatchesBilling && (
              <Grid item xs={5}>
                <Typography variant="h6">Shipping</Typography>

                <Grid item xs={12} container direction="row" alignItems="center">
                  <CheckOutlined />
                  <Typography variant="body2">Same as billing</Typography>
                </Grid>
              </Grid>
            )}

            {!shippingMatchesBilling && order.shippingAddress && (
              <Grid item xs={5} container direction="column">
                <Typography variant="h6">Shipping</Typography>
                <AddressView address={order.shippingAddress} user={order.user} />
              </Grid>
            )}
          </>
        )}
      </Grid>
    )
  }

  const toggleShippingBilling = () => {
    const shouldMatch = !shippingMatchesBilling;
    if (shouldMatch) {
      cloneBillingToShipping();
    }
    setShippingMatchesBilling(shouldMatch)

  }

  return (
    <Grid item xs={12} style={{ textTransform: 'capitalize' }}>
      <EditCard title="Billing" saveCTA="CONFIRM ADDRESS" editing={props.editing} setEditing={setEditing} showEditButton={canEditBillingAddress || canEditShippingAddress} showCancelButton={hasSavedAddress()}>
        {!editing ? <OrderAddressesView /> : (
          <>
            <AddressForm
              user={order.user}
              address={order.billingAddress}
              type={TypeOfAddress.BILLING}
              onChange={handleBillingAddressChange}
              disabled={!canEditBillingAddress}
              validationErrors={validationErrors}
            />

            {Boolean(validationErrors?.errors?.billing_address) && (
              <FormHelperText error>Billing Address {validationErrors?.errors?.billing_address}</FormHelperText>
            )}

            {canShowShippingAddress && (
              <Grid item xs={12}>
                {shippingMatchesBilling !== null && (
                  <>
                    <Typography variant="caption" display="block" gutterBottom>
                      <Checkbox
                        checked={shippingMatchesBilling}
                        onChange={toggleShippingBilling}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      Shipping address same as billing
                    </Typography>
                    {!shippingMatchesBilling && (
                      <>
                        <AddressForm
                          user={order.user}
                          address={order.shippingAddress}
                          type={TypeOfAddress.SHIPPING}
                          onChange={handleShippingAddressChange}
                          disabled={!canEditShippingAddress}
                          validationErrors={validationErrors}
                        />

                        {Boolean(validationErrors?.errors?.shipping_address) && (
                          <FormHelperText error>Shipping Address {validationErrors?.errors?.shipping_address}</FormHelperText>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
          </>
        )}
      </EditCard>
    </Grid >
  )
}

export default OrderAddresses;
