import { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  Radio,
  Box,
  CircularProgress
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TestingInSelect from './UserFields/TestingInSelect';
import PhoneNumber from './UserFields/PhoneNumber';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { add, format } from 'date-fns';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Alert from '@material-ui/lab/Alert';
import { parseISO, formatISO, parse } from 'date-fns';

function getSteps() {
  return ["Basics", 'More Info', 'Settings'];
}

function stepIsValid(user: any, step: number) {
  switch (step) {
    case 0:
      const mustBeBefore = add(new Date(), { years: -18 })
      const userDate = new Date(user.dob);
      const dobIsValid = Boolean(userDate && userDate < mustBeBefore);
      const firstNameIsValid = Boolean(user.firstName?.length > 0);
      const lastNameIsValid = Boolean(user.lastName?.length > 0);
      const genderIsValid = Boolean(user.gender);

      return genderIsValid && lastNameIsValid && firstNameIsValid && dobIsValid;
    case 1:
      return Boolean(user.testingIn?.length > 0) &&
        Boolean(user.phoneNumber?.length >= 10) &&
        Boolean(user.billingAddress?.addressOne?.length > 0) &&
        Boolean(user.billingAddress?.city?.length > 0) &&
        Boolean(user.billingAddress?.state?.length >= 2) &&
        Boolean(user.billingAddress?.zip?.length >= 5);
    default:
      return true;
  }
}

export const DEFAULT_DATE_FORMAT = "MM/dd/yyyy"

// function getStepContent(step: number) {
//   switch (step) {
//     case 0:
//       return 'Select campaign settings...';
//     case 1:
//       return 'What is an ad group anyways?';
//     case 2:
//       return 'This is the bit I really care about!';
//     default:
//       return 'Unknown step';
//   }
// }

const Step1 = (props: any) => {
  const { user, handleChange } = props


  const mustBeBefore = add(new Date(), { years: -18 })

  const handleDateChange = (_: any, value: string | null | undefined) => {
    if (value) {
      try {
        const newVal = formatISO(parse(value, DEFAULT_DATE_FORMAT, new Date()))
        handleChange({ target: { name: 'dob', value: newVal } })

      } catch (e) {
        console.error("Parsing of date failed", value);
      }
    }
  }


  // const handleDateChange = (_: any, value: string | null | undefined) => {
  //   if (value) { handleChange({ target: { name: 'dob', value: value } }); }
  // }

  // const handleDateChange = (date: any, dateKey: string) => {

  // }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item xs={12} md={5}>
        <TextField
          required
          label="First Name"
          name="firstName"
          value={user.firstName || ""}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          label="MI"
          name="middleInitial"
          value={user.middleInitial || ""}
          onChange={handleChange}
          inputProps={{ maxLength: 1 }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField
          required
          label="Last Name"
          name="lastName"
          value={user.lastName || ""}
          onChange={handleChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl required fullWidth style={{ marginBottom: '5px' }}>
          <FormLabel component="legend">Date of Birth</FormLabel>

          <KeyboardDatePicker
            required
            name='dob'
            value={user.dob ? parseISO(user.dob) : null}
            label="MM/DD/YYYY"
            // value={date || null}
            format={DEFAULT_DATE_FORMAT}
            emptyLabel=""
            onChange={handleDateChange}
            autoOk
            maxDate={mustBeBefore}
          />

          {/* <DatePicker
            required
            name='dob'
            value={user.dob || null}
            format="yyyy-MM-dd"
            emptyLabel=""
            onChange={(date: any) => handleDateChange(date, 'dob')}
            autoOk
            maxDate={mustBeBefore}
          /> */}

          <FormHelperText>DOB must be on or before {format(mustBeBefore, DEFAULT_DATE_FORMAT)}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth required>
          <FormLabel component="legend">Gender at Birth</FormLabel>
          <RadioGroup aria-label="gender" name="gender" value={user.gender || ""} onChange={handleChange} row>
            <FormControlLabel value="female" control={<Radio required />} label="Female" />
            <FormControlLabel value="male" control={<Radio required />} label="Male" />
          </RadioGroup>
        </FormControl>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

const Step2 = (props: any) => {
  const { user, handleChange } = props;

  return (
    <>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel id="testing-in-label">Testing In</InputLabel>
          <TestingInSelect id="testing_in"
            labelId="testing-in-label"
            handleChange={handleChange}
            currentValue={user.testingIn} />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <PhoneNumber handleChange={handleChange}
          currentValue={user.phoneNumber}
          fieldName="phoneNumber" />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          label="Address Line 1"
          name="addressOne"
          value={user.billingAddress?.addressOne || ""}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Address Line 2"
          name="addressTwo"
          value={user.billingAddress?.addressTwo || ""}
          onChange={handleChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          required
          label="City"
          name="city"
          value={user.billingAddress?.city || ""}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <InputMask
          mask="aa"
          maskPlaceholder={null}
          beforeMaskedStateChange={({ nextState }) => {
            let { value } = nextState;
            value = value.toUpperCase();
            return { ...nextState, value }
          }
          }
          value={user.billingAddress?.state || ""}
          onChange={handleChange}
        >
          <TextField required name="state" label="State" fullWidth />
        </InputMask>
      </Grid>
      <Grid item xs={5}>
        <InputMask
          mask="99999-9999"
          maskPlaceholder={null}
          beforeMaskedStateChange={({ nextState }) => {
            let { value } = nextState;
            if (value.endsWith("-")) {
              value = value.slice(0, -1);
            }
            return { ...nextState, value }
          }
          }
          value={user.billingAddress?.zip || ""}
          onChange={handleChange}
        >
          <TextField required name="zip" label="Zip" fullWidth />
        </InputMask>
      </Grid>
    </>
  )
}

const Step3 = (props: any) => {
  const { user, handleCheckChange } = props;

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          labelPlacement="start"
          control={<Switch color="primary" name="sendBirthdayCard" checked={user.sendBirthdayCard || false} onChange={handleCheckChange} />}
          label="Send Birthday Card"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          labelPlacement="start"
          control={<Switch color="primary" name="twelveMonthReminder" checked={user.twelveMonthReminder || false} onChange={handleCheckChange} />}
          label="Twelve Month Reminder"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          labelPlacement="start"
          control={<Switch color="primary" name="futureUpdates" checked={user.futureUpdates || false} onChange={handleCheckChange} />}
          label="Future Updates"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          labelPlacement="start"
          control={<Switch color="primary" name="smsUpdates" checked={user.smsUpdates || false} onChange={handleCheckChange} />}
          label="SMS Updates"
        />
      </Grid>

    </>
  )
}


const UserForm = (props: any) => {
  const { user, handleSave, handleCancel, validationErrors, loading } = props
  const heading = user && user.id ? 'Edit user' : 'User Enrollment'

  const [activeStep, setActiveStep] = useState(0);
  const [isMissingData, setIsMissingData] = useState(true);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  useEffect(() => {
    const missingData = !stepIsValid(user, activeStep);
    setIsMissingData(missingData);
  }, [user, activeStep]);

  function formatValidationErrors(errors: any) {
    return Object.entries(errors.errors).map((entry: any) => `${entry[0]} ${entry[1]}`).join(', ');
  }

  return (
    <Box p={4}>
      <Grid container spacing={3}>
        <Grid item container justifyContent="center">
          <Typography variant="h4">
            {heading}
          </Typography>
        </Grid>

        <Grid item xs={12}>

          <Stepper activeStep={activeStep}>
            {steps.map((label: string) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>

        {activeStep === 0 && <Step1 {...props} />}
        {activeStep === 1 && <Step2 {...props} />}
        {activeStep === 2 && <Step3 {...props} />}

        {validationErrors && <Alert severity="warning">{formatValidationErrors(validationErrors)}</Alert>}

        {loading && (
          <Grid container item xs={12} justifyContent="center">
            <Grid item><CircularProgress /></Grid>
          </Grid>
        )}

        {!loading && (
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Button onClick={handleCancel}>Cancel</Button>
                {activeStep > 0 ? <Button variant="contained" onClick={handleBack}>BACK</Button> : <div></div>}
              </Grid>
              <Grid item>
                {activeStep === steps.length - 1 ? (
                  <Button onClick={handleSave} variant="contained" color="primary" >Save</Button>
                ) : (
                  <Button variant="contained" color="primary" disabled={isMissingData} onClick={handleNext}>NEXT</Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid >
    </Box>
  )
}

export default UserForm
