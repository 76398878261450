import { useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { ResourceOptionProps } from './ResourceOptions';
import MoneyField from './MoneyField';
// import PushLink from './PushLink';
import Order from '../models/Order';
import { CircularProgress } from '@material-ui/core';
import formatCurrency from '../utils';
import NotesView from './NotesView';
import { useMutableResourceContext } from '../context/MutableResourceProvider';

const RefundOptions = ({ handleChange }: ResourceOptionProps) => {
  const { resource, resourceLoading, validationErrors, parentResource, setResource } = useMutableResourceContext();

  function setResourceOrder(order?: Order) {
    var r = { ...resource };
    r.order = order
    r.orderId = order?.id;
    setResource(r);
  }

  useEffect(() => {
    if (parentResource) {
      setResourceOrder(parentResource);
    }
  }, [parentResource]); // eslint-disable-line

  if (resourceLoading || !resource.order) { return <CircularProgress /> }
  return (
    <Grid container spacing={3}>
      <Grid item container direction="column" xs={8}>
        <MoneyField label="Amount" name="amount" value={resource.amount} onChange={handleChange} error={validationErrors?.amount} disabled={Boolean(resource.id)} />
        {resource.id && <Typography variant="body1">Confirmation Number: {resource.confirmationNumber}</Typography>}
        {!resource.id && (
          <Alert severity="info">A flat amount will be applied to this order, not to exceed the order amount ({formatCurrency(resource.order.totalAmount)}) minus existing refunds. </Alert>
        )}
      </Grid>

      <Grid item spacing={0} xs={4}>
        <NotesView comments={resource.notes} allowAdd={true} handleChange={handleChange} lifo={true} />
      </Grid>
    </Grid>
  )
};

export default RefundOptions;
