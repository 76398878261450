import { ResourceSchema } from '../services/ResourceService';
import ColumnFactory from '../services/ColumnFactory';
import PwnOrderOptions from '../components/PwnOrderOptions';
import { getNestedValue } from '../utils';
import Lab from './Lab';

type PwnOrder = {
  id: string;
  aasmState: string;
  externalOrderId: string;
  lab: Lab;
  requisitionForm: any;
  testResult: any;
};

const PwnOrderSchema = {
  apiResponseResourceCollectionKey: 'pwnOrders',
  apiResponseResourceItemKey: 'pwnOrder',
  apiRequestPath: '/pwn_orders',
  labels: { name: 'Pwn Order', pluralName: 'Pwn Orders', describer: (resource) => resource.id },
  columns: [
    ColumnFactory.id,
    { field: 'externalOrderId', headerName: 'Req Id', flex: 1 },
    { field: 'testResult.updatedAt', headerName: 'Latest Test Results', valueGetter: getNestedValue, flex: 1 },
    ColumnFactory.actions()
  ],
  columnsForMobile: [],
  optionHandler: PwnOrderOptions
} as ResourceSchema;

export { PwnOrderSchema };

export default PwnOrder;
