import ProductVariant from '../models/ProductVariant'

const splitByLab = (args: ProductVariant[]) => {
  if (!args) return new Map()

  const itemsByLab = new Map()

  args.forEach((item) => {
    const { lab } = item

    if (lab && lab.id) {
      let value = []
      if (itemsByLab.has(lab.id)) {
        value = itemsByLab.get(lab.id)
        value.push(item)
      } else {
        value.push(item)
      }

      itemsByLab.set(lab.id, value)
    }
  })

  return itemsByLab
}

export { splitByLab }
export default splitByLab
