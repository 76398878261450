import { createContext, useContext } from 'react'
import User, { CreateUser } from '../models/User'

export type LogoutArgs = {
  returnTo: string
}

enum UserRole {
  UNKNOWN = 0,
  GUEST,
  CUSTOMER,
  CSR,
  ADMIN
}

export { UserRole };

type Context = {
  isAuthenticated: boolean
  isLoading: boolean
  user?: User | null
  reloadUser: () => void
  handleLogin: (appState: any) => void
  handleLogout: (args?: LogoutArgs) => void
  permissions: string[]
  hasPermission: (permission: string) => boolean
  hasRole: (user: User | CreateUser | undefined | null, role: string) => boolean | undefined
  userRole: UserRole
}

const UserContext = createContext<Context>({
  isAuthenticated: false,
  isLoading: true,
  user: null,
  reloadUser: () => { },
  handleLogin: () => { },
  handleLogout: () => { },
  permissions: [],
  hasPermission: () => false,
  hasRole: () => false,
  userRole: UserRole.UNKNOWN,
})

const useUserContext = () => useContext(UserContext);


export { useUserContext }
export default UserContext
