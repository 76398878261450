
import { FormControlLabel, FormGroup, Grid, Switch, TextField } from '@material-ui/core';
import { ResourceOptionProps } from './ResourceOptions';
import { UserRole, useUserContext } from '../context/UserContext';
import { useMutableResourceContext } from '../context/MutableResourceProvider';

const LabOptions = ({ handleChange, handleCheckChange }: ResourceOptionProps) => {
  const { userRole } = useUserContext();
  const { resource } = useMutableResourceContext();

  const canEdit = userRole >= UserRole.ADMIN;
  return (
    <Grid>
      <TextField label="Lab Name" name="name" value={resource.name} onChange={handleChange} disabled={!canEdit} />
      <TextField label="Lab Color" name="color" value={resource.color} onChange={handleChange} disabled={!canEdit} />

      <FormGroup>
        <FormControlLabel
          labelPlacement="top"
          control={<Switch color="primary" name="sendOut" checked={resource.sendOut || false} onChange={handleCheckChange} />}
          label="Send to PWN"
        />
      </FormGroup>
    </Grid>
  )
};

export default LabOptions;
