import { Box, Typography, Button, Grid, Modal, Paper } from '@material-ui/core';
import { useHistory } from "react-router-dom";


const DetailEditor = ({ pluralName, children }: any) => {
  const history = useHistory();

  function canSave() {
    return true;
  }

  function handleClose() {
    history.push(`..`);
  }

  async function handleSave() {

  }

  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Paper>
          <Box m={4} style={{ minWidth: "600px" }}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="h6">{pluralName} Details</Typography>
              </Grid>
              {children}
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleSave} disabled={!canSave()}>SAVE</Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Modal >
    </>
  )
};

export default DetailEditor
