import { parentedSchema } from '../services/ResourceService';
import { UserSchema } from '../models/User';
import { OrderSchema } from '../models/Order';
import ResourceIndex from './ResourceIndex';
import { useUserContext } from '../context/UserContext';
import SearchProvider from '../context/SearchProvider';

export default function CareOrderIndex() {

  const { user } = useUserContext()

  if (!user || !user.id) { return <div>Loading</div> };

  return (
    <SearchProvider>
      <ResourceIndex schema={parentedSchema(OrderSchema, UserSchema)}
        scopedParentResourceId={user.id}
        hideExtras={true}
      />
    </SearchProvider>
  )
}
