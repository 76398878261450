
import React from 'react';
import ResourceService, { ResourceSchema, ResourceServiceOptions } from '../services/ResourceService';
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  children: any;
};


type ResourceContextTypes = {
  index: (schema: ResourceSchema, options?: ResourceServiceOptions) => Promise<any>;
  paginatedIndex: (schema: ResourceSchema, options?: ResourceServiceOptions) => Promise<any>;
  read: (id: string, schema: ResourceSchema, options?: ResourceServiceOptions) => Promise<any>;
  createOrUpdate: (resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) => Promise<any>;
  memberAction: (action: string, resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) => Promise<any>;
  destroy: (id: string, schema: ResourceSchema, options?: ResourceServiceOptions) => Promise<any>;
  patch: (resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) => Promise<any>;
  schemaFetch: (id: string, schema: ResourceSchema, options?: ResourceServiceOptions) => Promise<any>;
  downloadFile: (url: string, contentType: string, options?: ResourceServiceOptions) => Promise<any>;
  memberUpload: (action: string, formData: FormData, resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) => Promise<any>;
}

export const ResourceContext = React.createContext<ResourceContextTypes>({} as ResourceContextTypes);
export const useResourceContext = () => React.useContext(ResourceContext);

const ResourceProvider: React.FC<Props> = ({ children }: Props) => {
  const { getAccessTokenSilently, logout } = useAuth0();

  async function tokenize(options?: ResourceServiceOptions) {
    try {
      return { ...options, token: await getAccessTokenSilently() }
    } catch (e) {
      console.error("Error retrieving Auth0 token for the ResourceProvider", e);
      logout({ returnTo: window.location.origin });
    }
  }

  async function index(schema: ResourceSchema, options?: ResourceServiceOptions) {
    return ResourceService.index(schema, await tokenize(options));
  }

  async function paginatedIndex(schema: ResourceSchema, options?: ResourceServiceOptions) {
    return ResourceService.paginatedIndex(schema, await tokenize(options));
  }

  async function read(id: string, schema: ResourceSchema, options?: ResourceServiceOptions) {
    return ResourceService.read(id, schema, await tokenize(options));
  }

  async function createOrUpdate(resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) {
    return ResourceService.createOrUpdate(resource, schema, await tokenize(options))
  }

  async function memberAction(action: string, resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) {
    return ResourceService.memberAction(action, resource, schema, await tokenize(options));
  }

  async function destroy(id: string, schema: ResourceSchema, options?: ResourceServiceOptions) {
    return ResourceService.destroy(id, schema, await tokenize(options));
  }

  async function patch(resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) {
    return ResourceService.patch(resource, schema, await tokenize(options));
  }

  async function schemaFetch(id: string, schema: ResourceSchema, options?: ResourceServiceOptions) {
    return ResourceService.schemaFetch(id, schema, await tokenize(options));
  }

  async function downloadFile(url: string, contentType: string, options?: ResourceServiceOptions) {
    return ResourceService.downloadFile(url, contentType, await tokenize(options));
  }

  async function memberUpload(action: string, formData: FormData, resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) {
    return ResourceService.memberUpload(action, formData, resource, schema, await tokenize(options));
  }


  return <ResourceContext.Provider value={{ index, paginatedIndex, read, createOrUpdate, memberAction, destroy, patch, schemaFetch, downloadFile, memberUpload }}>{children} </ResourceContext.Provider>
}

export default ResourceProvider;
