import { useState, useContext, Dispatch, SetStateAction } from 'react';
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AuthGateway from './AuthGateway';
import OrderSummaryCard from './OrderSummaryCard'
import CareOrderCard from './CareOrderCard'
import InstructionalBreadcrumb from './InstructionalBreadcrumb'
import CareOrderUser, { CareOrderUserSelector } from './CareOrderUser'
import { OrderSchema } from '../models/Order'
import { useUserContext, UserRole } from '../context/UserContext'
import { SnackContext } from "../context/SnackProvider";
import OrderAddresses from './OrderAddresses';
import CareOrderArtifacts from './CareOrderArtifacts';
import MutableResourceProvider, { useMutableResourceContext } from '../context/MutableResourceProvider';
import OrderNextStepCard from './OrderNextStepCard';

type OrderParams = {
  orderId: string
}

const CareOrder = ({ displayOrderCard, setDisplayOrderCard }: { displayOrderCard: boolean, setDisplayOrderCard: Dispatch<SetStateAction<boolean>> }) => {
  const { orderId } = useParams<OrderParams>();
  const { setSnack } = useContext(SnackContext);

  if (!orderId) { return <div>No Order Specified</div> }
  return <MutableResourceProvider resourceIdentifier={orderId} schema={OrderSchema} onDidSaveResource={() => setSnack({ message: "Saved Order" })}>
    <CareOrderComponent displayOrderCard={displayOrderCard} setDisplayOrderCard={setDisplayOrderCard} />
  </MutableResourceProvider>
}

enum EditMode {
  None,
  Labs,
  Addresses,
  Discount,
  Payment
}

export const CareOrderComponent = ({ displayOrderCard, setDisplayOrderCard }: { displayOrderCard?: boolean, setDisplayOrderCard?: Dispatch<SetStateAction<boolean>> }) => {
  const { resource, ogResource } = useMutableResourceContext();
  const order = resource;
  const ogOrder = ogResource;
  const hasTransaction = order && order.paymentTransaction;

  const { userRole } = useUserContext()
  const [editMode, setEditMode] = useState(EditMode.None);

  if (userRole < UserRole.CUSTOMER) {
    return <AuthGateway message="You need to sign in (at least) as a customer to continue." />
  }

  const title = order && order.paymentTransaction
    ? 'Order Details'
    : 'Check Out'

  const handleChangeAddressEdit = (shouldEdit: boolean) => {
    const newMode = shouldEdit ? EditMode.Addresses : EditMode.None;

    setEditMode(newMode);
  }

  const handleChangePaymentEdit = (shouldEdit: boolean) => {
    setEditMode(shouldEdit ? EditMode.Payment : EditMode.None);
  }

  const handleChangeDiscountEdit = (shouldEdit: boolean) => {
    setEditMode(shouldEdit ? EditMode.Discount : EditMode.None);
  }

  const handleChangeLabEdit = (shouldEdit: boolean) => {
    setEditMode(shouldEdit ? EditMode.Labs : EditMode.None);
  }


  return (
    <Grid
      container justifyContent="center" alignItems="flex-start" spacing={2}
    >
      <Grid item xs={12} container justifyContent="space-between" spacing={1} direction="row">

        <Grid item xs={12}>
          {/* TODO MAKE THIS URL USE QA PARAMS */}
          <Button
            startIcon={<ArrowBackIcon />}
            href='https://testing.com'
            style={{ marginRight: 10 }}
          >
            Back to testing.com
          </Button>
        </Grid>


        {order && (
          <InstructionalBreadcrumb />
        )}

        {displayOrderCard && (
          <OrderNextStepCard />
        )}

        <Grid item>
          <Typography variant="h4" style={{ color: '#a90b0b', fontWeight: 'bolder' }}>
            {title}
          </Typography>
          <CareOrderUser />
        </Grid>
        {(editMode === EditMode.None || editMode === EditMode.Addresses) && (
          <Grid item><CareOrderUserSelector setEditing={handleChangeAddressEdit} /></Grid>
        )}
      </Grid>


      {!hasTransaction && (
        <Grid item container xs={12} justifyContent="space-between" spacing={2} direction="row-reverse">

          <Grid item xs={12} md={5}>
            <OrderSummaryCard
              editMode={editMode}
              EditMode={EditMode}
              handleChangeLabEdit={handleChangeLabEdit}
              handleChangeDiscountEdit={handleChangeDiscountEdit}
            />

          </Grid>

          <Grid item xs={12} md={7}>

            <OrderAddresses editing={editMode === EditMode.Addresses} setEditing={handleChangeAddressEdit} />

            <CareOrderCard
              billing={ogOrder.billingAddress}
              // captureShipping={captureShipping}
              order={order}
              setDisplayOrderCard={setDisplayOrderCard}
              onSuccess={async () => { }}
              onError={() => { }}
              shipping={order.shippingAddress}
              hidePostalCode
              editing={(editMode === EditMode.Payment)}
              setEditing={handleChangePaymentEdit}
            />

          </Grid>

        </Grid>
      )}



      {hasTransaction && (
        <Grid item container xs={12} justifyContent="space-between" spacing={3} direction="row">

          <Grid item xs={12}>
            <OrderSummaryCard
              editMode={editMode}
              EditMode={EditMode}
              handleChangeLabEdit={handleChangeLabEdit}
              handleChangeDiscountEdit={handleChangeDiscountEdit}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <OrderAddresses editing={editMode === EditMode.Addresses} setEditing={handleChangeAddressEdit} />
          </Grid>

          <CareOrderArtifacts />

        </Grid>
      )}




    </Grid>
  );
}


export default CareOrder;
