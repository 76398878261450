import { CardContent, Button, Grid, IconButton } from '@material-ui/core';
import { useMutableResourceContext } from '../context/MutableResourceProvider';
import EditIcon from '@material-ui/icons/EditSharp';

export type EditProps = {
  editing?: boolean;
  setEditing?: any;
  children?: any;
}

type EditStateProps = {
  showEditButton?: boolean;
  showCancelButton?: boolean;
  saveAction?: any;
  children: any;
}

const EditState = ({
  showEditButton=false,
  showCancelButton = true,
  editing,
  setEditing,
  children,
  saveAction
}: EditStateProps & EditProps) => {
  const { resourceIsDirty, revertResourceToOG } = useMutableResourceContext();

  const toggleFocus = () => {
    if (editing && resourceIsDirty()) {
      revertResourceToOG();
    }

    setEditing && setEditing(!editing);
  }

  const renderEditAction = () => {
    if (resourceIsDirty()) {
      if (!editing) { return null; }
    } else {
      if (!showEditButton) { return null; }
    }

    if (!showCancelButton) { return null; }

    return editing ? <Button variant='contained' onClick={toggleFocus}>Cancel</Button> : <IconButton onClick={toggleFocus}><EditIcon /></IconButton>
  }

  return (
    <div key={`focus-card`}>
      <div style={{display: 'flex', justifyContent: 'flex-end', padding: '12px 12px 0 0'}}>
        {renderEditAction()}
      </div>
      <CardContent className="card-content">
        {children}
        {editing && saveAction && (
          <Grid container item direction="row" justifyContent="space-around" >
            <Grid item xs={12} style={{marginTop: '12px'}}>
              {saveAction}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </div>
  )
}

export default EditState;
