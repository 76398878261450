import { useEffect, useState } from 'react'
import { Paper, Grid, makeStyles, Theme } from '@material-ui/core';

import { CreateUser } from '../models/User'
import UserForm from './UserForm'

const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      [theme.breakpoints.down('md')]: {
        padding: 0,
        '& .MuiBox-root': {
          padding: '45px 18px',
          width: 'calc(100% - 36px)'
        }
      },
      [theme.breakpoints.up('md')]: {
        margin: 48
      }
    },
  }
})


const UserProfileModal = (props: any) => {
  const styles = useStyles()
  const { onCancel, onSave, user, validationErrors, loading } = props
  const [profile, setProfile] = useState<Partial<CreateUser>>()

  useEffect(() => {
    if (!profile) {
      setProfile(user)
    }
  }, [profile, user, setProfile])

  const handleChange = (args: any) => {
    const addressKeys = ['addressOne', 'addressTwo', 'city', 'state', 'zip']
    const _profile: any = profile ? { ...profile } : {}
    const { target } = args
    const { name, value } = target

    if (addressKeys.includes(name)) {
      if (!_profile.billingAddress) { //TODO: maybe a better way to initialize this
        _profile.billingAddress = {};
      }
      _profile.billingAddress[name] = value
    } else if (name === 'phoneNumber') {
      _profile.phoneNumber = value
    } else {
      _profile[name] = value;
    }

    setProfile(_profile); //TODO: Maybe test this enrollment flow again -jj
  }

  const handleCheckChange = (event: any) => {
    setProfile((freshResource: any) => {
      const r = { ...freshResource } as any;
      r[event.target.name] = event.target.checked;
      return r;
    })
  }


  const handleSave = () => {
    onSave(profile)
  }

  if (!profile) { return <div>Loading</div> };

  return (
    <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
      <Grid item md={6} xs={12}>
        <Paper className={styles.paper}>
          <UserForm
            user={profile}
            handleChange={handleChange}
            handleCancel={onCancel}
            handleSave={handleSave}
            handleCheckChange={handleCheckChange}
            validationErrors={validationErrors}
            loading={loading}
          />
        </Paper>
      </Grid>
    </Grid>



  )
}

export default UserProfileModal
