import { Grid, Typography, Paper } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { Link, useRouteMatch } from 'react-router-dom';
import { useUserContext } from '../context/UserContext'
import User from '../models/User'
import { makeStyles } from '@material-ui/core/styles';

const Profile = () => {
  const { isLoading, user } = useUserContext();
  const { path } = useRouteMatch();
  const _user = (user as User)

  const useStyles = makeStyles(() => ({
    root: {
      padding: "4em 3em"
    }
  }));

  return (
    <Paper className={useStyles().root}>
      {isLoading && (
        <div>Loading...</div>
      )}
      <h2>{_user.firstName} {_user.middleInitial}. {_user.lastName}<Link to={`${path}/edit`}><Typography variant="subtitle2">EDIT</Typography></Link></h2>

      <Grid container spacing={2}>
        <Grid item xs={1}>
          Email:
        </Grid>
        <Grid item xs={11}>
          {_user.emailAddress}
        </Grid>

        <Grid item xs={1}>
          DOB:
        </Grid>
        <Grid item xs={5}>
          {new Date(_user.dob).toLocaleDateString('en-US')}
        </Grid>
        <Grid item xs={1}>
          Gender:
        </Grid>
        <Grid item xs={5}>
          {_user.gender}
        </Grid>

        <Grid item xs={1}>
          Phone Number:
        </Grid>
        <Grid item xs={5}>
          {_user.phoneNumber}
        </Grid>
        <Grid item xs={1}>
          Testing In:
        </Grid>
        <Grid item xs={5}>
          {_user.testingIn}
        </Grid>

        <Grid item xs={2}>
          Send Birthday Card:
        </Grid>
        <Grid item xs={1}>
          {_user.sendBirthdayCard && (<DoneIcon />)}
        </Grid>
        <Grid item xs={2}>
          Twelve Month Reminder:
        </Grid>
        <Grid item xs={1}>
          {_user.twelveMonthReminder && (<DoneIcon />)}
        </Grid>
        <Grid item xs={2}>
          Future Updates:
        </Grid>
        <Grid item xs={1}>
          {_user.futureUpdates && (<DoneIcon />)}
        </Grid>
        <Grid item xs={2}>
          SMS Updates:
        </Grid>
        <Grid item xs={1}>
          {_user.smsUpdates && (<DoneIcon />)}
        </Grid>
      </Grid>
    </Paper>
  )
};

export default Profile;
