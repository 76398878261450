
import React from "react";
import { Link } from "react-router-dom";
import { Snack } from '../context/SnackProvider'
import {
  Button,
  Snackbar
} from "@material-ui/core";

interface Props {
  snack?: Snack;
  setSnack: any;
}

// A healthier alternative to Snackbar
const Saladbar = ({ snack, setSnack }: Props) => {

  const eatSnack = () => {
    setSnack(undefined)
  }

  return (
    <Snackbar
      open={snack !== undefined}
      message={snack?.message}
      autoHideDuration={5000}
      onClose={eatSnack}
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={eatSnack}>
            OK
          </Button>
          {snack?.source &&
            <Link to={`${snack.source}`} style={{ textDecoration: 'none' }}>
              <Button color="secondary" size="small" onClick={eatSnack} >
                VIEW
              </Button>
            </Link>
          }
        </React.Fragment>
      } />
  )
};

export default Saladbar;
