import { ResourceSchema } from '../services/ResourceService';
import ColumnFactory from '../services/ColumnFactory';
import DiscountOptions from '../components/DiscountOptions';
import formatCurrency from '../utils';
import { HubActionProps, NewRedirectAction, HubAction } from '../components/CommonResourceHandlers';

const DiscountHubActionsResolver = (props: HubActionProps): HubAction[] | null => {
  const { schema, parentResourceId } = props;
  return (schema.parent && parentResourceId) ? null : [NewRedirectAction()];
}

type Discount = {
  id?: string
  valueType: string
  percentOff?: number
  amount?: number
}

const DiscountSchema = {
  apiResponseResourceCollectionKey: 'discounts',
  apiResponseResourceItemKey: 'discount',
  apiRequestPath: '/discounts',
  labels: { name: 'Discount', pluralName: 'Discounts', describer: (resource) => resource.code },
  columns: [
    ColumnFactory.id,
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'code', headerName: 'CODE', flex: 1 },
    { field: 'valueType', headerName: 'TYPE', flex: 1 },
    { field: 'discount', headerName: 'DISCOUNT', flex: 1, valueGetter: (params) => { return params.row.valueType === 'flat' ? formatCurrency(params.row.amount) : params.row.percentOff } },
    ColumnFactory.actions()
  ],
  columnsForMobile: [],
  optionHandler: DiscountOptions,
  actionsResolver: DiscountHubActionsResolver

} as ResourceSchema;


export { DiscountSchema }
export default Discount
