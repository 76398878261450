import CheckBlankIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { UserRole, useUserContext } from '../context/UserContext';
import { ResourceLinkDef } from '../services/ResourceService';
import PushDetailButton from '../components/PushDetailButton';

const RowDetailButton = (props: any) => {

  const { row } = props;
  return <PushDetailButton color="primary" to={row.id}>Details</PushDetailButton>

}

const RowLinks = (props: any) => {
  const { links, row } = props;
  const { userRole } = useUserContext();

  if (userRole < UserRole.CSR) { return null; }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      {links.map((link: ResourceLinkDef) => (
        <PushDetailButton to={`${row.id}/${link.path}`}>{link.cta}</PushDetailButton>
      ))}
    </div>
  )
}


const RowActions = (props: any) => {

  return (
    <>
      {props.showDetail && <RowDetailButton {...props} />}
    </>
  )
}

const ColumnFactory = {
  id: { field: 'id', headerName: 'ID', flex: 1 },
  createdAt: {
    field: 'createdAt',
    headerName: 'Created',
    width: 200,
    editable: false,
  },
  updatedAt: {
    field: 'updatedAt',
    headerName: 'Modified',
    width: 200,
    editable: false,
  },
  check: (col: any) => {
    const { field, headerName } = col;
    return {
      field,
      headerName,
      flex: 1,
      editable: false,
      renderCell: (row: any) => {
        return row.value ? <CheckIcon color="primary" /> : <CheckBlankIcon color="primary" />
      }
    }
  },
  actions: () => {
    return {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      editable: false,
      renderCell: (row: any) => (<RowActions row={row} showDetail />)
    }
  },
  links: (links: ResourceLinkDef[]) => {
    const isHub = window.location.href.indexOf('/hub/') > -1;

    return isHub && {
      field: 'links',
      headerName: 'Links',
      flex: 1,
      editable: false,
      renderCell: (row: any) => <RowLinks row={row} links={links} />
    }
  }

}

export default ColumnFactory;
