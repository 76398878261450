import {
  FormControl,
  FormHelperText,
  InputLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import TestingInSelect from './UserFields/TestingInSelect';
import Address from '../models/Address';
import User from '../models/User';

import { useMutableResourceContext } from '../context/MutableResourceProvider';


enum TypeOfAddress {
  BILLING = 'Billing Address',
  SHIPPING = 'Shipping Address'
}

export type AddressType = {
  city: string
  line1: string
  line2?: string
  name?: string
  state: string
  postal_code: string
}

export type ValidationErrorType = {
  errors: Map<any, any>
  type: string
}

interface AddressFormProps {
  user: User;
  address?: Address;
  type: TypeOfAddress;
  onChange: (newAddress: Address) => void;
  // showName?: boolean
  validationErrors?: ValidationErrorType;
  disabled: boolean;
}

const AddressForm = (props: AddressFormProps) => {
  const { address, onChange, type, user, disabled = false } = props
  const { validationErrors } = useMutableResourceContext();

  const handleOnChange = (event: any) => {
    const { target } = event
    const { name, value } = target

    const a = { ...address } as any;
    a[name] = value;
    onChange(a);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}> <Typography variant="h6">{type}</Typography></Grid>
      <Grid item xs={12}>
        <TextField
          disabled={true} //always disabled
          fullWidth
          label="Full Name"
          name="username"
          type="text"
          value={`${user.firstName} ${user.lastName}`}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={disabled}
          fullWidth
          label="Street Address"
          name="addressOne"
          onChange={handleOnChange}
          type="text"
          value={address?.addressOne || ""}
          required
          variant="outlined"
          error={Boolean(validationErrors?.errors?.addressOne)}
          helperText={validationErrors?.errors?.addressOne}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={disabled}
          fullWidth
          label="Apt, Suite"
          name="addressTwo"
          onChange={handleOnChange}
          type="text"
          value={address?.addressTwo || ""}
          variant="outlined"
          error={Boolean(validationErrors?.errors?.addressTwo)}
          helperText={validationErrors?.errors?.addressTwo}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={disabled}
          fullWidth
          label="City"
          name="city"
          onChange={handleOnChange}
          type="text"
          value={address?.city || ""}
          required
          variant="outlined"
          error={Boolean(validationErrors?.errors?.city)}
          helperText={validationErrors?.errors?.city}
        />
      </Grid>

      <Grid item xs={12} container direction="row" spacing={1}>
        <Grid item xs={6}>
          <TextField
            disabled={disabled}
            fullWidth
            label="Postal Code"
            name="zip"
            onChange={handleOnChange}
            type="text"
            value={address?.zip || ""}
            required
            variant="outlined"
            error={Boolean(validationErrors?.errors?.zip)}
            helperText={validationErrors?.errors?.zip}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl disabled={disabled} required fullWidth style={{ marginBottom: '5px' }}>
            <InputLabel id="state-label">State</InputLabel>
            <TestingInSelect
              id="state"
              labelId="state-label"
              name="state"
              handleChange={handleOnChange}
              currentValue={address?.state || ""}
              variant="outlined"
            />
            {Boolean(validationErrors?.errors?.state) && (
              <FormHelperText error>{validationErrors?.errors?.state}</FormHelperText>
            )}
          </FormControl>
        </Grid>

      </Grid>

    </Grid>
  )
}

export { TypeOfAddress }
export default AddressForm
