
import { ResourceSchema } from '../services/ResourceService';
import ProductVariant from './ProductVariant';

const OrderIntentSchema = {
  apiResponseResourceCollectionKey: 'order_intents',
  apiResponseResourceItemKey: 'order_intent',
  apiRequestPath: '/order_intents',
  // labels: { name: 'Refund', pluralName: 'Refunds', describer: (resource) => resource.id },
} as ResourceSchema;

export { OrderIntentSchema };


type OrderIntent = {
  id: string;
  variantIds: string[];
  variants: ProductVariant[];
  originationUrl?: string;
  orderId?: string;
}

export default OrderIntent;
