import { format } from 'date-fns'
import { Typography } from '@material-ui/core';
import { ResourceSchema } from '../services/ResourceService';
import ColumnFactory from '../services/ColumnFactory';

import formatCurrency from '../utils';
import OrderOptions from '../components/OrderOptions';
import PaymentTransaction from './PaymentTransaction';
import { FilterOption } from '../services/ResourceService';
import User from './User'
import LineItem from './LineItem';
import Address from './Address';
import OrderIntent from './OrderIntent';

// POST v GET has different attributes camelCase v snake
// export type OrderAddress = {
//   addressOne: string
//   address_one?: string
//   addressTwo?: string
//   city: string
//   state: string
//   zip: string
// }

type Order = {
  createdAt?: any
  aasmState?: string
  id?: string
  fees?: number
  subTotal: number
  totalAmount: number
  lineItems?: LineItem[]
  orderNumber?: string
  paymentTransaction?: PaymentTransaction
  user?: User
  orderIntent?: OrderIntent;
  billingAddress?: Address
  shippingAddress?: Address
  phoneNumber?: string
  discount?: any
  pwnOrders?: any[]
  refunds?: any[]
  notes?: any[] //TODO: add Note Type to this
  userId?: string
  orderIntentId?: string
}


const DateDescriptor = (filterOption: FilterOption) => {
  const dateString = filterOption.value ? filterOption.value.toLocaleDateString() : '...';
  return `${filterOption.description}: ${dateString}`;
}

const dateFormat = (args: any) => {
  if (!args) return ''
  const value = args?.row?.paymentTransaction ? args?.row?.paymentTransaction.createdAt : args.value
  const _date = new Date(value)

  return format(_date, "MMM d, yyyy")
}

const TotalGetter = (params: any) => {
  const order = params.row;
  var sum = 0;

  order.refunds?.forEach((refund: any) => {
    sum += refund.amount;
  });

  const refundDescription = sum > 0 ? `(-${formatCurrency(sum)})` : '';
  return `${formatCurrency(order.totalAmount)} ${refundDescription}`;
}

const formatMobileColumn = (args: any) => {
  const { row } = args
  const _date = new Date(row.createdAt)

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="caption">{format(_date, "MMM d, yyyy")}</Typography>
      <Typography variant="caption">{row.id}</Typography>
    </div>
  )
}

const OrderBackendAdaptor = (frontendResource: Order) => {
  console.trace();
  // console.info("Trying to adapt (1)", { frontendResource });

  var r = {
    ...frontendResource,
    // lineItemAttributes: frontendResource.lineItems
    // phoneNumberAttributes: { number: frontendResource.phoneNumber }
  } as any;
  // console.info("Trying to adapt (2)", { frontendResource, r });

  if (frontendResource.billingAddress) {
    r.billingAddressAttributes = frontendResource.billingAddress;
  }

  if (frontendResource.shippingAddress) {
    r.shippingAddressAttributes = frontendResource.shippingAddress;
  }

  if (frontendResource.lineItems) {
    r.lineItemsAttributes = frontendResource.lineItems;
  }

  if (frontendResource.notes) {
    r.notesAttributes = frontendResource.notes;
  }

  if (frontendResource.phoneNumber) {
    r.phoneNumberAttributes = frontendResource.phoneNumber;
  }

  if (frontendResource.orderIntent) {
    r.orderIntentId = frontendResource.orderIntent.id;
  }




  delete r.orderNumber;
  delete r.orderIntent;
  // delete r.totalAmount;
  // delete r.subTotal;
  // delete r.fees;
  delete r.refunds;
  delete r.pwnOrders;
  delete r.orders;
  delete r.createdAt;
  delete r.updatedAt;
  delete r.lineItems;
  // delete r.aasmState;
  // delete r.user;
  delete r.billingAddress;
  delete r.notes;

  return r;
}


const Links = [{ path: 'line_items', key: 'lineItems', cta: 'Line Items' }, { path: 'refunds', cta: 'Manage Refunds' }]

const OrderSchema = {
  apiResponseResourceCollectionKey: 'orders',
  apiResponseResourceItemKey: 'order',
  apiRequestPath: '/orders',
  labels: { name: 'Order', pluralName: 'Orders', describer: (resource) => resource.id },
  links: Links,
  columns: [
    { field: 'createdAt', headerName: 'Purchase Date', flex: 1, valueFormatter: dateFormat },
    { field: 'id', headerName: 'ORDER NUMBER', flex: 1 },
    { field: 'totalAmount', headerName: 'TOTAL', flex: 1, valueGetter: TotalGetter },
    { field: 'user.fullName', headerName: 'User', flex: 1, sortable: false, valueGetter: (params: any) => { return `${params.row.user?.firstName} ${params.row.user?.lastName}` } },
    { field: 'user.emailAddress', headerName: 'Email', flex: 1, sortable: false, valueGetter: (params: any) => { return params.row.user?.emailAddress } },
    { field: 'aasmState', headerName: 'Order Status', flex: 1 },
    ColumnFactory.links(Links),
    ColumnFactory.actions()
  ],
  columnsForMobile: [
    { field: 'id', headerName: 'ORDER', flex: 1, renderCell: formatMobileColumn },
    ColumnFactory.actions()
  ],
  optionHandler: OrderOptions,
  filterOptions: [
    { name: 'active', value: true, description: "Active" },
    { name: 'inactive', value: false, description: "Inactive" },
    { name: 'createdAfter', valueType: 'date', description: "Created After", descriptor: DateDescriptor },
    { name: 'createdBefore', valueType: 'date', description: "Created Before", descriptor: DateDescriptor }
  ],
  adaptor: {
    toBackend: OrderBackendAdaptor
  }
} as ResourceSchema;


export { OrderSchema };
export default Order;
