import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

export default function Attribute(props: any) {
  return (
    <>
      {props.name}
      {props.tooltip && <Tooltip title={props.tooltip} ><InfoIcon fontSize="small" /></Tooltip>}
      {props.children}
    </>
  );
};

