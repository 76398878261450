import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const stateMapping: any = {
  '': [],
  "cancelled": ['submitted', 'rejected', 'requisition_ready', 'complete'],
  "cart": ["paid"],
  "error": ['submitted', 'rejected', 'requisition_ready', 'complete'],
  "paid": ['submitted', 'rejected', 'requisition_ready', 'complete'],
  "partially_submitted": ['submitted', 'rejected', 'requisition_ready', 'complete'],
  "submitted": ['rejected', 'requisition_ready', 'complete'],
  "rejected": ['requisition_ready', 'complete'],
  "requisition_ready": ['rejected', 'complete'],
  "complete": ['rejected']
}

interface Props {
  handleChange: (e: any) => void;
  resource: any;
  ogResource: any;
}

const OrderStatusDropDown = ({ handleChange, resource, ogResource }: Props) => {
  const originalState = ogResource.aasmState;
  const currentOptions = stateMapping[originalState];

  return (
    <Grid container item spacing={1} xs={12}>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="aasmState-label">Status</InputLabel>
          <Select
            labelId="aasmState-label"
            id="aasmState"
            name="aasmState"
            value={resource.aasmState}
            onChange={handleChange}
            multiple={false}
          >
            <MenuItem value={originalState}>{originalState}</MenuItem>
            {currentOptions?.map((state: any) => <MenuItem value={state}>{state}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
};

export default OrderStatusDropDown;
