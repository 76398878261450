import { useMutableResourceContext } from '../context/MutableResourceProvider';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import PwnOrder from '../models/PwnOrder';
import Order from '../models/Order';
import DownloadPwnDocument from './DownloadPwnDocument';
import formatCurrency, { formatDateString } from '../utils';
import EditCard from './EditCard';

const paymentDate = (transaction: any) => {
  return formatDateString(transaction.createdAt);
}

interface PaymentTransactionCardProps {
  order: Order;
}

const PaymentTransactionCard = ({ order }: PaymentTransactionCardProps) => {
  return (
    <EditCard title="Payment Information">
      <Typography>Order #: {order.id}</Typography>
      <Typography>Confirmation #: {order.paymentTransaction?.confirmationNumber}</Typography>
      <Typography>Date: {paymentDate(order.paymentTransaction)}</Typography>
    </EditCard>
  )
}

interface RefundCardProps {
  refunds: any[];
}

const RefundCard = ({ refunds }: RefundCardProps) => {
  return (
    <EditCard title="Refunds">
      {refunds.map((refund: any) => (
        <>
          <Typography key="payment-date">Date: {formatDateString(refund.createdAt)}</Typography>
          <Typography key={refund.id}>Refund Amount: {formatCurrency(refund.amount)}</Typography>
          <Typography>Confirmation #: {refund.confirmationNumber}</Typography>
          <br />
        </>
      ))}
    </EditCard>
  )
}

const CareOrderArtifacts = () => {
  const { resource } = useMutableResourceContext();
  const order = resource;
  const hasTransaction = order && order.paymentTransaction;
  if (!hasTransaction) { return null; }

  return (
    <>
      <Grid item xs={12} md={6}>
        <PaymentTransactionCard order={order} />
      </Grid>
      {order.refunds && order.refunds.length > 0 && (
        <Grid item xs={12}>
          <RefundCard refunds={order.refunds} />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <EditCard title="Requisitions">
          {order?.pwnOrders?.map((pwnOrder: PwnOrder) => {
            return (
              <Typography key={`pwn-order-${pwnOrder.id}-req`}>{pwnOrder.lab.name} {pwnOrder.externalOrderId}:
                {pwnOrder.requisitionForm ?
                  <DownloadPwnDocument pwnId={pwnOrder.id} externalOrderId={pwnOrder.externalOrderId} docName={'requisition_form'} /> :
                  <span>Requisition not available yet</span>}
              </Typography>
            )
          }
          )}
        </EditCard>
      </Grid>
      <Grid item xs={12} md={6} >
        <EditCard title="Results">
          {order?.pwnOrders?.map((pwnOrder: PwnOrder) => {
            return (
              <Typography key={`pwn-order-${pwnOrder.id}-res`}>{pwnOrder.lab.name} {pwnOrder.externalOrderId}:
                {pwnOrder.testResult ?
                  <DownloadPwnDocument pwnId={pwnOrder.id} externalOrderId={pwnOrder.externalOrderId} docName={'test_result'} /> :
                  <span>Results not available yet</span>}
              </Typography>
            )
          }
          )}
        </EditCard>
      </Grid>
    </>
  );
}

export default CareOrderArtifacts;
