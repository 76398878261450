//Like a product offering
import Product from './Product';
import ProductVariantOptions from '../components/ProductVariantOptions';
import { ResourceSchema } from '../services/ResourceService';
import ColumnFactory from '../services/ColumnFactory';

import Lab from '../models/Lab';
import { FilterOption } from '../services/ResourceService';
import formatCurrency from '../utils'
import { HubActionProps, NewRedirectAction, HubAction, AddOneToManyAction, CreateOneToManyAction } from '../components/CommonResourceHandlers';
import { UserRole, useUserContext } from '../context/UserContext';


const ProductVariantHubActionsResolver = (props: HubActionProps): HubAction[] | null => {
  const { userRole } = useUserContext();

  const { schema, parentResourceId, resource } = props;

  if (userRole >= UserRole.ADMIN) {

    var actions = [];
    if (schema.parent && parentResourceId) {
      //Section if there a parent to product
      //We can add this user to a discount
      actions.push(AddOneToManyAction());
      if (resource) {
        // actions.push(RemoveOneToManyAction());
      }
      actions.push(CreateOneToManyAction());

    } else {
      actions.push(NewRedirectAction());
    }

    return actions;
  }

  return null;
}



type ProductVariant = {
  id: string;
  lab?: Lab;
  product?: Product;
  price: number;
  cost: number;
  sku: string;
  labId: string;
  productId: string;
  name: string;
  shippable: boolean;
};

const DateDescriptor = (filterOption: FilterOption) => {
  const dateString = filterOption.value ? filterOption.value.toLocaleDateString() : '...';
  return `${filterOption.description}: ${dateString}`;
}

const ProductVariantSchema = {
  apiResponseResourceCollectionKey: 'variants',
  apiResponseResourceItemKey: 'variant',
  apiRequestPath: '/variants',
  labels: { name: 'Variant', pluralName: 'Variants', describer: (resource) => resource.name },
  columns: [
    ColumnFactory.id,
    {
      field: 'name',
      headerName: 'Product Variant Name',
      flex: 2,
      editable: true,
    },
    {
      field: 'lab.name',
      headerName: 'Lab Name',
      flex: 2,
      editable: false,
      valueFormatter: (param: any) => {
        const { row } = param
        const { lab } = row

        return lab.name
      }
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      editable: false,
      valueFormatter: (param: any) => param.value && formatCurrency(param.value)
    },
    {
      field: 'cost',
      headerName: 'Cost',
      flex: 1,
      editable: false,
      valueFormatter: (param: any) => param.value && formatCurrency(param.value)
    },
    ColumnFactory.createdAt,
    ColumnFactory.updatedAt,
    ColumnFactory.check({
      field: 'shippable',
      headerName: 'Shippable',
    }),
    ColumnFactory.check({ field: 'active', headerName: 'ACTIVE' }),
    ColumnFactory.actions()
  ],
  optionHandler: ProductVariantOptions,
  filterOptions: [
    { name: 'active', value: true, description: "Active" },
    { name: 'inactive', value: false, description: "Inactive" },
    { name: 'createdAfter', valueType: 'date', description: "Created After", descriptor: DateDescriptor },
    { name: 'createdBefore', valueType: 'date', description: "Created Before", descriptor: DateDescriptor }
  ],
  actionsResolver: ProductVariantHubActionsResolver

} as ResourceSchema;

function emptyProductVariant() {
  return {} as ProductVariant;
}

export { ProductVariantSchema, emptyProductVariant };


export default ProductVariant;
