import { GridColDef } from '@material-ui/data-grid'

export type ResourceServiceOptions = {
  query?: string;
  filters?: FilterOption[];
  token?: string;
  parentResourceId?: string;
  page?: number;
  orphan?: boolean;
  method?: CreateUpdateMethods
  sort?: string;
  body?: any;
  contentType?: string;
}

export enum CreateUpdateMethods {
  PUT = 'PUT',
  POST = 'POST'
}

const generateHeaders = (token?: string, resource?: any): HeadersInit => {
  const headers = new Headers();

  if (!(resource instanceof FormData)) {
    headers.set("Content-Type", "application/json");
  }
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  return headers;
}

async function paginatedIndex(schema: ResourceSchema, options?: ResourceServiceOptions) {
  var path = pathForSchema(schema, undefined, options);
  var args = [];

  if (options) {

    if (options.query) {
      args.push({ name: 'query', value: options.query });
    }

    if (options.sort) {
      args.push({ name: 'sort', value: options.sort });
    }

    if (options.filters) {

      const formattedFilterArgs = options.filters.map((filter: FilterOption) => {
        const v = filter.valueType === 'date' ? filter.value.toISOString() : filter.value;
        return { name: filter.name, value: v }
      });

      args.push(...formattedFilterArgs);
    }

    if (options.page) {
      args.push({ name: 'page', value: options.page });
    }

    path += argsToUrlParamString(args);

  }


  const url = urlWithApiPath(path);
  return fetchPaginatedIndex(url, options);
}


async function fetchPaginatedIndex(url: string, options?: ResourceServiceOptions) {
  const result = await fetch(url, { headers: generateHeaders(options?.token) });

  const json = await result.json();
  console.info('GET result:', json);

  const expectedStatus = 200
  if (result.status === expectedStatus) {
    return json;
  } else {
    throw { errors: json, status: result.status }; // eslint-disable-line
  }
}

async function read(url: string, options?: ResourceServiceOptions) {
  const result = await fetch(url, { headers: generateHeaders(options?.token) });

  const json = await result.json();

  const expectedStatus = 200
  if (result.status === expectedStatus) {
    return json;
  } else {
    throw { errors: json, status: result.status }; // eslint-disable-line
  }
}

async function createOrUpdate(url: string, resource: any, method: CreateUpdateMethods, options?: ResourceServiceOptions) {
  const r = { ...resource, id: undefined, createdAt: undefined, updatedAt: undefined, slug: undefined };

  const body = JSON.stringify(r);
  var headers = generateHeaders(options?.token, resource);

  const o = {
    method,
    body,
    headers
  } as any

  const expectedStatues = [200, 201]
  const result = await fetch(url, o);
  const json = await result.json();
  // TODO: figure out if any resources expect this response to only be 201
  // const expectedStatus = method === 'POST' ? 201 : 200
  if (expectedStatues.includes(result.status)) {
    return json;
  } else {

    if (json?.error) {
      console.error({ json });
      throw new Error(json.error)
    }

    console.error({ errors: json, status: result.status })
    // TODO: are we expecting this too bubble up to the UI?
    throw { errors: json, status: result.status }; // eslint-disable-line
  }
}

async function destroy(url: string, options?: ResourceServiceOptions) {
  const o = { headers: generateHeaders(options?.token), method: 'DELETE' }
  const result = await fetch(url, o);
  return result;
}

async function patch(url: string, resource: any, options?: ResourceServiceOptions) {
  const body = JSON.stringify(resource);
  const o = {
    body,
    headers: generateHeaders(options?.token),
    method: 'PATCH'
  }

  const result = await fetch(url, o);
  const json = await result.json();

  if (result.status < 400) {
    return json;
  } else {
    throw { errors: json, status: result.status }; // eslint-disable-line
  }
}

function baseUrl() {
  const baseUrl = process.env.REACT_APP_HUB_API_DOMAIN;
  return baseUrl;
}

function urlWithPath(path: string) {
  const url = baseUrl();

  return `${url}${path}`;
}

function urlWithApiPath(path: string) {
  return urlWithPath(`/api/v1${path}`)
}

function urlWithHubPath(path: string) {
  return urlWithPath(`/hub${path}`);
}

function pathForSchema(schema: ResourceSchema, resourceId?: string, options?: ResourceServiceOptions) {
  var parentResourceId = options?.parentResourceId;
  var path = "";
  if (schema.parent && parentResourceId) {
    path += schema.parent.apiRequestPath;
    if (parentResourceId) {
      path += `/${parentResourceId}`;
    }
  }

  path += `${schema.apiRequestPath}`;
  if (resourceId) {
    path += `/${resourceId}`;
  }

  return path;
}

function hubPathForSchema(schema: ResourceSchema, resourceId?: string, parentResourceId?: string) {
  const options = resourceId === 'new' ? {} : { parentResourceId };
  return `/hub${pathForSchema(schema, resourceId, options)}`;
}

function carePathForSchema(schema: ResourceSchema, resourceId?: string, parentResourceId?: string) {
  const options = { parentResourceId };
  return `/care${pathForSchema(schema, resourceId, options)}`;
}

function navUrlForResources(schema: ResourceSchema, resourceId?: string, parentResourceId?: string) {
  var path = pathForSchema(schema, resourceId, { parentResourceId });

  const url = urlWithHubPath(path);
  return url;
}

type FilterOption = { name: string, value: any, valueType: 'date', description: string, descriptor?: (filterOption: FilterOption) => string }

export type ResourceLinkDef = {
  path: string;
  cta: string;
  key?: string;
};

export const ResourceComparitor = (res1: any, res2: any) => JSON.stringify(res1) === JSON.stringify(res2)

interface ResourceAdaptor {
  toFrontend?: (backendObject: any) => any;
  toBackend?: (frontendObject: any) => any;
}


type ResourceSchema = {
  apiResponseResourceItemKey: string;
  apiResponseResourceCollectionKey: string
  apiRequestPath: string;
  labels: { name: string, pluralName: string, describer: (resource: any) => string };
  columns: GridColDef[];
  columnsForMobile: GridColDef[]
  optionHandler: any;
  subPaths?: any[];
  resourceId?: string;
  parent?: ResourceSchema;
  filterOptions?: FilterOption[];
  idResolver?: (id: string) => string;
  actionsResolver?: any; //yes types needed
  bulkActionsResolver?: any;
  links?: ResourceLinkDef[];
  comparisonHandler?: (res1: any, res2: any) => boolean;
  adaptor?: ResourceAdaptor;
  hideSearchAction?: boolean;
};


function parentedSchema(schema: ResourceSchema, parent: ResourceSchema) {
  return { ...schema, parent: parent };
}

export type { ResourceSchema, FilterOption };
export { parentedSchema };

interface Arg {
  name: string,
  value: string
}

function argsToUrlParamString(args: Arg[]) {
  var urlParamString = '';

  args.forEach((arg: Arg, index: number) => {
    const p = index <= 0 ? '?' : '&';
    urlParamString += `${p}${arg.name}=${arg.value}`
  });

  return urlParamString; //TODO: urlencode this?
}


const ResourceService = {
  baseUrl,
  index: async (schema: ResourceSchema, options?: ResourceServiceOptions) => {
    const response = await paginatedIndex(schema, options);
    console.info("GOT RESPONSE", { response }, "Looking for key", schema.apiResponseResourceCollectionKey)
    return response[schema.apiResponseResourceCollectionKey];
  },
  paginatedIndex,
  read: async (id: string, schema: ResourceSchema, options?: ResourceServiceOptions) => {
    const response = await read(urlWithApiPath(pathForSchema(schema, id, options)), options);
    if (response[schema.apiResponseResourceItemKey]) {
      return response[schema.apiResponseResourceItemKey];
    } else {
      return response;
    };
  },
  createOrUpdate: async (resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) => {
    var id = schema.idResolver && resource.id ? schema.idResolver(resource.id) : resource.id;
    var apiPath = pathForSchema(schema, id, options);
    const url = urlWithApiPath(apiPath);

    const method = options?.method || (resource.id ? CreateUpdateMethods.PUT : CreateUpdateMethods.POST);
    const backendResource = schema.adaptor?.toBackend ? schema.adaptor.toBackend(resource) : resource;
    console.info("ABOUT TO CREATE", { url, method, schema, resource, backendResource });
    const response = await createOrUpdate(url, backendResource, method, options);

    if (response[schema.apiResponseResourceItemKey]) {
      return response[schema.apiResponseResourceItemKey];
    } else {
      console.warn("Response wasn't nested as expected");
      return response;
    };
  },
  memberAction: async (action: string, resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) => {
    const schemaPath = pathForSchema(schema, resource.id, options);
    const url = urlWithApiPath(`${schemaPath}/${action}`);
    // note: allows flexibility through options, but preserves existing meberAction default of PUT
    const method: CreateUpdateMethods = options?.method === CreateUpdateMethods.POST
      ? CreateUpdateMethods.POST
      : CreateUpdateMethods.PUT

    try {

      const backendResource = schema.adaptor?.toBackend ? schema.adaptor.toBackend(resource) : resource;
      console.info("ABOUT TO DO MEMBER ACTION", action, { url, method, schema, resource, backendResource });

      const response = await createOrUpdate(url, backendResource, method, options);

      if (response[schema.apiResponseResourceItemKey]) {
        return response[schema.apiResponseResourceItemKey];
      } else if (schema.parent && response[schema.parent?.apiResponseResourceItemKey]) {
        //I'm not sure when this is the case but wanted to maintain compatibility
        console.info("MemberAction response was nested under parent", action, schema);
        return response[schema.parent.apiResponseResourceItemKey];
      } else {
        console.warn("Response wasn't nested as expected");
        return response;
      };
    } catch (err) {
      throw err
    }
  },
  destroy: async (id: string, schema: ResourceSchema, options?: ResourceServiceOptions) => {
    var apiPath = pathForSchema(schema, id, options);
    const url = urlWithApiPath(apiPath);
    return (await destroy(url, options));
  },
  patch: async (resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) => {
    const id = schema.idResolver && resource.id ? schema.idResolver(resource.id) : resource.id;
    const apiPath = pathForSchema(schema, id, options)

    return await patch(urlWithApiPath(apiPath), resource, options)
  },
  schemaFetch: async (id: string, schema: ResourceSchema, options?: ResourceServiceOptions) => {
    // This is almost a raw fetch, with helpers for the schema parents/parentResourceId/resources/resource format and headers. Specify any method or body you want in options.
    const apiPath = pathForSchema(schema, id, options)
    const url = urlWithApiPath(apiPath);
    const o = { ...options, headers: generateHeaders(options?.token) }
    const result = await fetch(url, o);

    if (result.status < 400) {
      return result;
    } else {
      throw { errors: [result.toString()], status: result.status }; // eslint-disable-line
    }

  },
  memberUpload: async (action: string, formData: FormData, resource: any, schema: ResourceSchema, options?: ResourceServiceOptions) => {

    const schemaPath = pathForSchema(schema, resource.id, options);
    const url = urlWithApiPath(`${schemaPath}/${action}`);
    // note: allows flexibility through options, but preserves existing meberAction default of PUT

    var headers = generateHeaders(options?.token, formData);

    const o = {
      body: formData,
      headers,
      method: 'POST'
    }

    const result = await fetch(url, o);

    if (result.status < 400) {
      return result;
    } else {
      const json = await result.json();
      throw json;
    }
  },
  downloadFile: async (url: string, contentType: string, options?: ResourceServiceOptions) => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': contentType,
        'Authorization': `Bearer ${options?.token}`
      }
    });

    if (response.status < 400) {
      return await response.blob()
    } else {
      const json = await response.json();
      throw json;
    }
  },

  navUrlForResources,
  pathForSchema,
  hubPathForSchema,
  carePathForSchema,
  urlWithPath,
  urlWithApiPath
};

export default ResourceService;
