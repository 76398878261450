import { useState, useEffect } from 'react';
import { useResourceContext } from '../context/ResourceProvider';
import { useHistory, Redirect } from 'react-router-dom'
import { Button, Grid, Box, Typography, makeStyles, Theme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import LabItems from './LabItems'

import ProductVariant from '../models/ProductVariant'
import { parentedSchema } from '../services/ResourceService'
import Order, { OrderSchema } from '../models/Order'
import LineItem from '../models/LineItem';
import { UserSchema } from '../models/User'

import { UserRole, useUserContext } from '../context/UserContext'
import OrderIntent from '../models/OrderIntent';

const useStyles = makeStyles((theme: Theme) => {
  return {
    box: {
      [theme.breakpoints.down('md')]: {
        padding: '120px 18px',
        width: 'calc(100% - 36px)'
      },
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 256px)',
        padding: 128
      }
    }
  }
})

interface CartProps {
  orderIntent: OrderIntent;
  saveOrderIntent: (orderIntent: OrderIntent) => Promise<OrderIntent>;
}

const sumTotal = (acc: number, b: ProductVariant) => acc + b.price;

const Cart = (props: CartProps) => {
  const styles = useStyles()

  const { createOrUpdate } = useResourceContext();
  const history = useHistory();
  const { userRole, user, handleLogin } = useUserContext();
  const { orderIntent, saveOrderIntent } = props;

  const [total, setTotal] = useState(0);

  const items = orderIntent.variants;

  useEffect(() => {
    setTotal(items && items.filter(Boolean).reduce(sumTotal, 0));
  }, [items]);



  const removeLineItem = (lineItem: LineItem) => {
    const variants = items.filter(item => item.id !== lineItem.variant?.id);
    const variantIds = variants.map((v) => v.id);
    const oi = { ...orderIntent, variantIds };
    saveOrderIntent(oi);
  }

  const lineItemsFromVariants = (variants: ProductVariant[]) => {
    return variants.map((variant) => {
      return {
        price: variant.price,
        variantId: variant.id,
        variant: variant
      }
    })
  }

  const handleCreateOrder = async () => {
    if (!user) { throw new Error("Need a user"); } //refactor this

    const lineItems = lineItemsFromVariants(items);

    const payload: Order = {
      subTotal: total,
      totalAmount: total,
      lineItems: lineItems,
      orderIntent: orderIntent, //TODO: hook this up
      user: user
    }

    try {
      const result = await createOrUpdate(
        payload,
        parentedSchema(OrderSchema, UserSchema),
        { parentResourceId: user.id }
      )

      history.push(`/care/orders/${result?.id}`)
    } catch (err: any) {
      console.warn(err.message || 'trouble saving order.')
    }
  }

  const lineItems = lineItemsFromVariants(items);

  // If logged in and the orderIntent alreaady has an order, redirect to the order
  // (that's kind of the point of Cart)
  if (userRole >= UserRole.CUSTOMER && orderIntent.orderId) {
    return <Redirect to={`/care/orders/${orderIntent.orderId}`} />
  }

  return (
    <Box className={styles.box}>
      <Grid
        container
        alignItems="flex-start"
        spacing={1}
        direction="row"
      >
        <Grid item xs={12}>
          {/* TODO MAKE THIS URL USE QA PARAMS */}
          <Button
            startIcon={<ArrowBackIcon />}
            href='https://testing.com'
            style={{ marginRight: 10 }}
          >
            Back to testing.com
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h4" style={{ flex: 1, color: '#b20909', fontWeight: 'bolder' }}>
            Your Shopping Cart
          </Typography>
        </Grid>


        <LabItems lineItems={lineItems} removeLineItem={removeLineItem} editing={true} />

        {userRole === UserRole.GUEST && (
          <Grid item xs={12} style={{ alignItems: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={() => handleLogin({ returnTo: `/shop/cart/${orderIntent.id}` })}>Log In to checkout</Button>
          </Grid>
        )}

        {(userRole >= UserRole.CUSTOMER) && (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className="call-to-action"
              onClick={handleCreateOrder}
              variant="contained" color="primary"
              disabled={items.length === 0}
            >
              Proceed to Checkout
            </Button>

          </Grid>
        )}

      </Grid>
    </Box>
  )
}

export default Cart
