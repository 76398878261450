import { useCallback, useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { CircularProgress, Grid } from '@material-ui/core';
import { OrderSchema } from '../../../models/Order';
import { useResourceContext } from '../../../context/ResourceProvider';
import { parentedSchema } from '../../../services/ResourceService';
import PaymentTransaction, { PaymentTransactionSchema } from '../../../models/PaymentTransaction';
import { AddressSchema } from "../../../models/Address";
import StripeCardElement from './StripeCardElement'
import PaymentButton from '../PaymentButton'
import PaymentProps from '../PaymentProps'
import { useMutableResourceContext } from '../../../context/MutableResourceProvider';


export default function CheckoutForm({
  billing,
  captureShipping = false,
  cardElement,
  hidePostalCode = false,
  onCardComplete,
  onSuccess,
  onError,
  order,
  showButton = true,
  showCard = true,
  shipping,
  error
}: PaymentProps) {
  const { read, createOrUpdate } = useResourceContext();
  const { resource, setResource } = useMutableResourceContext();

  const [clientSecret, setClientSecret] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [element, setElement] = useState(cardElement)
  const [processing, setProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const getIntent = useCallback(
    async () => {
      // Create PaymentIntent as soon as the page loads //-jj or not
      const payment_intent = await read(
        'stripe',
        parentedSchema(PaymentTransactionSchema, OrderSchema),
        { parentResourceId: order.id }
      )

      setClientSecret(payment_intent.clientSecret);
    },
    [order]) // eslint-disable-line

  useEffect(() => {
    var r = { ...resource, processing: processing ? true : undefined };
    setResource(r);
  }, [processing]); // eslint-disable-line

  useEffect(() => {
    if (showButton && order && order.id) {
      getIntent()
    } else if (showCard && showButton && order.id) {
      //possible getIntent usage for hub
    }
  }, [order, showButton]) // eslint-disable-line

  useEffect(() => {
    if (cardElement && !element) {
      setElement(cardElement)
      setDisabled(false)
    }

    if (!cardElement) {
      setElement(null);
      setDisabled(true)
    }

  }, [cardElement, setElement]) // eslint-disable-line

  const handleCardElementChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    onError(event.error ? event.error.message : "");

    if (typeof onCardComplete === 'function') {
      if (event.complete) {
        onCardComplete(elements!.getElement(CardElement))
      } else {
        onCardComplete(null);
      }
    }
  };

  const handleSubmit = async () => {
    if (!order.user) { return; }

    setDisabled(true)
    setProcessing(true);

    if (!clientSecret) {
      console.warn('client secret is not set')
      return
    }

    const card = element ? element : elements!.getElement(CardElement);
    const stripeBilling = AddressSchema.adaptor.toStripe(billing, order.user);

    const stripeShipping = shipping ? AddressSchema.adaptor.toStripe(shipping, order.user) : undefined;

    // const _shipping = captureShipping ? transformAddress(shipping) : transformAddress(billing)
    // const _billing = transformAddress(billing)

    const payload = {
      payment_method: {
        billing_details: stripeBilling,
        card
      },
      shipping: captureShipping ? stripeShipping : stripeBilling
    }

    const results = await stripe!.confirmCardPayment(
      clientSecret,
      payload
    );
    if (results.error) {
      onError(results.error.message || '');
      setProcessing(false);
    } else {
      const pt = { vendor: 'Stripe', confirmationNumber: results['paymentIntent']['id'], rawResponse: results } as PaymentTransaction;
      await createOrUpdate(pt, parentedSchema(PaymentTransactionSchema, OrderSchema), { parentResourceId: order.id });

      setProcessing(false);
      onSuccess()
    }
  };

  const buttonText = processing ? "Submitting..." : "Place Order"

  return (
    <Grid container item xs={12} spacing={4}>
      {showCard && (
        <Grid item xs={12}>
          <StripeCardElement
            onChange={handleCardElementChange}
            hidePostalCode={hidePostalCode}
            error={error}
          />
        </Grid>
      )}
      {processing && <CircularProgress />}
      {showButton && !processing && (
        <Grid item xs={12}>
          <PaymentButton
            disabled={disabled}
            onClick={handleSubmit}
            text={buttonText}
          />
        </Grid>
      )}
    </Grid>
  );
}
