import { useState, useEffect } from 'react';
import { Typography, CircularProgress, Grid } from '@material-ui/core';
import Order, { OrderSchema } from '../models/Order';

import { useResourceContext } from '../context/ResourceProvider';
import ResourceService from '../services/ResourceService';
import { PwnOrderSchema } from '../models/PwnOrder';
import { Link } from 'react-router-dom';
import formatCurrency from '../utils';
import { ProductVariantSchema } from '../models/ProductVariant';
import { useMutableResourceContext } from '../context/MutableResourceProvider';

const LineItemOptions = () => {
  const { resource } = useMutableResourceContext();

  const { read } = useResourceContext();
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    const loadOrder = async () => {
      const resultOrder = await read(resource.order.id, OrderSchema);
      setOrder(resultOrder);
    };
    loadOrder();
  }, [resource.order]) // eslint-disable-line



  if (!order || !resource) { return <CircularProgress /> }
  return (
    <>
      <Grid container spacing={1}>
        <Grid item container spacing={1}>
          <Grid item xs={4}>
            <Typography variant='body1' align='right'><strong>Test:</strong></Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1'> {resource.variant.name}</Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={4}>
            <Typography variant='body1' align='right'><strong>SKU:</strong></Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1'> {resource.variant.sku}</Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={4}>
            <Typography variant='body1' align='right'><strong>Price:</strong></Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1'> {formatCurrency(resource.price)}</Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={4}>
            <Typography variant='body1' align='right'><strong>Req. ID:</strong></Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1'> {resource.pwnOrder?.externalOrderId}</Typography>
          </Grid>
        </Grid>

        {(resource.lastError) && (
          <Grid item container spacing={1}>
            <Grid item xs={4}>
              <Typography variant='body1' align='right'><strong>Last Error:</strong></Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='body1'> <code>{resource.lastError}</code></Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* {order.paymentTransaction && (
        <>
          <Button variant="outlined" onClick={() => setRefunding(true)}>Refund Item</Button>
          <Dialog open={refunding}>
            <DialogContent>
              <Typography>Feature still under dev</Typography>
              <Refund disabled={false} paymentTransactionId={order.paymentTransaction.id} amount={resource.price} lineItemIds={[resource.id]} />
            </DialogContent>
          </Dialog>
        </>
      )}*/}
      <Grid container spacing={2}>
      {resource.pwnOrderId && (
        <Grid item>
          <Link to={ResourceService.hubPathForSchema(PwnOrderSchema, resource.pwnOrderId)}>Pwn Order</Link>
        </Grid>
      )}
        <Grid item>
          <Link to={ResourceService.hubPathForSchema(ProductVariantSchema, resource.variant.id)}>Variant</Link>
        </Grid>
      </Grid>
    </>
  )
};

export default LineItemOptions;
