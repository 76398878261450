import { useEffect, useState } from 'react';
import Product, { ProductSchema } from "../models/Product";
import { useResourceContext } from '../context/ResourceProvider';
import { Box } from '@material-ui/core';

const DevNotes = () => {
  const [products, setProducts] = useState<Product[]>();
  const { index } = useResourceContext();

  const scriptPath = `${process.env.REACT_APP_HUB_API_DOMAIN}/scripts/shopping_cart.js`;
  const script = `<script type='text/javascript' src='${scriptPath}'></script><span id='shopping-cart-container'></span>`;

  async function loadVariants() {
    const p = await index(ProductSchema);
    setProducts(p);
  }

  useEffect(() => {
    loadVariants();
  }, []); // eslint-disable-line

  return (
    <div>
      <p>Embed this script on an html page for a shopping cart widget:</p>
      <Box p={8} style={{ backgroundColor: '#EEE', fontSize: 12 }}>
        <code>
          {`<html><body>  ${script}`}
          <br />
          {products?.map((product: Product) => product.embedCode)}
          <br />
          {` <body></html>`}
        </code>
      </Box>
    </div>
  )
}

export default DevNotes;
