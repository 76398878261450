import IconButton from '@material-ui/core/IconButton';

import { Theme, useTheme, makeStyles, List, ListItem, ListItemText } from '@material-ui/core';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

type Props = {
  open: boolean;
  setOpen: any;
};

const Sidebar = ({ open, setOpen }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const url = window.location.href;
  const isCurrent = (path: string) => url.indexOf(path) > -1;

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => setOpen(false)}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <Link to="/hub/products" style={{ textDecoration: 'none', color: 'grey', fontWeight: 400 }}>
          <ListItem selected={isCurrent('/hub/products')}>
            <ListItemText primary="Products" />
          </ListItem>
        </Link>
        <Link to="/hub/labs" style={{ textDecoration: 'none', color: 'grey', fontWeight: 400 }}>
          <ListItem selected={isCurrent('/hub/labs')} >
            <ListItemText primary="Labs" />
          </ListItem>
        </Link>
        <Link to="/hub/variants" style={{ textDecoration: 'none', color: 'grey', fontWeight: 400 }}>
          <ListItem selected={isCurrent('/hub/variants')} >
            <ListItemText primary="Variants" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/hub/users" style={{ textDecoration: 'none', color: 'grey', fontWeight: 400 }}>
          <ListItem selected={isCurrent('/hub/users')} >
            <ListItemText primary="Users" />
          </ListItem>
        </Link>
        <Link to="/hub/orders" style={{ textDecoration: 'none', color: 'grey', fontWeight: 400 }}>
          <ListItem selected={isCurrent('/hub/orders')} >
            <ListItemText primary="Orders" />
          </ListItem>
        </Link>
        <Link to="/hub/pwn_orders" style={{ textDecoration: 'none', color: 'grey', fontWeight: 400 }}>
          <ListItem selected={isCurrent('/hub/pwn_orders')} >
            <ListItemText primary="PWN Orders" />
          </ListItem>
        </Link>
        <Link to="/hub/discounts" style={{ textDecoration: 'none', color: 'grey', fontWeight: 400 }}>
          <ListItem selected={isCurrent('/hub/discounts')} >
            <ListItemText primary="Discounts" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/hub/integrations" style={{ textDecoration: 'none', color: 'grey', fontWeight: 400 }}>
          <ListItem selected={isCurrent('/hub/integrations')} >
            <ListItemText primary="System Integrations" />
          </ListItem>
        </Link>
      </List>
    </Drawer >
  );
};

export default Sidebar;
