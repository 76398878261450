import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentProps from '../PaymentProps';
import StripeCheckoutForm from "./StripeCheckoutForm";

const promise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

export default function StripePayment(props: PaymentProps) {
  return (
    <Elements stripe={promise}>
      <StripeCheckoutForm {...props} />
    </Elements>
  );
}
