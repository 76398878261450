import { Grid, Typography, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core';
import formatCurrency from '../utils'
import DeleteIcon from '@material-ui/icons/Delete';
import { EditProps } from './EditCard';
import LineItem from '../models/LineItem';
import ShopCard from './ShopCard';
import ConfirmDialog from './ConfirmDialog';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    rootGrid: {
      [theme.breakpoints.down('md')]: {
        '& .MuiTypography-h6': {
          margin: 0
        }
      },
      [theme.breakpoints.up('md')]: {
        '& .MuiTypography-h6': {
          margin: 20
        }
      },
    },
    card: {
      width: "100%",
      minWidth: "100%",
      // marginTop: "1rem", //this margin was killing me --jj
      marginBottom: "1rem",
      "& .card-header": {
        textTransform: "Capitalize",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      },
      "& .card-header-secondary": {
        textTransform: "Capitalize",
        background: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText
      },
      "& .card-content": {
        minHeight: "2rem",
        "& .content-item": {
          borderBottom: `1px solid ${theme.palette.primary.light}`
        },
        "& .content-item-no-border": {
          borderBottom: "none"
        }
      }
    },
    container: {
      "& .card:first-child": {
        marginTop: "4px"
      }
    }
  }
});

interface Props {
  lineItems: LineItem[];
  removeLineItem?: (lineItem: LineItem) => void;
}

const LineItemRows = ({ lineItems, removeLineItem }: Props) => {
  const label = lineItems.length === 1 ? 'Item' : 'Items';

  return (
    <>
      <p style={{ fontWeight: 'bolder', margin: '1em 16px 2em' }}>
        {lineItems.length} {label}
      </p>
      {lineItems.map((lineItem: LineItem) => <LineItemRow lineItem={lineItem} removeLineItem={removeLineItem} />)}
    </>
  );

}



type LineItemRowProps = {
  lineItem: LineItem;
  removeLineItem?: (lineItem: LineItem) => void;
};

const LineItemRow = ({ removeLineItem, lineItem }: LineItemRowProps) => {

  const [pendingRemovalItem, setPendingRemovalItem] = useState<LineItem | undefined>();

  const onConfirmRemoveLineItem = () => {
    if (pendingRemovalItem && removeLineItem) { removeLineItem(pendingRemovalItem); }
    setPendingRemovalItem(undefined);
  }

  const onCancelRemoveLineItem = () => {
    setPendingRemovalItem(undefined);
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ flex: '0 0 auto', minWidth: '18px' }}>
          {removeLineItem && <IconButton style={{ marginTop: "-15px" }} color="default" onClick={() => setPendingRemovalItem(lineItem)}><DeleteIcon /></IconButton>}
        </div>
        <div style={{ flex: '1 1 auto' }}>
          <p style={{ fontWeight: 'bolder', margin: '0 0 6px' }}>
            {lineItem.variant?.name}
          </p>
          <p style={{ color: '#6c6c6c', margin: '0 0 2em', fontSize: '.875rem' }}>{lineItem.variant?.lab?.name}</p>
        </div>
        <Typography style={{ flex: '0 0 auto', marginRight: '1em' }} color="textSecondary">
          {formatCurrency(lineItem.variant?.price)}
        </Typography>
      </div>
      <ConfirmDialog message={`Are you sure you want to remove ${pendingRemovalItem?.variant?.name} from your cart?`} open={Boolean(pendingRemovalItem)} onConfirm={onConfirmRemoveLineItem} setOpen={onCancelRemoveLineItem} />
    </>
  )
}

const LabItems = (props: EditProps & Props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.rootGrid}>
      <ShopCard title="Order Summary">
        <LineItemRows {...props} />
      </ShopCard>
    </Grid>
  )
}

export { LineItemRows }
export default LabItems;
