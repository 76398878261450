import { useUserContext } from '../context/UserContext';
import { Paper, Button, Grid } from '@material-ui/core';

interface Props {
  message?: string
}

const AuthGateway = ({ message }: Props) => {
  const { handleLogin } = useUserContext();

  return (
    <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
      <Grid item xs={10} md={3}>
        <Paper>
          <Grid container direction="column" alignItems="center" style={{ padding: '2em', textAlign: 'center' }} spacing={3}>
            <Grid item>
              <h3>You need to sign in</h3>
            </Grid>
            <Grid item>
              {message && <div>{message}</div>}
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => handleLogin({ returnTo: window.location.pathname })}>Sign In</Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>

  )
}

export default AuthGateway;
