
import { Grid, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { ResourceOptionProps } from './ResourceOptions';
import MoneyField from './MoneyField';
import { useMutableResourceContext } from '../context/MutableResourceProvider';
import { PushDetailButtonWithCount } from './PushDetailButton';

import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Alert } from '@material-ui/lab';


const DiscountOptions = ({ handleChange, handleDateChange }: ResourceOptionProps) => {

  const { ogResource, resource, validationErrors } = useMutableResourceContext();
  const needsSave = !ogResource || Object.keys(ogResource).length <= 0;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>

      <Grid container spacing={3}>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel component="legend">Discount Type</FormLabel>
            <RadioGroup aria-label="valueType" name="valueType" value={resource.valueType} onChange={handleChange} row>
              <FormControlLabel value="flat" control={<Radio />} label="Order Amount" />
              <FormControlLabel value="percentage" control={<Radio />} label="Order Percentage" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField label="Code" name="code" value={resource.code} onChange={handleChange} />
          {resource.valueType === 'flat' &&
            <MoneyField label="Amount" name="amount" value={resource.amount} onChange={handleChange} error={validationErrors?.amount} />
          }

          {resource.valueType === 'percentage' &&
            <TextField type='number' label="Percentage" name="percentOff" value={resource.percentOff} onChange={handleChange} />
          }

        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel component="legend">Entitlements</FormLabel>
            <RadioGroup aria-label="customerTargetType" name="customerTargetType" value={resource.customerTargetType} onChange={handleChange} row>
              <FormControlLabel value="entitled" control={<Radio />} label="Entitled Customers" />
              <FormControlLabel value="all" control={<Radio />} label="All Users" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormLabel component="legend">Start Date</FormLabel>

          <DatePicker
            name='startsAt'
            value={resource.startsAt}
            onChange={(date: any) => handleDateChange(date, 'startsAt')}
            autoOk
          />

        </Grid>

        <Grid item xs={3}>
          <FormLabel component="legend">End Date</FormLabel>

          <DatePicker
            name='endsAt'
            value={resource.endsAt}
            onChange={(date: any) => handleDateChange(date, 'endsAt')}
            autoOk
          />

        </Grid>

        <Grid item xs={6}>

          {needsSave && resource.customerTargetType === 'entitled' && (
            <Alert severity="info">Save this discount to manage users</Alert>
          )}
          {!needsSave && resource.customerTargetType === 'entitled' && (
            <PushDetailButtonWithCount link={{ path: 'users', cta: 'Manage Users' }} resource={resource} />
          )}
        </Grid>

      </Grid>
    </MuiPickersUtilsProvider>
  )
};

export default DiscountOptions;
