type Auth0Config = {
  domain: string;
  clientId: string;
  audience: string;
}


const getAuth0Config = () => {

  const domain = process.env.REACT_APP_STACK === 'prod' ? process.env.REACT_APP_AUTH0_DOMAIN_PROD : process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_STACK === 'prod' ? process.env.REACT_APP_AUTH0_CLIENT_ID_PROD : process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!domain || !clientId || !audience) {
    throw new Error("Missing auth0 config domain, clientId or audience");
  }

  return { domain, clientId, audience } as Auth0Config;

}

export { getAuth0Config };
export default Auth0Config;
