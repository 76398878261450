import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core';
import { EditProps } from './EditState';
import LineItem, { LineItemsSchema } from '../models/LineItem';
import { LineItemRows } from './LabItems';
import { useMutableResourceContext } from '../context/MutableResourceProvider';
import { useResourceContext } from '../context/ResourceProvider';


const useStyles = makeStyles((theme: Theme) => {
  return {
    rootGrid: {
      [theme.breakpoints.down('md')]: {
        '& .MuiTypography-h6': {
          margin: 0
        }
      },
      [theme.breakpoints.up('md')]: {
        '& .MuiTypography-h6': {
          margin: 20
        }
      },
    },
    card: {
      width: "100%",
      minWidth: "100%",
      // marginTop: "1rem", //this margin was killing me --jj
      marginBottom: "1rem",
      "& .card-header": {
        textTransform: "Capitalize",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      },
      "& .card-header-secondary": {
        textTransform: "Capitalize",
        background: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText
      },
      "& .card-content": {
        minHeight: "2rem",
        "& .content-item": {
          borderBottom: `1px solid ${theme.palette.primary.light}`
        },
        "& .content-item-no-border": {
          borderBottom: "none"
        }
      }
    },
    container: {
      "& .card:first-child": {
        marginTop: "4px"
      }
    }
  }
});

interface Props {

}

const LineItems = (props: EditProps & Props) => {
  const classes = useStyles();
  const { setEditing } = props;


  const { resource, loadResource } = useMutableResourceContext();
  const { destroy } = useResourceContext();

  const order = resource;
  const hasTransaction = order && order.paymentTransaction;

  const lineItems = order.lineItems;

  const confirmRemoval = async (lineItem: LineItem) => {
    // if (resource.pendingRemovalLineItem) {
    if (!lineItem.id) { throw new Error("Cannot confirm removal of line item with no id") };

    const result = await destroy(lineItem.id, LineItemsSchema);
    if (result) {
      loadResource(true);
      setEditing(false);
    }
  }


  return (
    <Grid item xs={12} className={classes.rootGrid}>
      <LineItemRows lineItems={lineItems} removeLineItem={hasTransaction ? undefined : confirmRemoval} />
    </Grid>
  )
}

export default LineItems;
