import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

interface Props {
  open: boolean
  handleClose: () => void
}
export default function PWNTerms({ open, handleClose }: Props) {

  return (
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>PWNHealth Terms &amp; Conditions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p><span>NOTE: PWNHealth Provides Health Testing Centers with physician oversight in order for us to order the labs for our patients and customers.  This informed consent describes your rights and options as it relates to working with PWNHealth. </span></p>
            <p><span></span></p>
            <p><span></span><b><span>Informed Consent<p></p></span></b></p>
            <p><span><p> </p></span></p>
            <p><b><span>BY CLICKING “I ACCEPT,” YOU ACKNOWLEDGE THAT YOU HAVE READ, ACCEPTED, AND AGREED TO BE BOUND BY THIS INFORMED CONSENT.  IF YOU DO NOT CLICK “I ACCEPT”, YOU WILL NOT BE ABLE TO USE OR RECEIVE THE SERVICES.<p></p></span></b></p>
            <p><span></span><b><span>General Informed Consent<p></p></span></b></p>
            <p><span><p> </p></span></p>
            <p><span>I agree to receive the <span >services provided by PWNHealth (the administrative services provider of the professional entities), PWN Remote Care Services, PW Medical Professional and certain other affiliated professional entities (collectively, “PWNHealth”, “we” or “us”) relating to ordering laboratory tests (“Tests”), including, without limitation, ordering of Tests, test review services, testing, receipt of Test results (“Results”), physician consultations via telemedicine (“Consults”), any customer support or counseling and any other related services provided by PWN or its service providers and partners (the “Services”). </span>All clinical Services, including Services provided by physicians, will be provided through PWN Remote Care Services, PW Medical Professional or their affiliated professional entities.<p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>If you have ordered an HIV Test (inclu</span><span>ding as part of a panel), please also review the Informed Consent to Perform HIV Testing immediately following this General Informed Consent.<p></p></span></p>
            <p><span> </span></p>
            <p><span>I acknowledge and agree to the following:<p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>●<span>      </span></span><span>I am the individual who will provide the sample for the Test(s) that I am ordering.<p></p></span></p>
            <p><span>●<span>      </span></span><span>I am at least eighteen (18) years of age. <p></p></span></p>
            <p><span>●<span>      </span></span><span>I have read and understand the information provided about the Test(s) that I am </span><span>requesting at the website where I am requesting the Test(s) . <p></p></span></p>
            <p><span>●<span>      </span></span><span>In order to utilize the Services, I must provide an appropriate sample for the Test(s), which may include a blood, urine, saliva or other sample.<p></p></span></p>
            <p><span>●<span>      </span></span><span>The information I have provided in connection with the Services is correct to the best of my knowledge. I will not hold PWN or its health care providers responsible for any errors or omissions that I may have made in providing such information.</span><span><p></p></span></p>
            <p><span>●<span>      </span></span><span>My health information and results may be shared with other PWN health care providers, including physicians, and counselors for purposes of providing care to me.<p></p></span></p>
            <p><span>●<span>      </span></span><span>The Services do not constitute treatment or diagnosis of any condition, disease or illness, except for Consults for Treatment Conditions as described below.<p></p></span></p>
            <p><span>●<span>      </span></span><span>While PWN and the laboratories implement safeguards to avoid errors, as with all laboratory tests, there is a chance of a false positive or false negative result.  </span><span><p></p></span></p>
            <p><span>●<span>      </span></span><span>I agree to contact <span >the PWNHealth's Care Coordination Team if I do not receive results within ten (10) days after I provided a sample at the lab testing center.</span><p></p></span></p>
            <p><span>●<span>      </span></span><span>I am responsible for checking my email for results notification and logging on to my account to view my results when available. <p></p></span></p>
            <p><span>●<span>      </span></span><span>If I receive an abnormal result on a Test, I understand that PWNHealth's Care Coordination Team will attempt to call me to review the results, offer education and explain the next steps I should take.  PWNHealth’s Care Coordination Team may leave me a voicemail but will not include my test results in any voicemail message.  I also understand that if I am not able to be reached, PWNHealth's Care Coordination Team will mail  a follow-up letter to the residential address I provided when I purchased my test (the letter will not include my test results).  If I receive an abnormal result and have not connected with PWNHealth’s Care Coordination Team, I understand that I should not delay following up with my personal physician.<p></p></span></p>
            <p><span>●<span>      </span></span><span>I understand that after receiving my Results, I will have the opportunity for a telemedicine Consult with a PWNHealth physician. If my Results show that I have Chlamydia, Gonorrhea, HSV 2, or Trichomoniasis (the “Treatment Conditions”), the physician may be able to prescribe medication during the Consult, if appropriate.  I understand that if my Results show that I have one of the Treatment Conditions, it is important to schedule a Consult as soon as possible or obtain other treatment.<p></p></span></p>
            <p><span>●<span>      </span></span><span>I certify that throughout the duration of the Services I receive, including my Consult, I will be physically present in the state of the patient service center I selected, or if I did not select a patient service center, the state of residence I provided or other state of which I have notified PWNHealth.</span><span><p></p></span></p>
            <p><span>●<span>      </span></span><span>I am responsible for forwarding any results to my primary care or other personal physician and for initiating follow up with such physician for care, diagnosis, medical treatment or to obtain an interpretation of the Results.<p></p></span></p>
            <p><span>●<span>      </span></span><span>I will not make medical decisions without consulting a healthcare provider or disregard medical advice from my healthcare provider or delay seeking such advice based on information as a result of the use of the Services.<p></p></span></p>
            <p><span>●<span>      </span></span><span>If I receive an ab</span><span>normal result on certain STD Tests, my name and result will be disclosed to my state health agency in accordance with applicable law. <p></p></span></p>
            <p><span>●<span>      </span></span><span>If I receive an abnormal result on an STD Test, it is important that I notify my <span >sexual and needle sharing partners and follow up with my personal physician to receive treatment.</span><p></p></span></p>
            <p><span></span><span><p> </p></span></p>
            <p><span>I understand that Services, including Consults, are delivered by health care providers who are not in the same physical location as I am using electronic communications, information technology or other means, including the electronic transmission of personal health information.    I also understand that:<p></p></span></p>
            <p><span>●<span>      </span></span><span>A PWN </span><span>physician will determine whether or not Test(s) and Services, including any treatment, are appropriate for me.<p></p></span></p>
            <p><span>●<span>      </span></span><span>For Consults for Treatment Conditions, the scope of services will be at the sole discretion of the physician treating me, with no guarantee of diagnosis, treatment, or prescription. The physician will determine whether or not the condition being diagnosed and/or treated or the Services being rendered are appropriate for a telehealth encounter.<p></p></span></p>
            <p><span>●<span>      </span></span><span>I have the right to withdraw my consent to the use of telehealth in the course of my care at any time by contacting the <span >PWNHealth's Care Coordination Team by calling </span>888-362-4321 or emailing patientservices@pwnhealth.com.<p></p></span></p>
            <p><span>●<span>      </span></span><span>Any video feed from the Consult will not be retained or recorded by PWNHealth.<p></p></span></p>
            <p><span>●<span>      </span></span><span>My health and wellness information pertaining to telehealth services are governed by the </span><span>PWNHealth Terms of Use and PWNHealth Notice of Privacy Practices.<p></p></span></p>
            <p><span>●<span>      </span></span><span>I may need to see </span><span>a health care provider in-person for diagnosis, treatment and care. <p></p></span></p>
            <p><span>●<span>      </span></span><span>There are potential risks associated with the use of technology, including disruptions, loss of data and technical difficulties.<p></p></span></p>
            <p><span>●<span>      </span></span><span>There are alternative ser</span><span>vices, such as visiting a primary care provider, an emergency room, or an urgent care facility; however, I chose to proceed with the Services at this time.<p></p></span></p>
            <p><span></span><span><p> </p></span></p>
            <p><span>I understand that if I have any questions before or after my Test, I can contact PWNHealth's Care Coordination Team by calling by calling </span><span>888-362-4321 or emailing patientservices@pwnhealth.com</span><span>.<p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>I authorize PWN to use the email address and phone number I provided in connection with my account at the time I purchased my Test(s) (or that I updated by contacting </span><span>PWNHealth's Care Coordination Team as</span><span> described below) to contact me in connection with the Services, including followup after a Consult.    I am responsible for contacting </span><span>PWNHealth's Care Coordination Team by calling by calling </span><span>888-362-4321 or emailing patientservices@pwnhealth.com</span><span> to notify them of any changes to </span><span>my mailing address, email address, phone number or other information that I provided in connection with the Services.  <p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>I understand that testing is voluntary and that I may withdraw my consent to testing at any time prior to the completion of the Test(s) by <span >contacting PWNHealth's Care Coordination Team by </span><span >calling </span>888-362-4321 or emailing patientservices@pwnhealth.com<span >.<p></p></span></span></p>
            <p><span><p> </p></span></p>
            <p><u><span>Data Authorization</span></u><span><p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>I specifically authorize the transfer and release of my information as described herein and in the Notice of Privacy Practices available to me when seeking and purchasing the Services, including my lab test Results and other identifiable health information, submitted by me or about me in connection with the Services, to, between and among myself and the following individuals, organizations and their representatives: (a) the company through which I purchased the applicable laboratory test and its affiliates, their staff and agents; (b) PWNHealth and its affiliates, and their staff, agents, and health care providers, including physicians, and (c) the laboratory conducting the laboratory testing services to facilitate and execute the Services requested by me or performed with my consent (including receiving, reviewing and approving a laboratory request; reviewing, processing and delivering the laboratory test value(s)/result(s)), and as required or permitted by law.<p></p></span></p>
            <p><span> <p></p></span></p>
            <p><span>I understand that I have a right to receive a copy of the above data disclosure authorization.  I have the right to refuse to agree to this authorization in which case my refusal may affect the Services provided to me.  When my information is used or disclosed pursuant to this authorization, it may be subject to re-disclosure by the recipient and may no longer be protected by privacy laws.  I have the right to revoke this authorization in writing at any time, except that the revocation will not apply to any information already disclosed by the parties referenced in this authorization.  This authorization will expire ten (10) years from the date of signature.   My written revocation must be submitted to PWNHealth’s General Counsel at:<p></p></span></p>
            <p><span> <p></p></span></p>
            <p><span>PWN Remote Care Services<p></p></span></p>
            <p><span>c/o PWNHealth, LLC<p></p></span></p>
            <p><span>Attn: General Counsel<p></p></span></p>
            <p><span>123 West 18<sup>th</sup> Street, 8<sup>th</sup> Floor<p></p></span></p>
            <p><span>New York, NY 10011<p></p></span></p>
            <p><span></span><span><p> </p></span></p>
            <p><span>I have read this Informed Consent carefully, and all my questions were answered to my satisfaction.  I hereby consent to participate in the Services, including the performance of the Test(s) that I have ordered and the Consult, pursuant to the terms, conditions, standards, and requirements set forth herein, in the PWNHealth Terms of Use and PWNHealth Notice of Privacy Practices or as otherwise provided to me.<p></p></span></p>
            <p><span></span><span><p> </p></span></p>
            <p><span><p> </p></span></p>
            <p><b><span>Informed Consent to Perform HIV Testing<p></p></span></b></p>
            <p><b><span>(This Consent Applies Only If You Purchase An HIV Test)</span></b><span><p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>I have been provided with and I understand the following information regarding HIV testing:<p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>●<span>      </span></span><b><span>What is HIV? </span></b><span>HIV stands for human immunodeficiency virus.<span > It's the virus that causes AIDS. </span>It damages your immune system, making it easier for you to get sick<span > and even die from infections or diseases, like cancer, that your body could normally fight off.</span><p></p></span></p>
            <p><span>●<span>      </span></span><b><span>How does the test work? </span></b><span>The HIV test is a blood test that looks for HIV and requires you to provide a sample of your blood. <span >The test is divided into three parts to ensure accuracy. The first test looks for antibodies your body makes when have been infected with HIV, as well as part of the virus itself. If the result of the first part is non-reactive, you are not infected with the HIV virus. If it is reactive, a second test, which looks for antibodies against HIV, is run from the same blood sample. If this test is reactive, it is highly likely you are infected with the HIV virus. If it is non-reactive or indeterminate, a third test is run. This last test looks for RNA, HIV’s genetic material. If this test is positive, then it confirms that there is HIV infection. Another test may be recommended if all three parts of the test are negative but there is still a strong reason to think you have been infected.</span><p></p></span></p>
            <p><span>●<span>      </span></span><b><span>Timing of Testing.</span></b><span>  After being infected with HIV, it takes several weeks and possibly months for blood tests to detect the virus. If you test too soon, the test may be negative even if the virus is in your body. If you think you may have been exposed to HIV in the last 3 days, you should immediately contact a doctor. </span><span>Prompt treatment, also called post-exposure prophylaxis (PEP), within 3 days of a possible exposure, can decrease the chance that you will develop an HIV infection.<p></p></span></p>
            <p><span>●<span>      </span></span><b><span>Test Results and Counseling.  </span></b><span>If your test shows that you have HIV, PWNHealth's Care Coordination Team will call you to review the results and explain the next steps you should take, including where you can get treatment. After your test you will be offered education, counseling, and information regarding your results.<p></p></span></p>
            <p><span>●<span>      </span></span><b><span>Treatment of HIV. </span></b><span>There are treatment options for HIV.  There’s no cure for HIV, but medication can slow down the damage the virus causes. New medications have made it possible for people with HIV to live about as long as they would without HIV. Getting tested means getting treatment that will help you stay healthy longer and lower the chances of spreading the virus to others.<p></p></span></p>
            <p><span>●<span>      </span></span><b><span>Safe Practices.</span></b><span>   Individuals with HIV/AIDS can adopt practices to protect uninfected people from becoming infected.  Use condoms every time you have sex.<p></p></span></p>
            <p><span>●<span>      </span></span><b><span>Partner Notification.</span></b><span>  If you test positive, it is important to notify your <span >sexual and needle sharing partners and </span>many states require that you do so.  There are resources available from your local health department which can help you notify your partners<span >.</span>  Be honest with your future partners so you can both be informed and help each other stay healthy.<p></p></span></p>
            <p><span>●<span>      </span></span><b><span>Further Testing.</span></b><span>  Even if your test results are negative, the CDC recommends yearly testing for some people, including those with multiple sex partners, men who have sex with men, those who use IV drugs, and those who’ve had another STD. Ask your doctor if you should be tested every year.<p></p></span></p>
            <p><span>●<span>      </span></span><b><span>Additional Information.  </span></b><span>Additional information is available at the website where I purchased the Test(s) .<p></p></span></p>
            <p><span></span><span>I understand that if I have any questions before or after the HIV test, I can contact PWNHealth's Care Coordination Team by calling </span><span>by calling </span><span>888-362-4321 or emailing patientservices@pwnhealth.com<span >.<p></p></span></span></p>
            <p><span><p> </p></span></p>
            <p><span>I understand that the testing being offered is confidential but not anonymous. It requires my name and credit card information. Anonymous testing options are available at in-person specialized testing centers.<p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>I understand that the law prohibits discrimination based on an individual’s HIV status.   Services are available if I believe I have experienced discrimination based on my HIV status.<p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>I understand that the law protects the confidentiality of test results.   As required by state law, </span><span>if I am positive for HIV, my name and results will be reported to my state’s health department.  I also understand that my health information and results may be shared with other PWN health care providers, including physicians, and counselors for purposes of providing care to me.</span><span><p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>I understand that testing is voluntary and that I may withdraw my consent to testing at any time prior to the completion of the laboratory test by <span >contacting PWNHealth's Care Coordination Team by calling </span><span >by calling </span>888-362-4321 or emailing patientservices@pwnhealth.com<span >.</span><p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>I have read and understand the information that has been provided to me.  I have been given the opportunity to ask questions about HIV testing and all of my questions have been answered to my satisfaction.<p></p></span></p>
            <p><span><p> </p></span></p>
            <p><span>I have read and understood this Informed Consent for HIV Testing and hereby consent to be tested for HIV pursuant to the terms, conditions, standards, and requirements set forth herein, in the PWNHealth Terms of Use and PWNHealth Notice of Privacy Practices or as otherwise provided to me. <p></p></span></p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
  );
}
