

import Attribute from './Attribute';
import { TextField, TextareaAutosize, Typography, Grid, FormControlLabel, Switch } from '@material-ui/core';
import { ResourceOptionProps } from './ResourceOptions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { UserRole, useUserContext } from '../context/UserContext';
import { useMutableResourceContext } from '../context/MutableResourceProvider';

function BasicAttrs({ handleCheckChange, handleChange }: (ResourceOptionProps)) {
  const { userRole } = useUserContext();
  const { resource } = useMutableResourceContext();

  const canEdit = userRole >= UserRole.ADMIN;

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={4}>
        <TextField label="Name" name="name" value={resource.name}
          onChange={handleChange}
          fullWidth
          disabled={!canEdit} />
      </Grid>

      <Grid item>
        <TextField label="Description" name="description" value={resource.description} onChange={handleChange}
          fullWidth
          multiline={true}
          inputProps={{ maxLength: 250 }}
          disabled={!canEdit}
        />
      </Grid>

      {resource.id && (
        <Grid item>
          <Attribute name="Embed Code" tooltip="Embed this script to show Add-to-Cart buttons for each product variant">
            <br />
            <TextareaAutosize value={resource.embedCode} disabled={true} style={{ minWidth: "300px" }} />
            <CopyToClipboard text={resource.embedCode}
              onCopy={() => alert("Copied embed code to clipboard")}>
              < FileCopyIcon />
            </CopyToClipboard>
          </Attribute>
          <Grid item xs={4}>
            <FormControlLabel
              labelPlacement="start"
              control={<Switch color="primary" name="fastingRequired" checked={Boolean(resource.fastingRequired)} onChange={handleCheckChange} disabled={!canEdit} />}
              label="Fasting Required"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

const ProductOptions = (props: ResourceOptionProps) => {
  return (
    <>
      <Typography>Attributes</Typography>
      <BasicAttrs {...props} />
    </>
  )
};

export default ProductOptions;
