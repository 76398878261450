import { ResourceSchema } from '../services/ResourceService';

type PaymentTransaction = {
  id: string;
  confirmationNumber: string;
  vendor: string;
  rawResponse: any;
};

const PaymentTransactionSchema = {
  apiResponseResourceCollectionKey: 'paymentTransactions',
  apiResponseResourceItemKey: 'paymentTransaction',
  apiRequestPath: '/payment_transactions',
  labels: { name: 'Payment Transaction', pluralName: 'Payment Transactions', describer: (resource) => resource.id },
  columns: [],
  columnsForMobile: [],
  optionHandler: null
} as ResourceSchema;

export { PaymentTransactionSchema };

export default PaymentTransaction;
