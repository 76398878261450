

import { Card, CardHeader, CardContent, makeStyles, Theme, Button, Grid, IconButton } from '@material-ui/core';
import { useMutableResourceContext } from '../context/MutableResourceProvider';
import CloseIcon from '@material-ui/icons/CloseSharp';
import EditIcon from '@material-ui/icons/EditSharp';

export type EditProps = {
  editing?: boolean;
  setEditing?: any;
  children?: any;
  setDisplayOrderCard?: any;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: { background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText },
    headerHighlighted: { background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }
  }
});

type EditCardProps = {
  title: string;
  saveCTA?: string;
  showEditButton?: boolean; //show the edit button
  showCancelButton?: boolean;
  // showSaveButton?: boolean; //show the save button
  children: any;
  customHeaderStyle?: { background: string, color: string }
  saveAction?: any;
}

const EditCard = (props: EditCardProps & EditProps) => {

  const { title, showEditButton = false, showCancelButton = true, editing = false, setEditing, children, customHeaderStyle, saveAction, saveCTA } = props;

  const { saveResource, resourceIsDirty, revertResourceToOG } = useMutableResourceContext();
  const classes = useStyles();

  const toggleFocus = () => {
    if (editing && resourceIsDirty()) {
      revertResourceToOG();
    }

    setEditing && setEditing(!editing);
  }

  const EditAction = () => {
    if (resourceIsDirty()) {
      if (!editing) { return null; }
    } else {
      if (!showEditButton) { return null; }
    }

    if (!showCancelButton) { return null; }
    //Custom height for icon ensures header will be same height as non-icon'd headers
    return <IconButton style={{ width: '40px', height: '40px' }} onClick={toggleFocus}>{editing ? <CloseIcon /> : < EditIcon />}</IconButton>

  }

  const handleSave = async () => {
    if (resourceIsDirty()) {
      const result = await saveResource();
      if (result) {
        setEditing && setEditing(false);
      }

    } else {
      setEditing && setEditing(false);
    }
  }

  return (
    <Card key={`focus-card`} >
      <CardHeader title={title}
        className={editing ? classes.headerHighlighted : classes.header}
        style={customHeaderStyle}
        action={<EditAction />} />
      <CardContent className="card-content">
        {children || null}
        {editing && resourceIsDirty() && (
          <Grid container item direction="row" >
            <Grid item xs={12} md={6}>
              <>
                {saveAction}
                {saveAction === undefined && <Button style={{ height: '60px', marginTop: '12px' }} variant="contained" color="primary" onClick={handleSave}>{saveCTA || `CONFIRM ${title}`}</Button>}
              </>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card >
  )
}

export default EditCard;
