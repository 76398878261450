import OrderSummary from './OrderSummary'
import LineItems from './LineItems';
import Discount from './Discount';
import { Card, CardHeader, makeStyles, Theme, useTheme } from '@material-ui/core';
import { useMutableResourceContext } from '../context/MutableResourceProvider';


const useStyles = makeStyles((theme: Theme) => {
  return {
    headerHighlighted: { background: '#666', color: '#fff' },
    labHeader: { background: theme.palette.secondary.light, color: theme.palette.secondary.contrastText },
    labHeaderHighlighted: { background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }
  }
});

const OrderSummaryCard = ({ editMode, EditMode, handleChangeLabEdit, handleChangeDiscountEdit }: { editMode: any, EditMode: any, handleChangeLabEdit: any, handleChangeDiscountEdit: any }) => {
  const theme = useTheme();
  const { resource } = useMutableResourceContext();
  const order = resource;
  const hasTransaction = order && order.paymentTransaction;
  const title = hasTransaction ? 'Items' : 'Order Summary';

  const classes = useStyles();


  return (
    <Card style={{ background: hasTransaction ? 'inherit' : theme.palette.secondary.main }}>
      <CardHeader title={title} className={hasTransaction ? classes.labHeader : classes.headerHighlighted} />

      <LineItems editing={editMode === EditMode.Labs} setEditing={handleChangeLabEdit} />
      <div style={{ borderBottom: '1px solid #d8d8d8', height: '1px', width: 'calc(100% - 30px)', margin: '0 15px' }} />
      <Discount
        editing={editMode === EditMode.Discount}
        setEditing={handleChangeDiscountEdit}
      />
      <div style={{ borderBottom: '1px solid #d8d8d8', height: '1px', width: 'calc(100% - 30px)', margin: '0 15px' }} />
      <OrderSummary
        order={resource}
        alignItemCount="left"
      />
    </Card>
  );
}

export default OrderSummaryCard;
