import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Hub from './components/Hub';
import Root from './components/Root';
import Shop from './components/Shop'
import Care from './components/Care';
import { getAuth0Config } from './services/Auth0Config';

function App() {

  // Log the env on first mount so we can be sure
  React.useEffect(() => console.info(`App Stack Details: REACT_APP_STACK="${process.env.REACT_APP_STACK}" Auth0Config.domain:`, getAuth0Config().domain), []);

  return (
    <div style={{}}>
      <Router >
        <Switch>
          <Route
            path="/hub">
            <Hub />
          </Route>
          <Route
            // path="/care/orders/:orderId">
            path="/care">
            <Care />
          </Route>
          <Route
            path="/shop">
            <Shop />
          </Route>
          <Route
            path="/">
            <Root />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
