import { useEffect, useState } from 'react';
import { TextField, Grid, FormControlLabel, Switch } from '@material-ui/core';
import { ResourceOptionProps } from './ResourceOptions';
import Lab, { LabSchema } from '../models/Lab';
import RelationSelector from './RelationSelector';
import Product, { ProductSchema } from '../models/Product';
import { Link } from 'react-router-dom';
import MoneyField from './MoneyField';
import { CircularProgress } from '@material-ui/core';
import { useResourceContext } from '../context/ResourceProvider';
import { UserRole, useUserContext } from '../context/UserContext';
import { useMutableResourceContext } from '../context/MutableResourceProvider';

const ProductVariantOptions = ({ handleChange, handleCheckChange, }: ResourceOptionProps) => {

  const { resource, setResource, validationErrors, parentResource, schema } = useMutableResourceContext();
  const parentResourceId = parentResource?.id;

  const [loading, setLoading] = useState(true);
  const { read } = useResourceContext();
  const { userRole } = useUserContext();

  const canEdit = userRole >= UserRole.ADMIN;
  function setResourceLab(lab?: Lab) {
    var r = { ...resource };
    r.lab = lab
    r.labId = lab?.id;
    setResource(r);
  }

  function setResourceProduct(product?: Product) {
    var r = { ...resource };
    r.product = product;
    r.productId = product?.id; //TODO: This is causing the save button to enable (resource is different than og now)
    setResource(r);
  }

  const variantShoppingPath = `/shop/cart?items=${resource.id}`

  const loadParent = async () => {
    if (schema.parent) {

      const parentResource = parentResourceId ? await read(parentResourceId, schema.parent) : null;

      if (schema.parent.apiResponseResourceCollectionKey === 'labs') {
        setResourceLab(parentResource);
      } else if (schema.parent.apiResponseResourceCollectionKey === 'products') {
        setResourceProduct(parentResource);
      }

    }
    // setTimeout(() => {
    setLoading(false);
    // }, 2000);
  }

  useEffect(() => {
    loadParent();
  }, [parentResourceId, schema]); // eslint-disable-line

  if (loading) { return <CircularProgress /> }

  return (
    <Grid container direction="column" spacing={3}>
      {resource.id && (
        <Grid item>
          <TextField label="id" name="id" disabled={true} value={resource.id} fullWidth />
        </Grid>
      )}
      <Grid item>
        <TextField label="Name" name="name" value={resource.name} onChange={handleChange} error={validationErrors?.name} fullWidth
          disabled={!canEdit}
        />
      </Grid>

      <Grid item container direction="row" spacing={1}>
        <Grid item xs={4}>
          <TextField label="SKU" name="sku" value={resource.sku} onChange={handleChange} error={validationErrors?.sku} fullWidth
            disabled={!canEdit}
          />
        </Grid>
        <Grid item xs={4}>
          <MoneyField
            label="Cost"
            name="cost"
            value={resource.cost}
            onChange={handleChange}
            error={validationErrors?.cost}
            fullWidth
            disabled={!canEdit}
          />
        </Grid>
        <Grid item xs={4}>
          <MoneyField
            label="Price"
            name="price"
            value={resource.price}
            onChange={handleChange}
            error={validationErrors?.price}
            fullWidth
            disabled={!canEdit}
          />
        </Grid>
      </Grid>

      <Grid item container direction="row">
        <Grid item xs={6}>
          <RelationSelector relatedResource={resource.lab} setRelatedResource={setResourceLab} schema={LabSchema} disabled={!canEdit}
          />
        </Grid>

        <Grid item xs={6}>
          <RelationSelector relatedResource={resource.product} setRelatedResource={setResourceProduct} schema={ProductSchema} disabled={!canEdit}
          />
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <FormControlLabel
          labelPlacement="start"
          control={<Switch color="primary" name="active" checked={resource.active == null ? true : Boolean(resource.active)} onChange={handleCheckChange} disabled={!canEdit} />}
          label="Active"
        />
      </Grid>

      <Grid item xs={4}>
        <FormControlLabel
          labelPlacement="start"
          control={<Switch color="primary" name="shippable" checked={Boolean(resource.shippable)} onChange={handleCheckChange} disabled={!canEdit} />}
          label="Shippable"
        />
      </Grid>

      {resource.id && (
        <Grid item xs={4}>
          <Link target="_blank" to={variantShoppingPath}>Start Cart for This Variant</Link>
        </Grid>
      )}

    </Grid>
  )
};
export default ProductVariantOptions;
