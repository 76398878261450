import { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import ConfirmDialog from './ConfirmDialog';
import ResourceOptions from './ResourceOptions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ResourceLinks from './ResourceLinks';
import MutableResourceProvider, { useMutableResourceContext } from '../context/MutableResourceProvider';
import { ResourceSchema } from '../services/ResourceService';

type Props = {
  onDidSave?: (resource: any) => void;
  onClose?: () => void;
} & ResourceDetailsDialogProps

const ResourceDetails = (props: Props) => {
  const { resourceId, parentResourceId } = useParams<any>();
  const { url } = useRouteMatch();
  const history = useHistory();

  function handleDidClose() {
    var components = url.split('/');
    components.pop();

    var newPath = components.join('/');

    history.push(newPath);
    props.onClose && props.onClose();
  }

  function handleDidSaveResource(newResource: any) {
    if (resourceId === 'new') {
      var components = url.split('/');
      components.pop();
      components.push(newResource.id);
      var newPath = components.join('/');

      history.replace(newPath);
    }

    props.onDidSave && props.onDidSave(newResource);
  }


  return <ResourceDetailsDialog {...props} resourceId={resourceId} parentResourceId={parentResourceId} onDidSaveResource={handleDidSaveResource} onDidClose={handleDidClose} />

}


type ResourceDetailsDialogProps = {
  parentResourceId?: string | null;
  resourceId?: string;
  schema: ResourceSchema;
  showSaveAndClose?: boolean;
  onDidSaveResource?: (resource: any) => void;
  onDidClose?: () => void;
}

export const ResourceDetailsDialog = ({ schema, resourceId, parentResourceId, onDidSaveResource, onDidClose, showSaveAndClose = false }: ResourceDetailsDialogProps) => {
  function handleClose() {
    onDidClose && onDidClose();
  }

  if (!resourceId) { return <div>Loading Resource</div> }
  return (
    <Dialog open={true} onClose={(handleClose)} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
      <DialogTitle id="form-dialog-title">{schema.labels.name} Details</DialogTitle>

      <MutableResourceProvider resourceIdentifier={resourceId} parentResourceIdentifier={parentResourceId} schema={schema} onDidSaveResource={onDidSaveResource}>
        <ResourceDetailsConsumer onDidClose={handleClose} showSaveAndClose={showSaveAndClose} />
      </MutableResourceProvider>

    </Dialog>
  )

}

const ResourceDetailsConsumer = ({ onDidClose, showSaveAndClose }: any) => {

  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [showActions, setShowActions] = useState(true);

  const { schema, resource, saveResource, resourceLoading, resourceIsDirty, parentResource, validationErrors, destroyResource } = useMutableResourceContext();

  function handleClose() {

    onDidClose && onDidClose();
  }


  async function handleSave(closeOnSave = false) {
    await saveResource();
    if (closeOnSave) {
      handleClose();
    }
  }

  async function handleConfirmDelete() {
    await destroyResource();
    handleClose();
  }

  function formatValidationErrors(errors: any) {
    const e = errors?.errors || errors;
    return Object.entries(e).map((entry: any) => `${entry[0]} ${entry[1]}`).join(', ');
  }

  return (
    <>

      <DialogContent>

        <ResourceOptions setShowActions={setShowActions} />
        {validationErrors && <Alert severity="warning">{formatValidationErrors(validationErrors)}</Alert>}
        <ResourceLinks resource={resource} schema={schema} parentResourceId={parentResource?.id} />
      </DialogContent>
      {showActions && (<DialogActions>
        {resourceLoading && <CircularProgress />}

        <Button onClick={handleClose}>Close</Button>
        {showSaveAndClose && <Button variant="contained" color="secondary" onClick={() => handleSave(true)} disabled={!resourceIsDirty() || resourceLoading}>Save and Close</Button>}
        <Button variant="contained" color="primary" onClick={() => handleSave()} disabled={!resourceIsDirty() || resourceLoading}>Save</Button>

      </DialogActions>)}


      <ConfirmDialog open={confirmingDelete} setOpen={setConfirmingDelete} onConfirm={handleConfirmDelete} title="Confirm Delete?" message={`Are you sure you want to delete the selected ${schema.labels.name}?`} />
    </>
  )
};

export default ResourceDetails
