import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import { CareOrderComponent } from './CareOrder';
// import { OrderSchema } from '../models/Order';
// import MutableResourceProvider from '../context/MutableResourceProvider';
import { CareOrderComponent } from './CareOrder';

interface Props {
  open: boolean,
  setOpen: (shouldOpen: boolean) => void;
}

export default function CareOrderDialog({ open, setOpen }: Props) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md" fullWidth={true}
    >
      <DialogContent>
        <CareOrderComponent />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}


