
import { ResourceComparitor } from '../services/ResourceService';
import User from './User';

// Our API Address
interface Address {
  addressOne: string;
  addressTwo?: string;
  city: string;
  state: string;
  zip: string;
}

export interface StripeAddress {
  name: string;
  address: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postal_code: string;
  }
}

const InlineDescriber = (address?: Address) => address && `${address.addressOne} ${address.addressTwo || ''} ${address.city} ${address.state}`;
const AddressSchema = {
  labels: { name: 'Address', pluralName: 'Addresses', describer: InlineDescriber },
  adaptor: {
    toStripe: (address: Address, user: User): StripeAddress => {
      return {
        name: user.firstName + ' ' + user.lastName,
        address: {
          line1: address?.addressOne || '',
          line2: address?.addressTwo || '',
          city: address?.city,
          state: address?.state,
          postal_code: address?.zip
        }
      }
    }
  },
  comparitor: ResourceComparitor
}

export { AddressSchema };
export default Address;
