
import { useUserContext, UserRole } from '../context/UserContext';
import AuthGateway from "./AuthGateway";

import { Link, Route, useRouteMatch, Switch } from 'react-router-dom';
import { Typography, Box, makeStyles, Theme } from '@material-ui/core';
// import AuthenticationButtons from './AuthenticationButtons';
import CareOrder from './CareOrder';
import CareOrderIndex from './CareOrderIndex';
import SnackProvider from '../context/SnackProvider';
import Profile from './Profile';
import ResourceDetails from './ResourceDetails';
import { ProfileSchema } from '../models/User';
import { TopAppBar, BottomAppBar } from './Bars';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    [theme.breakpoints.down('md')]: {
      padding: '80px 18px',
      width: 'calc(100% - 36px)',
      marginTop: theme.spacing(7)

    },
    [theme.breakpoints.up('md')]: {
      padding: '80px',
      width: 'calc(100% - 200px)',
      marginTop: theme.spacing(8)
    },
  },
  link: {
    fontWeight: 600,
    color: 'black',
    display: 'inline-block',
    marginRight: '1em'
  },
  title: {
    flexGrow: 1,
  },
}));

const Care = () => {
  const classes = useStyles();

  let { path } = useRouteMatch();

  const { userRole, reloadUser } = useUserContext()
  const [displayOrderCard, setDisplayOrderCard] = useState(false);

  if (userRole < UserRole.CUSTOMER) { return <AuthGateway message="You need to be a customer (at least) to continue" /> }

  return (
    <SnackProvider>
      <Route path={path}>

        <TopAppBar>
          {/* <Typography variant="h6" className={classes.title}>Customer Care Portal</Typography> */}
          {userRole > UserRole.CUSTOMER && (
            <Link to="/hub"><Typography className={classes.link}>Hub</Typography></Link>
          )}
          <Link to="/care/orders"><Typography className={classes.link}>Orders</Typography></Link>
        </TopAppBar>

        {/*
        <AppBar
          position="static"
        >
          <Toolbar>
            <Typography variant="h6" className={classes.title}>Customer Care Portal</Typography>
            {userRole > UserRole.CUSTOMER && (
              <Link to="/hub"><Typography style={{ color: '#FFF', display: 'inline-block', marginRight: '1em' }}>Hub</Typography></Link>
            )}
            <Link to="/care/orders"><Typography style={{ color: '#FFF', display: 'inline-block', marginRight: '1em' }}>Orders</Typography></Link>
            <AuthenticationButtons />

          </Toolbar>
        </AppBar> */}
        <Box className={classes.box}>
          <Switch>
            <Route exact path={`${path}/orders`}>
              <CareOrderIndex />
            </Route>

            <Route exact path={`${path}/orders/:orderId`}>
              <CareOrder displayOrderCard={displayOrderCard} setDisplayOrderCard={setDisplayOrderCard} />
            </Route>

            <Route path={`${path}/profile`}>
              <Route exact path={`${path}/profile/:resourceId`}>
                <ResourceDetails schema={ProfileSchema} onDidSave={() => reloadUser()} />
              </Route>
              <Profile />
            </Route>
          </Switch>
        </Box>
      </Route>

      <BottomAppBar />

    </SnackProvider>
  )
};

export default Care;
