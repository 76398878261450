import { useState } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import OrderStatusDropDown from './OrderStatusDropDown';
import { ResourceOptionProps } from './ResourceOptions';
import ConfirmDialog from './ConfirmDialog';
import NotesView from './NotesView';
import ResourceService, { parentedSchema } from '../services/ResourceService';
import formatCurrency from '../utils'
import { Link } from 'react-router-dom';
import { OrderSchema } from '../models/Order';
import { useResourceContext } from '../context/ResourceProvider';
import { UserSchema } from '../models/User';
import HeaderRow from './HeaderRow';
import CareOrderDialog from './CareOrderDialog';
import { useMutableResourceContext } from '../context/MutableResourceProvider';
import { AddressSchema } from '../models/Address';

export default function OrderOptions({ handleChange }: ResourceOptionProps) {

  const { resource, ogResource, loadResource } = useMutableResourceContext();

  const { memberAction } = useResourceContext();
  const [submitError, setSubmitError] = useState('');
  const [cancelError, setCancelError] = useState('');
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [showingEdit, showEdit] = useState(false);

  const order = resource;

  const doSubmitOrder = async () => {
    try {
      const parentResourceId = order.user.id;
      await memberAction('submit_to_pwn', resource, parentedSchema(OrderSchema, UserSchema), { parentResourceId });
      loadResource(true);
    } catch (e) {
      console.error(e);
      setSubmitError(e.errors['Error']);
    }
  }

  const doCancelOrder = async () => {
    try {
      const parentResourceId = order.user.id;
      await memberAction('cancel', resource, parentedSchema(OrderSchema, UserSchema), { parentResourceId });
      loadResource(true);
    } catch (e) {
      e && setCancelError(e?.errors?.Error);
    }

    setConfirmCancel(false);
  }
  const refundDescription = (refunds: any) => {
    var sum = 0;

    refunds?.forEach((refund: any) => {
      sum += refund.amount;
    });

    const refundDescription = sum > 0 ? `(-${formatCurrency(sum)})` : '';
    return refundDescription;
  }

  const calculateDiscount = () => {
    if (order.discount) {
      return order.discount.valueType === 'flat'
        ? `(${formatCurrency(order.discount.amount)})`
        : `${order.discount.percentOff} % (${formatCurrency(order.subTotal - order.totalAmount)})`;
    } else {
      return '';
    }
  }

  const handleConfirmCancel = () => {
    setCancelError('')
    setConfirmCancel(true)
  }

  const canShowCancel = () => {
    return ogResource.aasmState !== 'completed' && ogResource.aasmState !== 'refunded' && ogResource.aasmState !== 'cancelled';
  }

  return (
    <>
      <Grid container>
        <Grid item container xs={8} spacing={1}>
          <Grid item container xs={12} spacing={0}>
            <HeaderRow label='Order Number'>{order.id}</HeaderRow>
            <HeaderRow label='Status'>{ogResource.aasmState}</HeaderRow>
            <HeaderRow label='User'>
              <Link target="_blank" to={ResourceService.hubPathForSchema(UserSchema, order.user.id)} color="primary">{UserSchema.labels.describer(order.user)}</Link>
            </HeaderRow>
            <HeaderRow label='Billing'>{AddressSchema.labels.describer(order.billingAddress)}</HeaderRow>
            <HeaderRow label='Shipping'>{AddressSchema.labels.describer(order.shippingAddress)}</HeaderRow>

            <HeaderRow label='Amount'>{formatCurrency(order.subTotal)}</HeaderRow>
            <HeaderRow label='Fees'>{formatCurrency(order.fees)}</HeaderRow>
            <HeaderRow label='Discount'>{calculateDiscount()}</HeaderRow>
            <HeaderRow label='Total'>{formatCurrency(order.totalAmount)}</HeaderRow>
            <HeaderRow label='Refunds'>{refundDescription(order.refunds)}</HeaderRow>

            {order.id && (
              <>
                <HeaderRow label='Care Portal'><Link target="_blank" to={`/care/orders/${order.id}`} color="primary">Go to Order in Care Portal</Link></HeaderRow>
                <Button variant="outlined" onClick={() => showEdit(true)}>Edit Order</Button>
              </>
            )}


          </Grid>

          {(order.aasmState === 'unsubmitted' || order.aasmState === 'error') && (
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => doSubmitOrder()}>Submit Order</Button>
              <Typography>{submitError}</Typography>
            </Grid>
          )}

          {canShowCancel() && (
            <Grid item xs={12}>
              <Button variant="contained" onClick={() => handleConfirmCancel()}>Cancel Order</Button>
              <Typography style={{ color: "red" }}>{cancelError}</Typography>
            </Grid>
          )}
          {/*TODO: Move cancel to order status dropdown */}
          <OrderStatusDropDown handleChange={handleChange} resource={resource} ogResource={ogResource} />

        </Grid>

        <Grid item xs={4}>
          <NotesView comments={order.notes} allowAdd={true} handleChange={handleChange} lifo={true} />
        </Grid>



      </Grid>

      <ConfirmDialog
        open={confirmCancel}
        setOpen={setConfirmCancel}
        onConfirm={doCancelOrder}
        title="Confirm Cancel?"
        message={`Are you sure you want to cancel this order?`}
      />

      {showingEdit && order && (
        <CareOrderDialog open={showingEdit} setOpen={showEdit} />
      )}
    </>
  )
};
