import { createContext, useContext, useState } from 'react';
import Saladbar from "../components/Saladbar";

interface Snack {
  message: string;
  source?: string;
}

export const SnackContext = createContext<any>(undefined);
export const useSnackContext = () => useContext(SnackContext)!;

export default function SnackProvider(props: any) {
  const [snack, setSnack] = useState<Snack>();

  return (
    <SnackContext.Provider value={{ snack, setSnack }}>
      {props.children}
      <Saladbar snack={snack} setSnack={setSnack} />
    </SnackContext.Provider>

  );
}

export type { Snack };





