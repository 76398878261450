import { useState, useContext } from 'react';
import { Button } from '@material-ui/core';
import { useResourceContext } from '../context/ResourceProvider';
import { SnackContext } from "../context/SnackProvider";
import ResourceService from '../services/ResourceService';

interface Props {
  pwnId: string;
  externalOrderId: string;
  docName: string;
}

export default function DownloadPwnDocument({ pwnId, docName, externalOrderId }: Props) {
  const [downloading, setDownloading] = useState(false);
  const { setSnack } = useContext(SnackContext);
  const { downloadFile } = useResourceContext();

  const downloadPwnDocument = async () => {
    setDownloading(true);
    const url = ResourceService.urlWithApiPath(`/pwn_orders/${pwnId}/download?file=${docName}`);

    try {
      const blob = await downloadFile(url, 'application/pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL( new Blob([blob]) );
      link.setAttribute('download', `${externalOrderId}-${docName}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
    } catch(e) {
      setSnack({ message: (Array.isArray(e.errors) ? e.errors.join(',') : e.errors) });
    } finally {
      setDownloading(false);
    }
  }

  return (
    <Button size="small" onClick={downloadPwnDocument} variant="contained" color="primary" disabled={downloading}>Download</Button>
  )
}
