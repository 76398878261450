import { useContext, useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Checkbox,
  FormLabel,
  FormControlLabel
} from '@material-ui/core';

import PaymentProps from './Payment/PaymentProps'
import Payment from './Payment/Payment'
import { useMutableResourceContext } from '../context/MutableResourceProvider';
import { SnackContext } from "../context/SnackProvider";
import LTOTerms from './LTOTerms';
import PWNTerms from './PWNTerms';

import EditCard, { EditProps } from './EditCard';
import Order from '../models/Order';


interface CareOrderFinishPaymentProps {
  cardElement?: any;
  onSuccess: () => Promise<void>;
  onError: (message: string) => void;
}

function CareOrderFinishStripePayment({ cardElement, onSuccess, onError }: CareOrderFinishPaymentProps) {
  const { resource, ogResource } = useMutableResourceContext();
  const order = resource;
  const ogOrder = ogResource;
  const hasSavedAddress = () => Boolean(ogOrder.billingAddress);
  const hasSavedPayment = () => Boolean(ogOrder.paymentTransaction);
  const canShowPayment = () => hasSavedAddress() && !hasSavedPayment()

  if (!canShowPayment()) { return null; }

  return (
    <Grid item xs={12}>
      <Payment
        cardElement={cardElement}
        onCardComplete={() => { }}
        billing={order.billingAddress}
        captureShipping={false} //captureShipping
        order={order}
        onSuccess={onSuccess}
        onError={onError}
        shipping={order.shippingAddress}
        hidePostalCode
        showCard={false}
        showButton={true}
        paymentOption="Stripe"
      />
    </Grid>
  )
}

export { CareOrderFinishStripePayment };

type CareOrderCardProps = PaymentProps & EditProps;

const CareOrderCard = (props: CareOrderCardProps) => {
  const { setSnack } = useContext(SnackContext);

  const { editing, setEditing, setDisplayOrderCard } = props;
  const [cardElement, setCardElement] = useState<any>()
  const [paymentOption, setPaymentOption] = useState('')
  const [error, setError] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [showLtoTerms, setShowLtoTerms] = useState(false)
  const [showPwnTerms, setShowPwnTerms] = useState(false)

  const { resource, ogResource, loadResource, resourceIsDirty } = useMutableResourceContext();
  const order = resource as Order;
  const ogOrder = ogResource;
  const loadOrder = loadResource;

  const hasSavedAddress = () => Boolean(ogOrder.billingAddress);
  const hasSavedPayment = () => Boolean(ogOrder.paymentTransaction);
  const canShowPayment = () => (editing || !resourceIsDirty()) && hasSavedAddress() && !hasSavedPayment() && Boolean(order.lineItems?.length) //&& didConfirmReadyForPayment

  /*
  if we split the component into two distinct instances of payment
  we need a way to capture the card element for submission on click of
  the payment button.
*/
  const handleCardComplete = (cardElement: any) => {
    setCardElement(cardElement)
  }

  const handlePaymentSuccess = async () => {
    setError('')
    setSnack({ message: "Payment Successful!" });
    loadOrder(true)
    setDisplayOrderCard(true);
  }

  const handlePaymentError = (message: string) => {
    setError(message ? `Payment failed ${message}` : '');
  }

  useEffect(() => {
    if (!editing) {
      setCardElement(null);
    }
  }, [editing])

  useEffect(() => {
    setTermsAccepted(false);
  }, [ogResource])

  const handlePaymentOption = (selectedOption: string) => {
    setEditing(true);
    setPaymentOption(selectedOption);
  }

  const handleCancel = () => {
    setPaymentOption('');
    setError('');
    setEditing(false);
  }

  if (!canShowPayment()) { return null; }
  return (
    <EditCard title="Payment"
      editing={editing}
      setEditing={setEditing}
      showCancelButton={editing}
      showEditButton={editing}
      saveAction={null}
    >
      {editing && paymentOption === 'Stripe' ? (
        <>
          <Payment error={error} onCancel={handleCancel} paymentOption={paymentOption} cardElement={cardElement} onCardComplete={handleCardComplete} {...props} showButton={false} />
          <CareOrderFinishStripePayment onSuccess={handlePaymentSuccess} cardElement={cardElement} onError={handlePaymentError} />
        </>
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item container xs={12} direction="row">
            <LTOTerms open={showLtoTerms} handleClose={() => setShowLtoTerms(false)} />
            <PWNTerms open={showPwnTerms} handleClose={() => setShowPwnTerms(false)} />
            <FormControlLabel
              control={<Checkbox checked={termsAccepted} onChange={(event) => setTermsAccepted(event.target.checked)} />}
              label={<FormLabel>Accept <Button variant="text" color="primary" onClick={() => setShowLtoTerms(true)}>TESTING.COM Terms</Button> and <Button variant="text" color="primary" onClick={() => setShowPwnTerms(true)}>PWNHealth Terms &amp; Conditions</Button></FormLabel>} />
          </Grid>
          <Grid container item direction="row" spacing={1} xs={12}>
            <Grid item>
              <Button disabled={!termsAccepted} style={{ height: '55px' }} variant="contained" color="primary" onClick={() => handlePaymentOption('Stripe')}>Pay with Credit Card</Button>
            </Grid>
            <Grid item>
              {!termsAccepted && (
                <Button disabled={true} fullWidth variant="contained" style={{ height: '55px' }} color="primary">Pay with PayPal</Button>
              )}
              {termsAccepted && order.billingAddress && (
                <Payment
                  paymentOption="PayPal"
                  onCancel={handleCancel}
                  onSuccess={handlePaymentSuccess}
                  onError={handlePaymentError}
                  billing={order.billingAddress}
                  shipping={order.shippingAddress}
                  order={order} />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </EditCard>
  );
}

export default CareOrderCard;
