import { Auth0Provider } from "@auth0/auth0-react";
import { getAuth0Config } from '../services/Auth0Config';

interface Props {
  children: any;
};

// const localReturnKey = 'authRedirect';

const Auth0ProviderWithHistory = ({ children }: Props) => {
  const redirectUri = window.location.origin;
  const auth0Config = getAuth0Config();

  const onRedirectCallback = (appState: any) => {
    // note: history.push was not reloading after a login redirect.
    if (appState?.returnTo) {
      window.location.href = appState.returnTo;//
    }
  };

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      audience={auth0Config.audience}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
