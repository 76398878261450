import {
  useMediaQuery, useTheme,
} from '@material-ui/core';

const ITEMS = [
  { title: 'Order your test', subtitle: 'Online or over the phone' },
  { title: 'Find a lab near you', subtitle: 'Over 3,500 locations to choose from' },
  { title: 'Get your results sent directly to you', title2: 'SENT DIRECTLY TO YOU' }
]

const InstructionalBreadcrumb = () => {
  // media will be true if large
  const theme = useTheme();
  const mediaIsLarge = useMediaQuery(theme.breakpoints.up('md'));
  const containerPadding = mediaIsLarge ? '0 30px' : '0 8px';
  const contentMarginLeft = mediaIsLarge ? '20px' : '4px';
  const indexLabelFontSize = 'calc(10px + 4vmin)';
  const titleFontSize = 'calc(10px + .8vmin)';//bit of magic here, dynamic font size to ensure it
  const minWidth = mediaIsLarge ? '100%' : 'calc(100vw)' //Force the edges of the wayfinder to the edge of screen on mobile
  const marginLeft = mediaIsLarge ? '0px' : '-18px'; //Force the edges of the wayfinder to the edge of screen on mobile

  const renderItems = (): any => {

    return ITEMS.map((item: any, i: number) => {
      const showArrow = i + 1 < ITEMS.length;

      return (
        <div key={`item-${i}`} style={{
          display: 'flex', alignItems: 'center', position: 'relative', flex: '1 1',
          justifyContent: 'space-around', color: '#fff', padding: containerPadding
        }}>
          <div style={{ display: 'flex', alignItems: 'center', maxWidth: '340px' }}>
            <span style={{ fontSize: indexLabelFontSize, fontWeight: 'bolder' }}>{`${i + 1}`}</span>
            <div style={{ marginLeft: contentMarginLeft }}>
              <p style={{ margin: '0 0 4px', fontWeight: 'bold', fontSize: titleFontSize }}>{mediaIsLarge ? item.title.toUpperCase() : item.title}</p>
              {/* {item.title2 && <p style={{ margin: '0 0 4px', fontWeight: 'bold' }}>{item.title2}</p>} */}

              {mediaIsLarge && item.subtitle && <p style={{ margin: 0, fontSize: '.875rem' }}>{item.subtitle}</p>}
            </div>
            {showArrow && (
              <div style={{
                position: 'relative',
                // right: '30px',
                width: 0,
                height: 0,
                borderStyle: 'solid',
                borderWidth: '50px 0 50px 30px',
                borderColor: 'transparent transparent transparent #900707', filter: 'drop-shadow(20px 0 0.75rem rgba(0,0,0,.3))'
              }}
              />
            )}
          </div>
        </div>
      );
    });
  }

  return (
    <div style={{ display: 'flex', flexBasis: '100%', width: "100%", height: '100px', background: '#900707', marginBottom: '20px', minWidth: minWidth, marginLeft: marginLeft }}>
      {renderItems()}
    </div>
  )
}



export default InstructionalBreadcrumb;
