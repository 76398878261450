import { ResourceLinkDef, ResourceSchema } from '../services/ResourceService';
// import PushLink from './PushLink';
import { PushDetailButtonWithCount } from './PushDetailButton';

interface Props {
  resource: any;
  parentResourceId?: string;
  schema: ResourceSchema;
}

const ResourceLinks = ({ resource, schema }: Props) => {
  if (!resource.id) { return null; }
  return (
    <div style={{ marginTop: '1em' }}>
      {schema.links?.map((link: ResourceLinkDef) => <PushDetailButtonWithCount link={link} resource={resource} />)}
    </div>
  )
}
export default ResourceLinks
