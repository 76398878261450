import { ResourceSchema } from '../services/ResourceService';
import PaymentTransaction from './PaymentTransaction';
import formatCurrency from '../utils';
import RefundOptions from '../components/RefundOptions';
import ColumnFactory from '../services/ColumnFactory';
import { HubActionProps, NewRedirectAction, HubAction, CreateOneToManyAction } from '../components/CommonResourceHandlers';
import { UserRole, useUserContext } from '../context/UserContext';




const RefundHubActionsResolver = (props: HubActionProps): HubAction[] | null => {
  const { userRole } = useUserContext();

  const { schema, parentResourceId, resource } = props;

  // TODO:Cannot issue a refund if there's no payment yet
  // if (!Boolean(parentResource.paymentTransaction)) { return null; }
  if (userRole >= UserRole.CSR) {

    var actions = [];
    if (schema.parent && parentResourceId) {
      //Section if there a parent to product
      //We can add this user to a discount
      // actions.push(AddOneToManyAction());
      if (resource) {
        // actions.push(RemoveOneToManyAction());
      }
      actions.push(CreateOneToManyAction());

    } else {
      actions.push(NewRedirectAction());
    }

    return actions;
  }

  return null;
}


type Refund = {
  id: string
  amount: number
  fees: number
  confirmationNumber: string
  paymentTransaction: PaymentTransaction
  rawResponse: any
};

const RefundSchema = {
  apiResponseResourceCollectionKey: 'refunds',
  apiResponseResourceItemKey: 'refund',
  apiRequestPath: '/refunds',
  labels: { name: 'Refund', pluralName: 'Refunds', describer: (resource) => resource.id },
  columns: [
    ColumnFactory.id,
    { field: 'amount', headerName: 'AMOUNT', flex: 1, valueFormatter: (param: any) => param.value && formatCurrency(param.value) },
    { field: 'fees', headerName: 'FEES', flex: 1, valueFormatter: (param: any) => param.value && formatCurrency(param.value) },
    { field: 'confirmationNumber', headerName: 'Refund Confirmation#', flex: 1 },
    ColumnFactory.actions()
  ],
  columnsForMobile: [],
  optionHandler: RefundOptions,
  actionsResolver: RefundHubActionsResolver

} as ResourceSchema;

export { RefundSchema };

export default Refund;
